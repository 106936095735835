import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IUser } from "./users"

export interface IApproveUser {
  id: string
  usuario_id?: string
  consignante_id: string
  consignataria_id?: string
  status: 'aguardando_liberacao' | 'liberado' | 'recusado'
  criado_em: string
  Usuario: IUser
  perfis?: string[]
}
export const approveUsers: IGenericModel<IApproveUser> = {
  ...generic(endpoints.APPROVE_USERS),
}