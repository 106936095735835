import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface ITag {
    id: string
    name: string
    color: string
    textColor: string
    updatedAt: string
    createdAt: string
}

export const tags: IGenericModel<ITag> = {
    ...generic(endpoints.bank.TAGS)
}