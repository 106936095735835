import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export enum TransactionType {
    PIX_IN = "pix_in",
    PIX_OUT = "pix_out",
    CASH_IN = "cash_in",
    CASH_OUT = "cash_out",
    BOLETO_IN = "boleto_in",
    BOLETO_OUT = "boleto_out",
    DEBIT = "debit",
    CREDIT = "credit",
    RECHARGE = "recharge",
    TAXES = "taxes",
    OTHER = "other",
}

export interface IExtract {
    id: string
    title: string
    name: string
    value: number
    type: TransactionType
    subType: number
    createdAt: string
}

export const extratos: IGenericModel<IExtract> = {
    ...generic(endpoints.EXTRATOS),
}
