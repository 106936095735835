import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IAgent } from "models/bank/agents"
import { generateMockContact } from "./contacts.mock"

export default generateGenericMock(endpoints.bank.AGENTS, generateAgentMockData)

export function generateAgentMockData(index: string): IAgent {
    return {
        id: index || "0",
        // avatar: faker.internet.avatar(),
        // name: faker.name.name(),
        // lastName: faker.name.lastName(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        email: faker.internet.email(),
        // phone: faker.phone.phoneNumber(),
        active: faker.random.boolean(),
        perfilId: faker.random.uuid(),
        status: faker.helpers.randomize(['invited', 'registered']),
        contact: generateMockContact()
    }
}

