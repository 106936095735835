import React from "react"
import { Link, RouteComponentProps, Redirect } from "react-router-dom"
import { useBreadcrumb, useStore, useActions } from "hooks"
import { UserOutlined } from "@ant-design/icons"
import { Row, Form, Input, Button, Typography } from "antd"
import AuthPage from "./AuthPage"
import Paragraph from "antd/lib/typography/Paragraph"
import queryString from "query-string"
import { LockOutlined } from "@ant-design/icons"
import { capitalizeString } from "lib/helper"
import MaskedInput from "antd-mask-input"
import { validate } from "gerador-validador-cpf"

function JoinRegister(props: RouteComponentProps) {
    const workspaceInfo = useStore((state) => state.app.workspaceInfo)
    useBreadcrumb({ items: [{ title: "Cadastro no " + workspaceInfo?.name }] })

    const { loading } = useStore((state) => state.session)
    const { joinAgent } = useActions((actions) => actions.session)
    const userPreRegister = queryString.parse(props.location.search)

    function onSubmit(model: any) {
        joinAgent({ ...userPreRegister, ...model }).then(() => {
            props.history.replace("/")
        })
    }

    if (!userPreRegister.email || !userPreRegister.token || !userPreRegister.productName) {
        return <Redirect to="/" />
    }

    return (
        <AuthPage
            title={
                "Cadastro em " + workspaceInfo?.name
                // " - " +
                // capitalizeString(userPreRegister.productName as string)
            }
        >
            <Paragraph>
                Você foi convidado a se juntar ao workspace{" "}
                <Typography.Text strong>{workspaceInfo?.name}</Typography.Text>.
            </Paragraph>
            <Paragraph>Cadastre-se preenchendo as informações abaixo:</Paragraph>
            <Form
                onFinish={onSubmit as any}
                initialValues={{
                    name: userPreRegister.name,
                    lastName: userPreRegister.lastName,
                }}
                layout="vertical"
                hideRequiredMark
            >
                <Form.Item label="E-mail">
                    <Input type="email" value={userPreRegister.email || ""} disabled />
                </Form.Item>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: "Por favor, insira seu nome." }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Sobrenome" name="lastName">
                    <Input />
                </Form.Item>
                <Form.Item label="Celular" name="cellphone">
                    <MaskedInput mask="(11) 11111-11-11" />
                </Form.Item>
                <Form.Item
                    name="birthdate"
                    label="Data de nascimento "
                    rules={[
                        { required: true, message: "Por favor, insira sua data de nascimento." },
                    ]}
                >
                    <MaskedInput mask="11/11/1111" />
                </Form.Item>
                <Form.Item
                    label="CPF"
                    name="cpf"
                    rules={[
                        // { required: true, message: "Por favor, insira seu cpf." },
                        {
                            validator: async (rule, value) => {
                                if (validate(value)) return undefined
                                else throw new Error("CPF Inválido!")
                            },
                        },
                    ]}
                >
                    {/* <Input /> */}
                    <MaskedInput mask="111.111.111-11" />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Senha"
                    rules={[{ required: true, message: "Por favor, insira sua senha." }]}
                >
                    <Input type="password" placeholder="Password" />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{ width: "100%" }}
                        size="large"
                    >
                        Cadastrar
                    </Button>
                </Form.Item>
            </Form>
        </AuthPage>
    )
}

export default JoinRegister
