import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IAutomation {
    id: string
    updatedAt: string
    createdAt: string
    name: string
    description: string
    conditions: {
        all: {
            field: string
            operator: string
            value: string | number
        }[]
    }
    actions: {
        type: string
        value: any
    }[]
}

export const automations: IGenericModel<IAutomation> = {
    ...generic(endpoints.bank.AUTOMATIONS)
}