// import faker from "faker"
// import { generatePerfilMockData } from "mock/bank_mock/perfis.mock"
// import { generateUserMockData } from "mock/users.mock"
// import { generatePerfilMockData } from "mock/perfis.mock"
// import { generateSecretaryMockData } from "mock/system_mock/secretaries.mock"
// import { IUserSession } from "models/session"
// import { generateAppMockData } from "mock/bank_mock/marketplace.mock"

export const generateLoginMockData = () => {
    return {
    }
}

export default {
    endpoint: "/users/register",
    latency: 1000,
    method: {
        post: {
            success: {
                statusCode: 200,
                body: generateLoginMockData
            },
            error: {
                statusCode: 401,
                error: {
                    errors:
                        [
                            {
                                key: null,
                                type: "ValidationError",
                                descriptions: [
                                    "Algum erro"
                                ]
                            }
                        ]
                }
            }

        }
    },
}

