import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateUserMockData } from "./users.mock"
import { ICalendarEvent } from "models/calendario_eventos"

export default generateGenericMock(
    endpoints.CALENDARIO_EVENTOS,
    generateNotificationMockData,
    { max: 1 }
)

export function generateNotificationMockData(
    index: string,
    model?: Partial<ICalendarEvent>
): ICalendarEvent {
    var end = new Date()
    end.setHours(end.getHours() + 2)
    return {
        id: index || "0",
        criado_em: faker.date.past().toISOString(),
        atualizado_em: faker.date.past().toISOString(),
        visible: faker.helpers.randomize([
            "consignataria",
            "todas_consignatarias",
            "consignante",
        ]),
        Usuario: generateUserMockData("0"),
        // consignataria_id: "0",
        showToAllUsers: faker.random.boolean(),
        title: faker.random.words(),
        start: new Date().toISOString(),
        end: end.toISOString(),
        allDay: false,
        resource: {},
        ...model
    }
}
