import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { Channel } from "lib/constants"
import { Type, Status } from "lib/constants/Channel"

export interface IChannel {
    id: string
    name: string
    description: string
    updatedAt: string
    createdAt: string
    type: Type
    active: boolean
    status: Status
    extra?: {
        url?: string,
        phone?: string
        email?: string
        image?: string
    }
}

export const channels: IGenericModel<IChannel> = {
    ...generic(endpoints.bank.CHANNELS)
}
