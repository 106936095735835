import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IUser } from "./users"

export type ILogEventActions = 'Alteração de dados' | "Alteração de configurações" | "Reserva de margem" | "Liquidação de contratos" | "Suspensão de contratos" | "Edição de consignatária" | "Envio de notificação" | "Averbação de contratos" | "Importação de dados" | "Aprovação/Reprovação de importação" | "Aprovação/Reprovação de fechamento" | "Aprovação/Reprovação do arquivo retorno"
export type ILogEventQuery = 'Log de acessos ao sistema' | "Consulta de margem" | "Consulta de relatórios" | "Consulta de contratos" | "Consulta de relatórios" | "Log de visualização de notificações"
export type ILogEvent = ILogEventActions | ILogEventQuery

export interface ILog {
    id: string
    criado_em: string
    user: IUser
    consignataria_id?: string
    cpf_servidor?: string
    nome_servidor?: string
    matricula_servidor?: string
    orgao_servidor?: string
    num_contrato?: string
    identificador?: string
    icon?: string
    evento: ILogEvent
    descricao?: string
}

export const logs: IGenericModel<ILog> = {
    ...generic(endpoints.LOGS)
}