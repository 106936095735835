import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
// import stores from "store"

import "./styles/index.less"
import "./styles/index.scss"
import App from "./App"
import * as serviceWorker from "./lib/serviceWorker"
import { StoreProvider } from "easy-peasy"
import store from "store"
import { RecoilRoot } from "recoil"

ReactDOM.render(
    <StoreProvider store={store}>
        <Router>
            <RecoilRoot>
                <App />
            </RecoilRoot>
        </Router>
    </StoreProvider>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
