import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateRandomFakeList } from "lib/helper"

export const actionsMock = [
    {
        type: "set_subject",
        value: "Novo assunto"
    },
    {
        type: "set_status",
        value: faker.helpers.randomize(["open", "pending", "solved"])
    },
    {
        type: "set_priority",
        value: faker.helpers.randomize(["low", "normal", "high", "urgent"])
    },
    {
        type: "set_type",
        value: faker.helpers.randomize(["question", "incident", "problem", "task"])
    },
    {
        type: "set_group",
        value: faker.helpers.randomize(["1", "2", "3", "4"])
    },
    {
        type: "set_agent",
        value: faker.helpers.randomize(["current_user", "1", "2", "3", "4"])
    },
    {
        type: "set_tag",
        value: faker.helpers.randomize(["5", "6", "9", "10", "2"])
    },
    {
        type: "unset_tag",
        value: faker.helpers.randomize(["5", "6", "9", "10", "2"])
    },
    {
        type: "add_follower",
        value: faker.helpers.randomize(["5", "6", "9", "10", "2"])
    },
    {
        type: "add_response",
        value: faker.helpers.randomize(["5", "6", "9", "10", "2"])
    },
    {
        type: "add_comment",
        value: faker.helpers.randomize(["esse ticket é muito bom", "esse usuário é muito inteligente"])
    },
]

export default generateGenericMock(endpoints.bank.MACROS, generateMockData)

export function generateMockData() {
    return {
        id: faker.random.uuid(),
        name: faker.random.words(),
        description: faker.random.words(20),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        actions: generateRandomFakeList(0, 5, () => faker.helpers.randomize(actionsMock)),
        restriction: faker.helpers.randomize([
            {
                type: faker.helpers.randomize(["user", "group"]),
                ids: faker.helpers.randomize([["1"], ["1", "2"]]),
            },
            {
                type: "public"
            }
        ]),
    }
}