import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IParametroConvenio {
    id: string
    atualizado_em: string
    criado_em: string
    ativo: boolean
    codigo: string
    descricao: string
    comentario: string
    tipo: "Consignante" | "Consignataria"
    tipoValor: "boolean" | "string" | "decimal" | "integer"
    valor: string | number | boolean | string[]
}

export const parametrosConvenio: IGenericModel<IParametroConvenio> = {
    ...generic(endpoints.PARAMETROS_CONVENIO),
}