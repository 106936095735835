import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IReportConversation } from "products/bank/pages/Reports/ReportConversations"
import { generateAgentMockData } from "./agents.mock"
import { IReportConversationAgents } from "products/bank/pages/Reports/ReportConversationsAgent"

export default generateGenericMock(endpoints.bank.REPORT_CONVERSATIONS_AGENT_PERFORMANCE, genMockReportConversation)

export function genMockReportConversation(): IReportConversationAgents {
    return {
        tme: faker.random.number({ max: 200 }),
        tma: faker.random.number({ max: 200 }),
        tmr: faker.random.number({ max: 200 }),
        assigned: faker.random.number({ max: 200 }),
        pending: faker.random.number({ max: 200 }),
        resolved: faker.random.number({ max: 200 }),
        agent: generateAgentMockData("0"),
        messagesSent:faker.random.number({ max: 200 })
    }
}