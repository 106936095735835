import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useBreadcrumb, useStore, useActions, useHost, useGenericStore } from "hooks"
import { UserOutlined, LockOutlined } from "@ant-design/icons"

import styles from "./style.module.css"
import { PageHeader, Card, Row, Col, Form, Input, Checkbox, Button, Select, message } from "antd"
import AuthPage from "./AuthPage"
import { validate } from "gerador-validador-cpf"
import { MaskedInput } from "antd-mask-input"
import Avatar from "components/ui/Avatar"
import { endpoints } from "config/endpoints.config"
import { request } from "lib/request"

export interface IAccount {
    name: string
    login: string
    type: "cpf" | "cnpj" | "administrator" | "approver" | "operator"
    account: string
}

const getTypeLabel = (type: string) => {
    switch (type) {
        case "cpf":
            return "Conta Pessoa Física"
        case "cnpj":
            return "Conta Pessoa Jurídica"
        case "administrator":
            return "Administrador"
        case "approver":
            return "Aprovador"
        case "operator":
        default:
            return "Operador"
    }
}

function Login() {
    // const { subdomain } = useHost()
    // const workspaceInfo = useStore((state) => state.app.workspaceInfo)
    // const [recaptchaKey, setRecaptchaKey] = useState("")
    const [step, setStep] = useState(0)
    const [accounts, setAccounts] = useState<IAccount[]>()
    const [loadingLogin, setLoadingLogin] = useState(false)
    const [selectedAccount, setSelectedAccount] = useState<IAccount>()

    // const workspaceInfo = useStore((state) => state.app.workspaceInfo)
    // const { all: secretaries } = useGenericStore((state) => state.system.secretaries)
    // useBreadcrumb({ items: [{ title: "Entrar em " + (workspaceInfo?.name || "") }] })
    useBreadcrumb({ items: [{ title: "Acesso" }] })

    const { loading } = useStore((state) => state.session)
    const { login, loginOperator } = useActions((actions) => actions.session)

    function onSubmit(model: { senha: string; cpf: string; remember: boolean }) {
        // login({ password: "123456", cpf: "02767335335", remember: true } as any)
        // setAccounts([
        //     {
        //         name: "Paulo teste",
        //         login: "04000791354",
        //         type: "user",
        //         account: "194406856-6",
        //     },
        //     {
        //         name: "Paulo Henrique Gonçalves Alves",
        //         login: "644-1",
        //         type: "operator",
        //         account: "174406856-6",
        //     },
        // ])
        setLoadingLogin(true)
        request
            .post(endpoints.LIST_LOGIN, {
                cpf: model.cpf,
            })
            .then((res) => {
                setAccounts(res.data)
                setStep(1)
            })
            .finally(() => {
                setLoadingLogin(false)
            })
        // login({ ...model, cpf: masks.number(model.cpf)!, recaptchaKey })
        // if (recaptchaKey) {
        // } else {
        //     toast.info("Marque o Recaptcha.")
        // }
    }

    function onLogin(model: { password: string }) {
        if (selectedAccount.type === "cpf") {
            login({ ...model, cpf: selectedAccount.login, recaptchaKey: "", remember: true, selectedAccount })
        } else {
            loginOperator({ ...model, codOperator: selectedAccount.login, selectedAccount })
        }
    }

    if (step === 2) {
        return (
            <AuthPage>
                <Form name="normal_login" initialValues={{ remember: true }} layout="vertical" hideRequiredMark autoComplete="off" onFinish={onLogin}>
                    <Form.Item>
                        <Row>
                            <a
                                className={styles.account}
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                                    width: "100%",
                                    marginBottom: 0,
                                    border: "none",
                                }}
                            >
                                <Row gutter={12} align="middle">
                                    <Col>
                                        <Avatar size={28} id={selectedAccount.account} name={selectedAccount.name} round />
                                    </Col>
                                    <Col>
                                        <div style={{ fontWeight: 700 }}>{selectedAccount.name}</div>
                                        <div>{selectedAccount?.account ? `Cc: ${selectedAccount?.account}` : "Conta em Análise"}</div>
                                    </Col>
                                </Row>
                            </a>
                        </Row>
                    </Form.Item>
                    <Form.Item name="password" label="Senha" rules={[{ required: true, message: "Por favor, insira sua senha." }]}>
                        <Input.Password type="password" />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {({ getFieldValue }) => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                // className="login-form-button"
                                loading={loading}
                                style={{
                                    minWidth: "100%",
                                    margin: "0 auto",
                                    display: "block",
                                    height: 48,
                                    maxHeight: 48,
                                }}
                                // size="large"
                                disabled={!getFieldValue("password")}
                            >
                                Entrar
                            </Button>
                        )}
                    </Form.Item>
                </Form>
                <br />
                <Row>
                    <a
                        className="blue-link"
                        style={{
                            display: "block",
                            textAlign: "center",
                            marginTop: -11,
                            marginBottom: 20,
                            fontSize: 13,
                            fontWeight: 600,
                        }}
                        onClick={() => {
                            setStep(1)
                        }}
                    >
                        Voltar
                    </a>
                    <Link
                        to={selectedAccount.type === "cpf" ? "/user/forgot" : "/user/forgot-operator/" + selectedAccount.login}
                        className="blue-link"
                        style={{
                            display: "block",
                            textAlign: "center",
                            marginTop: -11,
                            marginBottom: 20,
                            fontSize: 13,
                            fontWeight: 600,
                            marginLeft: 20,
                        }}
                    >
                        Esqueci a senha
                    </Link>
                </Row>
            </AuthPage>
        )
    }

    if (step === 1) {
        return (
            <AuthPage>
                <Form layout="vertical">
                    <Form.Item label="Contas">
                        {accounts?.map((account) => {
                            return (
                                <a
                                    key={account.login + account.type + account.name}
                                    className={styles.account}
                                    onClick={() => {
                                        setSelectedAccount(account)
                                        setStep(2)
                                    }}
                                >
                                    <Row gutter={12} align="middle">
                                        <Col>
                                            <Avatar size={28} id={account.account} name={account.name} round />
                                        </Col>
                                        <Col>
                                            <div style={{ fontWeight: 700 }}>{account.name}</div>
                                            <div>{getTypeLabel(account.type)}</div>
                                            <div>{account?.account ? `Cc: ${account?.account}` : ""}</div>
                                        </Col>
                                    </Row>
                                </a>
                            )
                        })}
                    </Form.Item>

                    <br />
                    <a
                        className="blue-link"
                        style={{
                            display: "block",
                            textAlign: "center",
                            marginTop: -11,
                            marginBottom: 20,
                            fontSize: 13,
                            fontWeight: 600,
                        }}
                        onClick={() => {
                            setStep(0)
                        }}
                    >
                        Voltar
                    </a>
                </Form>
            </AuthPage>
        )
    }

    return (
        <AuthPage>
            <Form name="normal_login" initialValues={{ remember: true }} onFinish={onSubmit as any} layout="vertical" hideRequiredMark autoComplete="off">
                <Form.Item
                    label="CPF"
                    name="cpf"
                    rules={[
                        // { required: true, message: "Por favor, insira seu cpf." },
                        {
                            validator: async (rule, value) => {
                                if (validate(value)) return undefined
                                else throw new Error("CPF Inválido!")
                            },
                        },
                    ]}
                >
                    {/* <Input /> */}
                    <MaskedInput mask="111.111.111-11" autoComplete="cpf" name="cpf" />
                </Form.Item>
                {/* <Form.Item name="password" label="Senha" rules={[{ required: true, message: "Por favor, insira sua senha." }]}>
                    <Input.Password
                        // prefix={
                        //     <Icon
                        //         name="fas fa-lock"
                        //         marginRight={5}
                        //         style={{ color: "var(--shade-30)" }}
                        //     />
                        // }
                        type="password"
                        // placeholder="Senha"
                    />
                </Form.Item> */}
                {/* <Link
                    to="/user/forgot"
                    className="blue-link"
                    style={{
                        display: "block",
                        textAlign: "end",
                        marginTop: -11,
                        marginBottom: 20,
                        fontSize: 13,
                        fontWeight: 600,
                    }}
                >
                    Esqueci a senha
                </Link> */}
                {/* <Button
                    type="link"
                    // icon={<i className="far fa-lock-alt" style={{ marginRight: 8 }} />}
                >
                    
                </Button> */}
                {/* <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox style={{ fontSize: 16 }}>Lembrar</Checkbox>
                    </Form.Item>
                </Form.Item> */}

                {/* <Form.Item>
                    <Row justify="center">
                        <ReCAPTCHA
                            sitekey="6LeVpaUaAAAAAEASdRBvNw64w5-O-UCUjBMd0CXq"
                            onChange={(key: any) => {
                                setRecaptchaKey(key)
                            }}
                        />
                    </Row>
                </Form.Item> */}
                <Form.Item shouldUpdate>
                    {({ getFieldValue }) => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            // className="login-form-button"
                            loading={loadingLogin}
                            style={{
                                minWidth: "100%",
                                margin: "0 auto",
                                display: "block",
                                height: 48,
                                maxHeight: 48,
                            }}
                            // size="large"
                            disabled={!getFieldValue("cpf")}
                        >
                            Continuar
                        </Button>
                    )}
                </Form.Item>
                <br />
                <Link
                    to="/user/register"
                    className="blue-link"
                    style={{
                        display: "block",
                        textAlign: "center",
                        marginTop: -11,
                        marginBottom: 20,
                        fontSize: 13,
                        fontWeight: 600,
                    }}
                >
                    Não tem conta? Crie agora
                </Link>
                {/* <Row justify="space-between">
                    <Button
                        type="link"
                        // icon={<i className="far fa-lock-alt" style={{ marginRight: 8 }} />}
                    >
                        <Link to="/user/forgot">Esqueci a senha</Link>
                    </Button>
                    <Button
                        type="link"
                        icon={<i className="far fa-plus-circle" style={{ marginRight: 8 }} />}
                    >
                        <Link to="/user/register">Criar uma conta</Link>
                    </Button>
                </Row> */}
                {/* <br /> */}
                {/* <div
                    style={{
                        display: "block",
                        textAlign: "center",
                        fontSize: 13,
                    }}
                >
                    Ainda não tem conta?{" "}
                    <Link
                        to="/user/register"
                        className="blue-link"
                        style={{ fontSize: 13, fontWeight: 600, marginLeft: 15 }}
                    >
                        Cadastrar
                    </Link>
                </div> */}
            </Form>
        </AuthPage>
    )
}

export default Login
