import React from "react"
import { Link } from "react-router-dom"
import { useBreadcrumb, useStore, useActions } from "hooks"
import { UserOutlined } from "@ant-design/icons"
import { Row, Form, Input, Button } from "antd"
import AuthPage from "./AuthPage"
import Paragraph from "antd/lib/typography/Paragraph"

function Forgot() {
    useBreadcrumb({ items: [{ title: "Esqueci a senha" }] })

    return (
        <AuthPage title="E-mail enviado!">
            <Paragraph style={{ fontSize: 16 }}>
                Siga as instruções no seu e-mail para resetar sua senha.
            </Paragraph>

            <Row justify="center">
                <Button type="link">
                    <Link to="/user/login" className="blue-link">
                        Voltar a página de entrada
                    </Link>
                </Button>
            </Row>
        </AuthPage>
    )
}

export default Forgot
