// import { Upload } from "antd"
// import { UploadProps } from "antd/lib/upload"
// import { endpoints } from "config/endpoints.config"
// import { request } from "lib/request"
import { Button } from "antd"
import React, { useCallback, useState } from "react"
import { DropzoneOptions, useDropzone } from "react-dropzone"

interface IUploadProps {
    children: React.ReactNode
    className?: string
    buttonLabel?: string
    value?: any
    onChange?: Function
    dropOptions?: DropzoneOptions
    showUploadButton?: boolean
    inline?: boolean
}

interface File {
    type: string
}

function Uploader(props: IUploadProps) {
    const onDrop = useCallback((acceptedFiles) => {
        props.onChange?.(
            acceptedFiles.map((file: any) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        )
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        ...props.dropOptions,
    })

    const handleUpload = () => {
        // const formData = new FormData()
        // fileList.forEach((file: any) => {
        //     const pass = props.beforeUpload?.(file, fileList)
        //     if (pass) {
        //         formData.append("files[]", file)
        //     }
        // })
        // request.post(endpoints.UPLOAD, formData)
    }

    return (
        <div {...getRootProps()} className={props.className} style={props.inline ? { display: "inline-block" } : {}}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop the files here ...</p> : props.children}
            {props.showUploadButton && (
                <Button className="normal" style={{ marginLeft: 270, position: "absolute" }}>
                    {props.buttonLabel || "Escolha imagem"}
                </Button>
            )}
        </div>
    )
}

export default Uploader
