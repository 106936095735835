import React, { useEffect } from "react"
import { Switch, Redirect, RouteComponentProps } from "react-router-dom"
import authRoutes from "routes/auth.routes"
import { useStore, useActions, useHost } from "hooks"
import { getRoutes } from "components/Router"

function Auth(props: RouteComponentProps) {
    const { isLoggedIn } = useStore((state) => state.session)
    
    const isLogout =
    props.location.pathname === "/user/logout" || props.location.pathname === "/user/logout/"
    
    if (!isLogout && isLoggedIn && !props.location.pathname.includes("/user/join-workspace")) {
        return <Redirect to="/" />
    }

    return <Switch>{getRoutes(authRoutes)}</Switch>
}

export default Auth
