import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IReportConversationGroups } from "products/bank/pages/Reports/ReportConversationsGroup"
import { generateGroupMockData } from "./groups.mock"

export default generateGenericMock(endpoints.bank.REPORT_CONVERSATIONS_GROUP_PERFORMANCE, genMockReportConversation)

export function genMockReportConversation(): IReportConversationGroups {
    return {
        tme: faker.random.number({ max: 200 }),
        tma: faker.random.number({ max: 200 }),
        tmr: faker.random.number({ max: 200 }),
        new: faker.random.number({ max: 200 }),
        pending: faker.random.number({ max: 200 }),
        resolved: faker.random.number({ max: 200 }),
        group: generateGroupMockData("0"),
    }
}