import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IContract {
    id: string
    name: string
    updatedAt: string
    createdAt: string
    description: string
}

export const contracts: IGenericModel<IContract> = {
    ...generic(endpoints.bank.CONTRACTS)
}