import React, { useMemo } from "react"

import { Form, Button, Select, Row, Col, Divider } from "antd"
import Icon from "components/common/Icon"
import { OperatorsLabel, InputType, Operators, Ticket } from "lib/constants"
import { FormInstance } from "antd/lib/form"
import { ICustomField } from "models/bank/customFields"
import { useGenericStore } from "hooks"
import { getValueInput, IInputType } from "../ActionsInput"
import { IAgent } from "models/bank/agents"
import { getFullName } from "lib/helper"
import { ITag } from "models/bank/tags"

interface IConditionsInputProps {
    form: FormInstance
}

export interface IConditionType {
    label: string
    field: string
    operators: Operators[]
    values: IInputType
    icon?: string
}

export interface IConditionTypeGroup {
    groupLabel: string
    options: IConditionType[]
}

// function getValueInput(filter?: IConditionType) {
//     switch (filter?.values?.type) {
//         case InputType.Text:
//             return <Input />
//         case InputType.Number:
//             return <InputNumber style={{ width: "100%" }} />
//         case InputType.Select:
//         default:
//             return (
//                 <Select>
//                     {filter?.values?.options!.map((option) => (
//                         <Select.Option key={option.value} value={option.value}>
//                             {option.label}
//                         </Select.Option>
//                     ))}
//                 </Select>
//             )
//     }
// }

function getOperatorsByInputType(type: InputType) {
    switch (type) {
        case InputType.Boolean:
            return [Operators.Is]
        case InputType.MultiSelect:
            return [Operators.Is, Operators.IsNot, Operators.Contains, Operators.NotContains]
        case InputType.Text:
        case InputType.Textarea:
            return [Operators.Is, Operators.IsNot, Operators.Contains, Operators.NotContains]
        case InputType.Decimal:
        case InputType.Number:
        case InputType.Date:
            return [Operators.Is, Operators.IsNot, Operators.GreaterThan, Operators.LessThan]
        case InputType.Select:
        default:
            return [Operators.Is, Operators.IsNot]
    }
}

function getFilter(
    field: any,
    conditionsValue: any,
    conditionsTypesGroupsExisting: IConditionTypeGroup[],
    type: "all" | "any"
) {
    let filter: IConditionType
    conditionsTypesGroupsExisting.some((conditionGroup) => {
        const foundCondition = conditionGroup.options.find((filter) => {
            return filter.field === conditionsValue[type][field.name]?.field
        })
        if (foundCondition) {
            filter = foundCondition
            return true
        }
    })
    return filter!
}

function ConditionsInput(props: IConditionsInputProps) {
    const { all: customFields } = useGenericStore((store) => store.bank.customFields)
    const { all: agents } = useGenericStore((store) => store.bank.agents)
    const { all: groups } = useGenericStore((store) => store.bank.groups)
    const { all: tags } = useGenericStore((store) => store.bank.tags)
    const { all: organizations } = useGenericStore((store) => store.bank.organizations)

    const conditionsTypesGroups: IConditionTypeGroup[] = useMemo(
        () => [
            {
                groupLabel: "Ticket",
                options: [
                    {
                        label: "Status",
                        field: "ticket:status",
                        operators: [
                            Operators.Is,
                            Operators.IsNot,
                            Operators.LessThan,
                            Operators.GreaterThan,
                        ],
                        values: {
                            type: InputType.Select,
                            options: Object.keys(Ticket.Status).map((key) => Ticket.Status[key]),
                        },
                        icon: "fas fa-wave-square",
                    },
                    {
                        label: "Tipo",
                        field: "ticket:type",
                        operators: [Operators.Is, Operators.IsNot],
                        values: {
                            type: InputType.Select,
                            options: Object.keys(Ticket.Type).map((key) => Ticket.Type[key]),
                        },
                        icon: "fas fa-question-square",
                    },
                    {
                        label: "Prioridade",
                        field: "ticket:priority",
                        operators: [
                            Operators.Is,
                            Operators.IsNot,
                            Operators.LessThan,
                            Operators.GreaterThan,
                        ],
                        values: {
                            type: InputType.Select,
                            options: Object.keys(Ticket.Priority).map(
                                (key) => Ticket.Priority[key]
                            ),
                        },
                        icon: "fas fa-exclamation-triangle",
                    },
                    {
                        label: "Grupos",
                        field: "ticket:groups",
                        operators: [Operators.Is, Operators.IsNot],
                        values: {
                            type: InputType.MultiSelect,
                            options: [
                                {
                                    value: "ticket:group",
                                    label: "(Agente logado)",
                                },
                                ...groups.map((group) => ({
                                    value: group.id,
                                    label: group.name,
                                })),
                            ],
                        },
                        icon: "fas fa-users",
                    },
                    {
                        label: "Atribuído",
                        field: "ticket:assignee",
                        operators: [Operators.Is, Operators.IsNot],
                        values: {
                            type: InputType.Select,
                            options: [
                                {
                                    value: "current_user",
                                    label: "(Agente logado)",
                                },
                                ...agents.map((agent) => ({
                                    value: agent.id,
                                    label: getFullName(agent.contact),
                                })),
                            ],
                        },
                    },
                    // {
                    //     label: "Contato", // TODO SELECT COM SEARCH PAGINADO
                    //     field: "ticket:contact",
                    //     operators: [Operators.Is, Operators.IsNot],
                    //     values: {
                    //         type: InputType.Select,
                    //         options: agents.map((agent) => ({
                    //             value: agent.id,
                    //             label: getFullName(agent.contact),
                    //         })),
                    //     },
                    // },
                    // {
                    //     label: "Organização", // TODO SELECT COM SEARCH PAGINADO
                    //     field: "ticket:organization",
                    //     operators: [Operators.Is, Operators.IsNot],
                    //     values: {
                    //         type: InputType.Select,
                    //         options: organizations.map((organization) => {
                    //             console.log(organization, organizations)
                    //             return ({
                    //             value: organization.id,
                    //             label: organization.name,
                    //         })}),
                    //     },
                    // },
                    {
                        label: "Etiquetas",
                        field: "ticket:tag",
                        operators: [Operators.Contains, Operators.NotContains],
                        values: {
                            type: InputType.Select,
                            options: tags.map((tag) => ({
                                value: tag.id,
                                label: tag.name,
                            })),
                        },
                    },
                    {
                        label: "Descrição",
                        field: "ticket:description",
                        operators: [
                            Operators.ContainsAnyWords,
                            Operators.ContainsNoneWords,
                            Operators.ContainsString,
                            Operators.NotContainsString,
                        ],
                        values: {
                            type: InputType.Textarea,
                        },
                    },
                    {
                        label: "Canal",
                        field: "ticket:channel_type",
                        operators: [Operators.Is, Operators.IsNot],
                        values: {
                            type: InputType.Select,
                            options: [
                                {
                                    value: "web_form",
                                    label: "Formulário Web",
                                },
                                {
                                    value: "email",
                                    label: "Email",
                                },
                                {
                                    value: "chat",
                                    label: "Chat",
                                },
                                {
                                    value: "twitter",
                                    label: "Twitter",
                                },
                                {
                                    value: "Twitter_dm",
                                    label: "Twitter DM",
                                },
                                {
                                    value: "twitter_like",
                                    label: "Twitter Like",
                                },
                                {
                                    value: "voicemail",
                                    label: "Email de voz",
                                },
                                {
                                    value: "incoming_phone_call",
                                    label: "Recebendo Ligação",
                                },
                                {
                                    value: "outcoming_phone_call",
                                    label: "Enviando Ligação",
                                },
                                {
                                    value: "text",
                                    label: "Texto",
                                },
                                {
                                    value: "web_widget",
                                    label: "Web Widget",
                                },
                                {
                                    value: "api",
                                    label: "Web Service",
                                },
                                {
                                    value: "automation",
                                    label: "Automação",
                                },
                                {
                                    value: "facebook_post",
                                    label: "Facebook Post",
                                },
                                {
                                    value: "facebook_dm",
                                    label: "Facebook Mensagem Privada",
                                },
                                {
                                    value: "integrations",
                                    label: "Channel Integrations",
                                },
                            ],
                        },
                    },
                    {
                        label: "Recebido no",
                        field: "ticket:channel",
                        operators: [Operators.Is, Operators.IsNot],
                        values: {
                            type: InputType.Select,
                            options: [
                                {
                                    value: "bank@nomeempresa",
                                    label: "bank@nomeempresa",
                                },
                            ],
                        },
                    },
                    {
                        label: "Horas desde criação",
                        field: "ticket:hours_since_created",
                        operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
                        values: {
                            type: InputType.Number,
                        },
                        icon: "fas fa-clock",
                    },
                    {
                        label: "Horas desde abertura",
                        field: "ticket:hours_since_open",
                        operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
                        values: {
                            type: InputType.Number,
                        },
                    },
                    {
                        label: "Horas desde pendente",
                        field: "ticket:hours_since_pending",
                        operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
                        values: {
                            type: InputType.Number,
                        },
                    },
                    {
                        label: "Horas desde resolvido",
                        field: "ticket:hours_since_solved",
                        operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
                        values: {
                            type: InputType.Number,
                        },
                    },
                    {
                        label: "Horas desde fechado",
                        field: "ticket:hours_since_closed",
                        operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
                        values: {
                            type: InputType.Number,
                        },
                    },
                    {
                        label: "Horas desde atribuído",
                        field: "ticket:hours_since_assigned",
                        operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
                        values: {
                            type: InputType.Number,
                        },
                    },
                    {
                        label: "Horas desde atualizado",
                        field: "ticket:hours_since_updated",
                        operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
                        values: {
                            type: InputType.Number,
                        },
                    },
                    {
                        label: "Horas desde violação SLA",
                        field: "ticket:hours_since_due_date",
                        operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
                        values: {
                            type: InputType.Number,
                        },
                    },
                    {
                        label: "Horas até violação SLA",
                        field: "ticket:hours_until_due_date",
                        operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
                        values: {
                            type: InputType.Number,
                        },
                    },
                ],
            },
            {
                groupLabel: "Campos Personalizados",
                options: customFields.map((customField) => ({
                    label: customField.name,
                    field: `ticket:${customField.placeholder}`,
                    operators: getOperatorsByInputType(customField.type),
                    values: {
                        type: customField.type,
                        options: customField.options,
                    },
                })),
            },
        ],

        [agents, tags, customFields]
    )

    const conditionsTypesGroupsExisting = useMemo(
        () => conditionsTypesGroups.filter((coGroup) => coGroup.options.length),
        [conditionsTypesGroups]
    )

    const getConditions = (label: string, type: "all" | "any") => (
        <Form.Item label={label}>
            <Form.List name={["conditions", type]}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            {fields.map((field, i) => (
                                <div
                                    key={field.key}
                                    style={{
                                        display: "flex",
                                        marginBottom: 5,
                                        // boxShadow: "0 1px 9px rgba(0,0,0,.08)",
                                        // border: "1px solid rgba(0,0,0,.08)",
                                        backgroundColor: "var(--shade-45)",
                                        padding: "10px 15px 5px",
                                        alignItems: "center",
                                        borderRadius: 4,
                                    }}
                                >
                                    {/* <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => {
                                            return (
                                                prevValues.conditions.all[field.name]?.field !==
                                                currentValues.conditions.all[field.name]?.field
                                            )
                                        }}
                                    >
                                        {({ getFieldValue }) => {
                                            const filter = conditionsTypes.find((filter) => {
                                                return (
                                                    filter.field ===
                                                    getFieldValue("conditions").all[field.name]
                                                        ?.field
                                                )
                                            })
                                            if (filter) {
                                                return (
                                                    <Icon
                                                        name={filter.icon!}
                                                        marginRight={10}
                                                        style={{ color: "black", width: 18 }}
                                                    />
                                                )
                                            }
                                        }}
                                    </Form.Item> */}
                                    <Row gutter={[8, 8]} style={{ width: "100%" }}>
                                        <Col flex={1} style={{ minWidth: 140 }}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, "field"]}
                                                fieldKey={[field.fieldKey, "field"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Faltando Campo",
                                                    },
                                                ]}
                                                noStyle
                                            >
                                                <Select
                                                    onChange={() => {
                                                        props.form.resetFields([
                                                            [
                                                                "conditions",
                                                                type,
                                                                field.name,
                                                                "value",
                                                            ],
                                                            [
                                                                "conditions",
                                                                type,
                                                                field.name,
                                                                "operator",
                                                            ],
                                                        ])
                                                    }}
                                                >
                                                    {conditionsTypesGroupsExisting.map(
                                                        (conditionGroups) => (
                                                            <Select.OptGroup
                                                                label={conditionGroups.groupLabel}
                                                                key={conditionGroups.groupLabel}
                                                            >
                                                                {conditionGroups.options.map(
                                                                    (condition) => (
                                                                        <Select.Option
                                                                            key={condition.field}
                                                                            value={condition.field}
                                                                        >
                                                                            {condition.label}
                                                                        </Select.Option>
                                                                    )
                                                                )}
                                                            </Select.OptGroup>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1} style={{ minWidth: 140 }}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, currentValues) => {
                                                    return (
                                                        prevValues.conditions[type][field.name]
                                                            ?.field !==
                                                        currentValues.conditions[type][field.name]
                                                            ?.field
                                                    )
                                                }}
                                            >
                                                {({ getFieldValue }) => {
                                                    let filter: IConditionType = getFilter(
                                                        field,
                                                        getFieldValue("conditions"),
                                                        conditionsTypesGroupsExisting,
                                                        type
                                                    )

                                                    if (filter) {
                                                        return (
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, "operator"]}
                                                                fieldKey={[
                                                                    field.fieldKey,
                                                                    "operator",
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "Faltando Operador",
                                                                    },
                                                                ]}
                                                                noStyle
                                                            >
                                                                <Select>
                                                                    {filter.operators.map(
                                                                        (operator) => (
                                                                            <Select.Option
                                                                                key={operator}
                                                                                value={operator}
                                                                            >
                                                                                {
                                                                                    OperatorsLabel[
                                                                                        operator
                                                                                    ]
                                                                                }
                                                                            </Select.Option>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </Form.Item>
                                                        )
                                                    }
                                                    return null
                                                }}
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1} style={{ minWidth: 140 }}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, currentValues) => {
                                                    return (
                                                        prevValues.conditions[type][field.name]
                                                            ?.operator !==
                                                            currentValues.conditions[type][
                                                                field.name
                                                            ]?.operator ||
                                                        !currentValues.conditions[type][field.name]
                                                            ?.operator
                                                    )
                                                }}
                                            >
                                                {({ getFieldValue }) => {
                                                    const fieldFieldValue = getFieldValue(
                                                        "conditions"
                                                    )[type][field.name]
                                                    const filter = getFilter(
                                                        field,
                                                        getFieldValue("conditions"),
                                                        conditionsTypesGroupsExisting,
                                                        type
                                                    )
                                                    if (fieldFieldValue?.operator) {
                                                        return (
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, "value"]}
                                                                fieldKey={[field.fieldKey, "value"]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Faltando Campo",
                                                                    },
                                                                ]}
                                                                noStyle
                                                                valuePropName={
                                                                    filter?.values.type ===
                                                                    InputType.Boolean
                                                                        ? "checked"
                                                                        : undefined
                                                                }
                                                            >
                                                                {getValueInput(filter?.values!)}
                                                            </Form.Item>
                                                        )
                                                    }
                                                    return null
                                                }}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Button
                                        icon={<i className="far fa-times" />}
                                        size="small"
                                        shape="circle"
                                        style={{ marginLeft: 10, marginBottom: 5 }}
                                        type="text"
                                        onClick={() => {
                                            remove(field.name)
                                        }}
                                    />
                                </div>
                            ))}

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add()
                                    }}
                                    block
                                    style={{
                                        color: "#367fee",
                                        textAlign: "left",
                                        height: 45,
                                        marginTop: 10,
                                    }}
                                >
                                    <Icon name="fas fa-plus-circle" style={{ marginRight: 10 }} />
                                    Filtro
                                </Button>
                            </Form.Item>
                        </div>
                    )
                }}
            </Form.List>
        </Form.Item>
    )

    return (
        <>
            <Divider orientation="left" plain>
                Condições
            </Divider>
            {getConditions("Precisa atender todas as condições abaixo", "all")}
            {getConditions("Precisa atender qualquer uma das condições abaixo", "any")}
        </>
    )
}

export default ConditionsInput
