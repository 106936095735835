import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
// import { IVerbaDesconto } from "./consignatarias"
// import { IServidorMatricula } from "./servidores"

export interface IContratoNota {
    id: string
    usuario_id: string
    contrato_id: string
    usuario_nome: string
    texto: string
    criado_em: string
}

export interface IParcela {
    numero: number
    valor: number
    valor_descontado: number
    data_vencimento: string
    data_pagamento: string
    status: "Descontado" | "Em aberto"
}

export interface IMatricula {
    id: string
    cargo: string
    criado_em: string
    fim_contrato: string
    numero: string
    orgao: string
    orgao_codigo: string
    funcao_inicio_em: string
    admissao_em?: string
}

export interface IPessoa {
    id: string
    cpf: string
    criado_em: string
    nome: string
    rg: string
    fancard: boolean
}

export interface IContratoDoc {
    documentType:
        | "identity_front"
        | "identity_back"
        | "proof_of_residence"
        | "paycheck_1"
        | "paycheck_2"
        | "paycheck_3"
        | "others"
        | "ccb"
    fileName: string
    name: string
    usuario_id?: string
    type: string
}

export interface IContrato {
    id: string
    Cliente: {
        celular: string
        id: string
    }
    Matricula: IMatricula
    Solicitacao: {
        id: string
        docs: IContratoDoc[]
    }
    Pessoa: IPessoa
    criado_em: string
    expira_em: string
    nr_parcelas: number
    parcelas_pagas: number
    numero: string
    falha_incluir_contrato: boolean
    parcelas: IParcela[]
    produto: string
    situacao:
        | "Reservado"
        | "Pendente"
        | "Cancelado"
        | "Expirado"
        | "Aprovado"
        | "Liquidado"
        | "Quitado"
        | "Em processo de refinanciamento"
        | "Em processo de portabilidade"
        | "Liquidado por refinanciamento"
        | "Liquidado por portabilidade"
    valor_liberado: number
    valor_parcela: number
    valor_financiado: number
    comprovante_url: string
    codigo_consignataria: string
    primeiro_desconto: string
    pendenciaObs?: string
    pendenciaResolvida?: boolean
    anotacoes?: IContratoNota[]
}

export const contratos: IGenericModel<IContrato> = {
    ...generic(endpoints.CONTRATOS),
}
