import { views, IView, IViewModel } from "./views"
import { contracts, IContract } from "./contracts"
import { agents, IAgent } from "./agents"
import { IGenericModel } from "models/generic"
import { groups, IGroup } from "./groups"
import { tags, ITag } from "./tags"
import { businessHours, IBusinessHours } from "./businessHours"
import { responses, IResponse } from "./responses"
import { customFields, ICustomField } from "./customFields"
import { macros, IMacro } from "./macros"
import { bots, IBot, IBotModel } from "./bots"
import { automations, IAutomation } from "./automations"
import { slas, ISLA } from "./slas"
import { contacts, IContact } from "./contacts"
import { channels, IChannel } from "./channels"
import { organizations, IOrganization } from "./organizations"
import { tickets, ITicketModel } from "./tickets"
import { conversations, IConversationModel } from "./conversations"
import { search, ISearchModel } from "./search"

export interface IEcontractModel {
    views: IViewModel,
    contracts: IGenericModel<IContract>
    agents: IGenericModel<IAgent>
    groups: IGenericModel<IGroup>
    tags: IGenericModel<ITag>
    businessHours: IGenericModel<IBusinessHours>
    responses: IGenericModel<IResponse>
    customFields: IGenericModel<ICustomField>
    macros: IGenericModel<IMacro>
    bots: IBotModel
    automations: IGenericModel<IAutomation>
    slas: IGenericModel<ISLA>
    contacts: IGenericModel<IContact>
    channels: IGenericModel<IChannel>
    organizations: IGenericModel<IOrganization>
    tickets: ITicketModel
    conversations: IConversationModel
    search: ISearchModel
}

const bank: IEcontractModel = {
    views,
    contracts,
    agents,
    groups,
    tags,
    businessHours,
    responses,
    customFields,
    bots,
    macros,
    automations,
    slas,
    contacts,
    channels,
    organizations,
    tickets,
    search,
    conversations,
}

export default bank