import { useEffect } from "react"
import { useActions } from "hooks"
import { IBreadcrumb } from "models/app"

interface IUseBreadcrumbChatProps {
    (
        breadcrumb: IBreadcrumb,
        config?: {
            revertBreadcrumbOnBack?: boolean
            renderButtons?: React.ReactNode
            tabTitleOnly?: boolean
        },
    ): void
}

export const useBreadcrumb: IUseBreadcrumbChatProps = (breadcrumb, config) => {
    const setBreadcrumb = useActions(actions => actions.app.setBreadcrumb)

    useEffect(() => {
        if (!config?.tabTitleOnly) {
            setBreadcrumb(breadcrumb)
        }
        document.title = breadcrumb.items?.[breadcrumb.items?.length - 1]?.title + " - eMargem"
    }, [breadcrumb])
}

export default useBreadcrumb