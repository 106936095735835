import React from "react"

import styles from "./style.module.css"
import { Row, Col, PageHeader, Card, Form, Button, Spin } from "antd"
import { useStore, useActions } from "hooks"
import AppSpinner from "components/ui/AppSpinner"
import BaseSkeleton from "components/layout/BaseSkeleton"
import logoImg from "assets/images/logos/logo-emargem.svg"
import ballsImg from "assets/images/balls.png"
import heroLoginImg from "assets/images/heroes/login_hero3.svg"
import { Link } from "react-router-dom"
// import logoOutlineImg from "assets/images/logos/logo-emargem-black.svg"
import hero from "assets/images/heroes/woman_phone_1.jpg"
import logo from "assets/images/logos/logo.svg"
import { style } from "glamor"

interface IAuthPageProps {
    children: React.ReactNode
    title?: string
    maxWidth?: number
}

function AuthPage(props: IAuthPageProps) {
    // const workspaceInfo = useStore((state) => state.app.workspaceInfo)

    // const logo = (
    //     <div style={{ height: 50, marginTop: 40 }}>
    //         {/* {<img src={workspaceInfo?.organizationLogo} className={styles.organization_logo} /> ||
    //             "eMargem"} */}
    //         <Link to="/">
    //             {<img src={logoImg} className={styles.organization_logo} alt="logo" /> || "eMargem"}
    //         </Link>
    //     </div>
    // )

    // if (!workspaceInfo) {
    //     return <AppSpinner />
    // }

    return (
        // <BaseSkeleton>
        <Row className={styles.auth}>
            <Col
                flex={1}
                style={{
                    width: "50%",
                    padding: 40,
                    // display: "flex",
                    // flexDirection: "column",
                    // justifyContent: "center",
                    overflow: "auto",
                    minHeight: "100vh",
                }}
                className={styles.auth_content}
            >
                <Row justify="center">
                    <img
                        src={logo}
                        alt=""
                        style={{
                            width: 160,
                        }}
                    />
                </Row>
                <Row justify="center" align="middle">
                    <Col style={{ maxWidth: props.maxWidth || 440, width: "100%" }}>
                        {/* <PageHeader title={logo} style={{ paddingLeft: 0 }} /> */}
                        <Card
                            bordered={false}
                            bodyStyle={{ display: "flex", padding: 0 }}
                            style={{
                                boxShadow: "none",
                                border: "none",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "white",
                                    borderRadius: 16,
                                    zIndex: 1,
                                }}
                            >
                                <h3
                                    style={{
                                        fontWeight: 700,
                                        fontSize: 17,
                                        marginBottom: 20,
                                    }}
                                >
                                    {props.title}
                                </h3>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}
                                >
                                    {props.children}
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col flex={1} style={{ width: "50%", position: "relative" }} className={styles.hero_wrapper}>
                <img src={hero} alt="" className={styles.hero} />
                <div className={styles.gradient}></div>
                <div className={styles.apps}>
                    <div>
                        <h3>Baixe o super app e aproveite todos os produtos Tá Na Mão</h3>
                    </div>
                    <Row gutter={12}>
                        <Col>
                            <a href="https://apps.apple.com/br/app/t%C3%A1-na-m%C3%A3o-bank/id1546650650" target="_blank" rel="noopener noreferrer">
                                <Button size="large" className="outlined large" icon={<i className="fab fa-apple" />}>
                                    App Store
                                </Button>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://play.google.com/store/apps/details?id=br.com.hubfintech.hub_tanamao" target="_blank" rel="noopener noreferrer">
                                <Button size="large" className="outlined large" icon={<i className="fab fa-google-play" />}>
                                    Google Play
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </div>
            </Col>
            {/* <div className={styles.footer_bg} /> */}
        </Row>
        // </BaseSkeleton>
    )
}

export default AuthPage
