import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { UploadFile } from "antd/lib/upload/interface"
import { IFile } from "models/files"

export interface IFileUpload {
    url: string
}

export interface IResponse {
    id: string
    name: string
    updatedAt: string
    createdAt: string
    restriction: {
        type: "user" | "group" | "public"
        ids?: string[]
    }
    text: string
    files: IFile[]
}

export const responses: IGenericModel<IResponse> = {
    ...generic(endpoints.bank.RESPONSES)
}