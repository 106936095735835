import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"

export interface IRoute {
    component?: React.ReactNode
    routes?: IRoute[]
    redirect?: string
    path?: string
    props?: any
    name?: string
    exact?: boolean
}

const getRoute = (route: IRoute, i: number) => {
    const RouteComponent: any = route.component
    return (
        <Route
            path={route.path}
            render={(routeProps) => {
                const props = {
                    ...routeProps,
                    ...route.props,
                    routes: route.routes,
                    routeName: route.name,
                }
                return (
                    <RouteComponent {...props}>
                        <Switch>{route.routes && getRoutes(route.routes)} </Switch>
                    </RouteComponent>
                )
            }}
            key={i + route?.path!}
            exact={!!route.exact}
        />
    )
}

export const getRoutes = (routes: IRoute[]): React.ReactNode => {
    return routes.map((r, i) => {
        if (r.component) {
            return getRoute(r, i)
        } else if (r.routes) {
            return getRoutes(r.routes)
        } else if (r.redirect) {
            return <Redirect exact from={r.path} to={r.redirect} key={r.path + r.redirect} push />
        }
    })
}
