import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IPerfilPermission {
    perfis: boolean
    usuarios: boolean
    auditoria: boolean
    liberacao_usuarios: boolean
    avisos: boolean
    consignatarias: boolean
    tabela_taxas: boolean
    parametros_convenio: boolean
    consulta_margem: boolean
    reservar_margem: boolean
    servidores: boolean
    notificacoes: boolean
    refinanciamento_contrato: boolean
    consultar_contratos: boolean
    aprovacao_reservas: boolean
    importar_movimentacao: boolean
    consultar_importacoes: boolean
    calendario: boolean
    remove_anexo_contrato: boolean
    simulacao_contrato: boolean
}

export interface IPerfil {
    id: string
    nome: string
    tipo: "consignante" | "consignataria"
    consignante_id?: string
    consignataria_id?: string
    atualizado_em: string
    criado_em: string
    descricao: string
    ativo: boolean
    permissoes: Partial<IPerfilPermission>
}

export const perfis: IGenericModel<IPerfil> = {
    ...generic(endpoints.PERFIS)
}