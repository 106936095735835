import React from "react"
import { Link, RouteComponentProps } from "react-router-dom"
import { useBreadcrumb, useStore, useActions } from "hooks"
import { LockOutlined } from "@ant-design/icons"
import { Row, Form, Input, Button } from "antd"
import AuthPage from "./AuthPage"
import queryString from "query-string"

// interface IForgotProps extends RouteComponentProps {

// }

function ResetPassword(props: RouteComponentProps) {
    useBreadcrumb({ items: [{ title: "Alterar senha" }] })

    const { loading } = useStore((state) => state.session)
    const { resetPassword } = useActions((actions) => actions.session)
    const query = queryString.parse(props.location.search)
    const token = query.token
    const email = query.email
    const codOperator = query.codOperator
    const taxNumber = query.taxNumber

    function onSubmit(model: { password: string }) {
        resetPassword({ ...model, token: token as string, email: email as string, codOperator: codOperator as string, taxNumber: taxNumber as string }).then(
            () => {
                props.history.push("/user/reset/success")
            }
        )
    }

    return (
        <AuthPage title="Nova senha">
            <Form onFinish={onSubmit as any}>
                <Form.Item name="password" rules={[{ required: true, message: "Por favor, insira sua nova senha." }]}>
                    <Input prefix={<LockOutlined />} placeholder="Sua nova senha" type="password" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} style={{ width: "100%" }} size="large">
                        Enviar
                    </Button>
                </Form.Item>
                <Row justify="center">
                    <Button type="link">
                        <Link to="/user/login">Voltar a página de entrada</Link>
                    </Button>
                </Row>
            </Form>
        </AuthPage>
    )
}

export default ResetPassword
