import { IGenericModel } from "models/generic"
import { regions, IRegion } from "./regions"
import { secretaries, ISecretary } from "./secretaries"
// import { search, ISearchModel } from "./search"

export interface ISystemModel {
    regions: IGenericModel<IRegion>,
    secretaries: IGenericModel<ISecretary>,
    // search: ISearchModel
}

const system: ISystemModel = {
    regions,
    secretaries,
    // search,
}

export default system