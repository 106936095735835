import React from "react"

import styles from "./style.module.css"
import { Row, Col, Form, Select } from "antd"
import { useGenericStore, useStore } from "hooks"
import { IGroup } from "models/bank/groups"
import { FormInstance } from "antd/lib/form"

interface IRestrictionInputProps {
    form: FormInstance
}

function RestrictionInput(props: IRestrictionInputProps) {
    const { all: groups } = useGenericStore((store) => store.bank.groups)
    const user = useStore((state) => state.session.user)

    return (
        <Row gutter={12}>
            <Col span={8}>
                <Form.Item name={"restriction"} noStyle>
                    <Form.Item
                        name={["restriction", "type"]}
                        label="Quem tem acesso?"
                        rules={[{ required: true }]}
                    >
                        <Select
                            onChange={(value) => {
                                switch (value) {
                                    case "public":
                                        props.form.setFieldsValue({
                                            restriction: { type: "public" },
                                        })
                                        return
                                    case "user":
                                        props.form.setFieldsValue({
                                            restriction: {
                                                type: "user",
                                                ids: [user?.id],
                                            },
                                        })
                                        return
                                    case "group":
                                        props.form.setFieldsValue({
                                            restriction: {
                                                type: "group",
                                                ids: [],
                                            },
                                        })
                                        return
                                }
                            }}
                            // allowClear
                        >
                            <Select.Option value="public">Todos os agentes</Select.Option>
                            <Select.Option value={"user"}>Apenas você</Select.Option>
                            <Select.Option value="group">
                                Agentes em grupos específicos
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Form.Item>
            </Col>
            <Col span={16}>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.restriction?.type !== currentValues.restriction?.type
                    }
                >
                    {({ getFieldValue }) => {
                        return getFieldValue("restriction")?.type === "group" ? (
                            <Form.Item
                                name={["restriction", "ids"]}
                                label="Escolha os grupos que tem acesso"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor escolha pelo menos um grupo.",
                                    },
                                ]}
                            >
                                <Select mode="multiple">
                                    {groups.map((group) => (
                                        <Select.Option
                                            key={group.id}
                                            value={group.id}
                                            title={group.description}
                                        >
                                            {group.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : null
                    }}
                </Form.Item>
            </Col>
        </Row>
    )
}

export default RestrictionInput
