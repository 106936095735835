export default {
    getHtml: () => `<html>
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Termos_e_Condicoes_de_Uso_-_Tanamao_App.Rev.05.03.2020 (2)</title>
        <meta name="generator" content="BCL easyConverter SDK 5.0.210" />
        <style type="text/css">
            body {
                margin-top: 0px;
                margin-left: 0px;
                padding-left: 60px;
                padding-right: 60px;
                max-width: 700px;
                margin: auto;
            }

            #page_1 {
                position: relative;
                overflow: hidden;
                padding: 0px;
                border: none;
                width: 100%;
            }
            #page_1 #id1_1 {
                border: none;
                margin: 0px 0px 0px 0px;
                padding: 0px;
                border: none;
                overflow: hidden;
            }
            #page_1 #id1_2 {
                border: none;
                margin: 64px 0px 0px 147px;
                padding: 0px;
                border: none;
                width: 533px;
                overflow: hidden;
            }

            #page_2 {
                position: relative;
                overflow: hidden;
                padding: 0px;
                border: none;
                width: 100%;
            }
            #page_2 #id2_1 {
                border: none;
                margin: 0px 0px 0px 0px;
                padding: 0px;
                border: none;
                width: 100%;
                overflow: hidden;
            }
            #page_2 #id2_2 {
                border: none;
                margin: 115px 0px 0px 147px;
                padding: 0px;
                border: none;
                width: 533px;
                overflow: hidden;
            }

            #page_3 {
                position: relative;
                overflow: hidden;
                padding: 0px;
                border: none;
                width: 100%;
            }
            #page_3 #id3_1 {
                border: none;
                margin: 0px 0px 0px 0px;
                padding: 0px;
                border: none;
                width: 100%;
                overflow: hidden;
            }
            #page_3 #id3_2 {
                border: none;
                margin: 60px 0px 0px 147px;
                padding: 0px;
                border: none;
                width: 533px;
                overflow: hidden;
            }

            #page_4 {
                position: relative;
                overflow: hidden;
                padding: 0px;
                border: none;
                width: 100%;
            }
            #page_4 #id4_1 {
                border: none;
                margin: 0px 0px 0px 0px;
                padding: 0px;
                border: none;
                width: 100%;
                overflow: hidden;
            }
            #page_4 #id4_2 {
                border: none;
                margin: 53px 0px 0px 147px;
                padding: 0px;
                border: none;
                width: 533px;
                overflow: hidden;
            }

            #page_5 {
                position: relative;
                overflow: hidden;
                padding: 0px;
                border: none;
                width: 100%;
            }
            #page_5 #id5_1 {
                border: none;
                margin: 0px 0px 0px 0px;
                padding: 0px;
                border: none;
                width: 100%;
                overflow: hidden;
            }
            #page_5 #id5_2 {
                border: none;
                margin: 76px 0px 0px 147px;
                padding: 0px;
                border: none;
                width: 533px;
                overflow: hidden;
            }

            #page_6 {
                position: relative;
                overflow: hidden;
                padding: 0px;
                border: none;
                width: 100%;
            }
            #page_6 #id6_1 {
                border: none;
                margin: 0px 0px 0px 0px;
                padding: 0px;
                border: none;
                width: 100%;
                overflow: hidden;
            }
            #page_6 #id6_2 {
                border: none;
                margin: 58px 0px 0px 147px;
                padding: 0px;
                border: none;
                width: 533px;
                overflow: hidden;
            }

            #page_7 {
                position: relative;
                overflow: hidden;
                padding: 0px;
                border: none;
                width: 100%;
            }
            #page_7 #id7_1 {
                border: none;
                margin: 0px 0px 0px 0px;
                padding: 0px;
                border: none;
                width: 100%;
                overflow: hidden;
            }
            #page_7 #id7_2 {
                border: none;
                margin: 105px 0px 0px 147px;
                padding: 0px;
                border: none;
                width: 533px;
                overflow: hidden;
            }

            #page_8 {
                position: relative;
                overflow: hidden;
                padding: 0px;
                border: none;
                width: 100%;
            }
            #page_8 #id8_1 {
                border: none;
                margin: 0px 0px 0px 0px;
                padding: 0px;
                border: none;
                width: 100%;
                overflow: hidden;
            }
            #page_8 #id8_2 {
                border: none;
                margin: 260px 0px 0px 147px;
                padding: 0px;
                border: none;
                width: 533px;
                overflow: hidden;
            }

            .ft0 {
                font: 15px sans-serif;
                line-height: 17px;
            }
            .ft1 {
                font: bold 15px sans-serif;
                line-height: 17px;
            }
            .ft2 {
                font: 15px sans-serif;
                text-decoration: underline;
                line-height: 17px;
            }
            .ft3 {
                font: bold 15px sans-serif;
                margin-left: 10px;
                line-height: 17px;
            }
            .ft4 {
                font: 15px sans-serif;
                text-decoration: underline;
                margin-left: 30px;
                line-height: 17px;
            }
            .ft5 {
                font: 15px sans-serif;
                text-decoration: underline;
                margin-left: 29px;
                line-height: 17px;
            }
            .ft6 {
                font: 15px sans-serif;
                text-decoration: underline;
                margin-left: 31px;
                line-height: 17px;
            }
            .ft7 {
                font: bold 14px sans-serif;
                line-height: 16px;
            }
            .ft8 {
                font: 15px sans-serif;
                text-decoration: underline;
                margin-left: 31px;
                line-height: 16px;
            }
            .ft9 {
                font: 15px sans-serif;
                line-height: 16px;
            }
            .ft10 {
                font: 15px sans-serif;
                text-decoration: underline;
                margin-left: 33px;
                line-height: 17px;
            }
            .ft11 {
                font: bold 15px sans-serif;
                line-height: 16px;
            }
            .ft12 {
                font: 15px sans-serif;
                text-decoration: underline;
                margin-left: 29px;
                line-height: 16px;
            }
            .ft13 {
                font: 15px sans-serif;
                text-decoration: underline;
                margin-left: 34px;
                line-height: 17px;
            }
            .ft14 {
                font: 8px "Calibri";
                line-height: 10px;
            }
            .ft15 {
                font: 15px sans-serif;
                text-decoration: underline;
                margin-left: 26px;
                line-height: 17px;
            }
            .ft16 {
                font: 12px "MS PGothic";
                line-height: 12px;
            }
            .ft17 {
                font: 15px sans-serif;
                margin-left: 12px;
                line-height: 17px;
            }
            .ft18 {
                font: 15px sans-serif;
                margin-left: 12px;
                line-height: 16px;
            }
            .ft19 {
                font: italic 15px sans-serif;
                line-height: 17px;
            }
            .ft20 {
                font: bold 14px sans-serif;
                line-height: 17px;
            }
            .ft21 {
                font: bold 15px sans-serif;
                margin-left: 29px;
                line-height: 17px;
            }

            .p0 {
                text-align: left;
                margin-top: 0px;
                margin-bottom: 0px;
            }
            .p1 {
                text-align: left;
                margin-top: 19px;
                margin-bottom: 0px;
            }
            .p2 {
                text-align: left;
                margin-top: 35px;
                margin-bottom: 0px;
            }
            .p3 {
                text-align: left;
                margin-top: 18px;
                margin-bottom: 0px;
            }
            .p4 {
                text-align: left;
                margin-top: 19px;
                margin-bottom: 0px;
            }
            .p5 {
                text-align: left;
                margin-top: 18px;
                margin-bottom: 0px;
            }
            .p6 {
                text-align: left;
                margin-top: 0px;
                margin-bottom: 0px;
            }
            .p7 {
                text-align: left;
                margin-top: 0px;
                margin-bottom: 0px;
            }
            .p8 {
                text-align: left;
                margin-top: 0px;
                margin-bottom: 0px;
            }
            .p9 {
                text-align: left;
                margin-top: 3px;
                margin-bottom: 0px;
            }
            .p10 {
                text-align: left;
                margin-top: 2px;
                margin-bottom: 0px;
            }
            .p11 {
                text-align: left;
                margin-top: 18px;
                margin-bottom: 0px;
            }
            .p12 {
                text-align: left;
                margin-top: 16px;
                margin-bottom: 0px;
            }
            .p13 {
                text-align: left;
                margin-top: 15px;
                margin-bottom: 0px;
            }
            .p14 {
                text-align: left;
                margin-top: 17px;
                margin-bottom: 0px;
            }
            .p15 {
                text-align: left;
                padding-left: 48px;
                margin-top: 18px;
                margin-bottom: 0px;
                text-indent: -24px;
            }
            .p16 {
                text-align: left;
                padding-left: 48px;
                margin-top: 0px;
                margin-bottom: 0px;
                text-indent: -24px;
            }
            .p17 {
                text-align: left;
                padding-left: 48px;
                margin-top: 3px;
                margin-bottom: 0px;
                text-indent: -24px;
            }
            .p18 {
                text-align: left;
                padding-left: 48px;
                margin-top: 2px;
                margin-bottom: 0px;
                text-indent: -24px;
            }
            .p19 {
                text-align: left;
                padding-left: 48px;
                margin-top: 0px;
                margin-bottom: 0px;
                text-indent: -24px;
            }
            .p20 {
                text-align: left;
                padding-left: 24px;
                margin-top: 0px;
                margin-bottom: 0px;
            }
            .p21 {
                text-align: left;
                padding-left: 48px;
                margin-top: 18px;
                margin-bottom: 0px;
                text-indent: -24px;
            }
            .p22 {
                text-align: left;
                padding-left: 48px;
                padding-right: 76px;
                margin-top: 0px;
                margin-bottom: 0px;
                text-indent: -24px;
            }
            .p23 {
                text-align: left;
                padding-left: 48px;
                margin-top: 4px;
                margin-bottom: 0px;
                text-indent: -24px;
            }
            .p24 {
                text-align: left;
                margin-top: 19px;
                margin-bottom: 0px;
            }
        </style>
    </head>

    <body>
        <div id="page_1">
            <div id="id1_1">
                <p class="p1 ft0">
                    <span class="ft1">FOUR SEVEN SERVIÇOS DE INTERMEDIAÇÃO COMERCIAL LTDA</span>,
                    sociedade anônima fechada, inscrita no CNPJ/MF sob o nº
                    <nobr>22.614.716/0001-11,</nobr> com sede na Rua Dom Luiz, 880, sala 506, Bairro
                    Aldeota, Fortaleza/CE, única e exclusiva proprietária das marcas e dos domínios
                    associados ao <span class="ft1">Aplicativo </span>e ao
                    <span class="ft1">Site </span>doravante, em conjunto, denominados simplesmente
                    “<span class="ft1">Tanamao</span>”.
                </p>
                <p class="p2 ft0">
                    Estes <span class="ft1">Termos de Uso </span>estabelecem as condições que regem
                    a utilização do Aplicativo e do Site pelo Usuário. Para poder usar o Aplicativo
                    e/ou o Site <a href="http://www.tanamaobank.com.br/">(</a
                    ><a href="http://www.tanamaobank.com.br/"
                        ><span class="ft2">www.tanamaobank.com.br</span></a
                    >), você deverá ler, compreender e aceitar estes Termos de Uso e a Política de
                    Privacidade.
                </p>
                <p class="p3 ft0">
                    Caso tenha qualquer dúvida sobre os Termos de Uso e/ou a Política de
                    Privacidade, recomendamos que entre em contato com o Tanamao por meio do
                    <nobr>e-mail</nobr>
                    <a href="mailto:suporte@tanamaobank.com.br"
                        ><span class="ft2">suporte@tanamaobank.com.br</span></a
                    >
                    ANTES de <nobr>aceitá-los.</nobr>
                </p>
                <p class="p4 ft1">
                    <span class="ft1">1.</span
                    ><span class="ft3">SIGNIFICADO DAS PALAVRAS/TERMOS</span>
                </p>
                <p class="p3 ft0">
                    As palavras/termos constantes nestes Termos de Uso e na Política de Privacidade,
                    sempre que usados com a primeira letra em maiúsculo, terão o significado
                    estabelecido abaixo, seja no plural ou no singular:
                </p>
                <p class="p5 ft0">
                    <span class="ft1">(a)</span><span class="ft4">Aplicativo:</span> significa o
                    aplicativo Tanamao adaptado e desenvolvido para operações em telefone celular,
                    tablet ou qualquer outro dispositivo móvel.
                </p>
                <p class="p6 ft0">
                    <span class="ft1">(b)</span><span class="ft5">Cliente:</span> significa toda e
                    qualquer pessoa jurídica, seja ela de direito público ou privado, que firma
                    contrato para utilização e disponibilização do Tanamao aos seus
                    servidores/colaboradores.
                </p>
                <p class="p7 ft0">
                    <span class="ft1">(c)</span><span class="ft6">Conteúdo:</span> significa toda e
                    qualquer informação disponibilizada pelo ou por meio do Aplicativo ou do Site,
                    tais como produtos, serviços, textos, dados, software, imagens, vídeos, áudios,
                    recursos interativos etc., <nobr>incluindo-se</nobr> os códigos fontes
                    empregados para exibição desses conteúdos, como aqueles em linguagem HTML, CSS,
                    PHP, entre outros.
                </p>
                <p class="p8 ft0">
                    <span class="ft1">(d)</span><span class="ft5">Conta Tanamao:</span> significa a
                    conta de acesso criada pelo Usuário no Tanamao.
                </p>
                <p class="p7 ft9">
                    <span class="ft7">(e)</span><span class="ft8">Dados de Conta:</span> significa
                    qualquer informação que o Usuário insira ao se cadastrar no Tanamao, tais como,
                    mas não se limitando, há dados de instituições bancárias, número de agência
                    bancária, número de conta, dívidas e contratos que o Usuário possui, bem como
                    quaisquer outros dados de contas correntes dos Usuários ou que sejam solicitadas
                    em algum momento para finalização de alguma das operações solicitadas pelos
                    Usuários.
                </p>
                <p class="p9 ft0">
                    <span class="ft1">(f)</span
                    ><span class="ft10">Dados Financeiros:</span> quaisquer dados do Usuário
                    coletados pelo Tanamao diretamente junto ao Cliente, com o qual o Usuário possua
                    vínculo empregatício, que detalhem, de forma específica e pessoal, informações
                    sobre a(s) sua(s) dívidas(s) e contrato(s), incluindo, sem limitação, renda
                    mensal, valor de margem consignável, a natureza e tipo de suas verbas e
                    descontos, dívidas e contratos, podendo ser consignadas em folha de pagamento ou
                    não, entre outros dados do tipo relevantes ao planejamento financeiro do
                    Usuário, incluindo, ainda, mas não se limitando, a dados que tenham sido
                    inseridos pelo Usuário em sua Conta Tanamao para individualização de sua
                    experiência.
                </p>
                <p class="p6 ft0">
                    <span class="ft1">(g)</span
                    ><span class="ft4">Dados de Folha de Pagamento:</span> quaisquer dados
                    disponibilizados pelo Cliente e que permitam a identificação do Usuário no ato
                    de cadastramento no Tanamao.
                </p>
                <p class="p7 ft9">
                    <span class="ft11">(h)</span><span class="ft12">Dados Pessoais:</span> significa
                    qualquer dado disponibilizado pelo Usuário que, de alguma forma, o identifique,
                    tais como, mas não se limitando a nome completo, CPF, data de nascimento,
                    endereço, número de telefone ou endereço de <nobr>e-mail.</nobr>
                </p>
                <p class="p10 ft0">
                    <span class="ft1">(i)</span><span class="ft13">Grupo:</span> determinado perfil
                    de usuários de acordo com categorias e critérios baseados nos dados coletados.
                </p>
                <p class="p6 ft0">
                    <span class="ft1">(j)</span><span class="ft10">Parceiros:</span> empresas que
                    mantenham relação contratual de Parceria para disponibilização de produtos e/ou
                    serviços aos Usuários do Tanamao.
                </p>
                <p class="p6 ft0">
                    <span class="ft1">(k)</span
                    ><span class="ft5">Política de Privacidade:</span> significa a política de
                    privacidade que rege as disposições sobre a utilização dos dados do Usuário.
                </p>
            </div>
            <div id="id1_2"></div>
        </div>
        <div id="page_2">
            <div id="id2_1">
                <p class="p6 ft0">
                    <span class="ft1">(l)</span><span class="ft13">Site:</span> significa o endereço
                    eletrônico
                    <a href="http://www.tanamaobank.com.br/"
                        ><span class="ft2">www.tanamaobank.com.br</span></a
                    >
                    ou qualquer outro que vier a <nobr>substituí-lo.</nobr>
                </p>
                <p class="p7 ft0">
                    <span class="ft1">(m)</span><span class="ft15">Software:</span> significa o
                    Software de propriedade exclusiva da FOUR SEVEN SERVIÇOS DE INTERMEDIAÇÃO
                    COMERCIAL LTDA, denominada simplesmente Tanamao, por meio do qual serão geridos,
                    manejados e consolidados os Dados Pessoais, Dados de Folha de Pagamento, Dados
                    de Contas e Dados Financeiros do Usuário, sempre de maneira automatizada.
                </p>
                <p class="p8 ft0">
                    <span class="ft1">(n)</span><span class="ft5">Termos de Uso:</span> condições de
                    uso para a utilização do Aplicativo e do Site.
                </p>
                <p class="p7 ft0">
                    <span class="ft1">(o)</span><span class="ft4">Usuário:</span> significa uma
                    pessoa física, maior de idade, com plena capacidade de contratar, integrante da
                    base de dados dos Clientes, apta a acessar o Aplicativo, e que realiza o seu
                    cadastro pessoal de modo a usufruir das funcionalidades oferecidas pelo Tanamao,
                    aceitando expressamente os presentes Termos de Uso e a Política de Privacidade.
                </p>
                <p class="p11 ft1">
                    <span class="ft1">2.</span
                    ><span class="ft3">INTRODUÇÃO E ADESÃO AO Tanamao</span>
                </p>
                <p class="p3 ft0">
                    O Tanamao tem como prioridade o <nobr>bem-estar</nobr> de seus Usuários, e
                    atuando alinhado com seus Clientes, possibilita o desenvolvimento e a
                    humanização de políticas precisas de gestão de pessoas.
                </p>
                <p class="p12 ft0">
                    O Tanamao foi desenvolvido para ser uma ferramenta simplificada que possibilite
                    a organização financeira de seus Usuários, e atuando alinhado com políticas de
                    gestão de pessoas, o Tanamao está apto a <nobr>adaptar-se</nobr> às prioridades
                    e preferências dos Clientes e Usuários, e assim, ofertar condições que conduzam
                    o Usuário a uma saúde financeira estável.
                </p>
                <p class="p13 ft0">
                    Para usar o Tanamao de maneira satisfatória, teremos sempre um Cliente
                    contratando o Software e disponibilizando dados não individualizáveis dos seus
                    colaboradores/servidores, estes dados irão compor a base do Software, sendo que
                    o Usuário no momento de cadastramento terá que nos permitir acesso aos seus
                    Dados Pessoais, a fim de que os mesmos possam interagir com o Software e com os
                    Parceiros.
                </p>
                <p class="p3 ft0">
                    A partir do momento em que o Usuário aceitar os Termos de Uso e a Política de
                    Privacidade, as disposições neles constantes serão aplicáveis e irão regular
                    plenamente a relação entre o Tanamao e o Usuário. Dessa forma, é recomendável
                    eventual impressão de uma cópia dos mesmos para futura referência. Será dever do
                    Usuário <nobr>manter-se</nobr> atento a possíveis atualizações dos Termos de Uso
                    e da Política de Privacidade, que podem ocorrer a qualquer tempo.
                </p>
                <p class="p11 ft1">
                    <span class="ft1">3.</span><span class="ft3">UTILIZAÇÃO DO SOFTWARE</span>
                </p>
                <p class="p1 ft0">
                    Estes Termos de Uso concedem ao Usuário uma licença pessoal, nacional,
                    revogável, não exclusiva e intransferível de uso do Software e/ou Aplicativo sem
                    cobrança de royalties, sendo certo que o Usuário não poderá utilizar e nem
                    permitir o uso do Software e/ou Aplicativo para qualquer outra finalidade não
                    expressamente prevista nestes Termos de Uso.
                </p>
                <p class="p3 ft0">
                    Para uso do Tanamao o Usuário realizará o seu cadastro no Aplicativo, ato no
                    qual fornecerá ao Tanamao a possibilidade de acesso aos seus Dados Pessoais,
                    CPF, <nobr>e-mail,</nobr> número telefônico, data de nascimento e senha que
                    serão utilizados para acesso e criação da Conta Tanamao do Usuário.
                </p>
                <p class="p3 ft0">
                    O Usuário será responsável pela veracidade, validade e precisão dos Dados
                    Pessoais ou Dados de Conta por ele fornecidos no seu cadastro, inclusive em
                    relação à indicação de endereço de <nobr>e-mail</nobr> válido de sua
                    titularidade e número telefônico, <nobr>competindo-lhe,</nobr> ainda, manter
                    referido cadastro sempre atualizado. O Tanamao não terá qualquer obrigação de
                    policiar ou fiscalizar os Dados Pessoais ou Dados de Conta fornecidos pelos
                    Usuários, mas poderá, ao seu exclusivo critério, excluir as informações que lhe
                    pareçam inverídicas ou ofensivas.
                </p>
            </div>
            <div id="id2_2"></div>
        </div>
        <div id="page_3">
            <div id="id3_1">
                <p class="p7 ft0">
                    O CPF (“<span class="ft2">login</span>”) e a senha usados pelo Usuário para
                    acesso a sua Conta Tanamao são confidenciais e de responsabilidade exclusiva do
                    Usuário, que deverá entrar em contato com o Tanamao, imediatamente, na hipótese
                    de comprometimento do seu sigilo.
                </p>
                <p class="p1 ft0">
                    O Usuário está plenamente ciente e concorda que ao fornecer os seus Dados
                    Pessoais e os seus Dados de Conta ao Tanamao, estará autorizando o Tanamao a
                    usar o Software para buscar Dados Financeiros
                    <span class="ft1"
                        >junto a cada instituição bancária com a qual o Usuário possua dívidas ou
                        contratos </span
                    >e Dados de Folha de Pagamento
                    <span class="ft1"
                        >junto a cada Cliente com quem o Usuário mantem vínculo empregatício</span
                    >, sempre de maneira automatizada. O Tanamao irá então utilizar o Software para
                    organizar tais dados, a fim de atender o propósito do Tanamao, bem como usar
                    tais dados na forma da Política de Privacidade.
                </p>
                <p class="p14 ft0">
                    No que se refere especialmente aos Dados de Conta, Dados de Folha de Pagamento e
                    aos Dados Financeiros, o Usuário entende e concorda que o Tanamao atuará perante
                    os sites das instituições bancárias e ao Cliente, na qualidade de representante
                    do Usuário. O Aceite dos Termos de Uso e da Política de Privacidade implica em
                    uma autorização expressa ao Tanamao para acessar e utilizar os Dados de Conta,
                    Dados de Folha de Pagamento e Dados Financeiros fornecidos por meio dos sites
                    das instituições bancárias e do Cliente.
                </p>
                <p class="p11 ft1">
                    <span class="ft1">4.</span><span class="ft3">DADOS DO USUÁRIO</span>
                </p>
                <p class="p1 ft0">
                    De maneira a possibilitar a completa funcionalidade do Aplicativo e do Site,
                    caso seja de interesse do Usuário, ele deverá informar ao Tanamao os seus Dados
                    Pessoais e Dados de Conta e <nobr>autorizá-lo</nobr> expressamente, na qualidade
                    de mandatário, a acessar seus Dados Financeiros e Dados de Folha de Pagamento
                    disponibilizados no sistema das instituições bancárias e na Cliente.
                </p>
                <p class="p3 ft0">
                    O Tanamao não movimenta ou de qualquer forma interfere nas dívidas, contratos e
                    contas bancárias informadas pelo Usuário, as senhas e/ou códigos de autorização
                    concedidos pelo Usuário se limitam a permitir que o Tanamao busque Dados
                    Financeiros junto a cada instituição bancária que o Usuário possua dívidas e/ou
                    contratos, unicamente para fins lícitos relacionados ao propósito a que o
                    Aplicativo e o Site se destinam.
                </p>
                <p class="p3 ft0">
                    O Tanamao não se responsabilizará pela revisão dos Dados de Conta fornecidos
                    pelo Usuário, bem como pelos Dados Financeiros obtidos junto aos sites das
                    instituições bancárias, seja no que tange à precisão dos dados, seja quanto à
                    legalidade ou ameaça de violação em função do fornecimento desses dados.
                </p>
                <p class="p3 ft0">
                    O Usuário está ciente e concorda que o Tanamao não integra e não pode ser
                    considerado como uma extensão das instituições bancárias das quais os Dados
                    Financeiros são extraídos pelo Software, não sendo o Tanamao responsável pelos
                    produtos e/ou serviços oferecidos por estas, seja de maneira solidária ou
                    subsidiária.
                </p>
                <p class="p3 ft0">
                    O acesso aos Dados de Conta e aos Dados Financeiros do Usuário depende de
                    serviços prestados por suas respectivas instituições bancárias. Sendo assim, o
                    Usuário está ciente e concorda que o Tanamao não assume qualquer
                    responsabilidade quanto à pontualidade, precisão, não entrega ou falha na
                    obtenção dos Dados Financeiros por meio dos sites destas instituições, já que
                    esses dados poderão ser sujeitos a problemas técnicos ou outras dificuldades de
                    conexão que podem resultar em falha na obtenção, perda de dados ou quaisquer
                    outras interrupções de serviços.
                </p>
                <p class="p11 ft1"><span class="ft1">5.</span><span class="ft3">PARCEIROS</span></p>
                <p class="p12 ft0">
                    Objetivando ajudar nossos Usuários a
                    <nobr>organizarem-se</nobr> financeiramente, poderemos divulgar e/ou ofertar
                    produtos e serviços oferecidos por nossos Parceiros como, por exemplo,
                    renegociações de dívidas, consulta ao SPC e SERASA, planos de saúde e
                    odontológicos, atividades de cobranças de
                </p>
            </div>
            <div id="id3_2"></div>
        </div>
        <div id="page_4">
            <div id="id4_1">
                <p class="p7 ft0">
                    dívidas, projetos de educação financeira, planos de telefonia
                    <nobr>pós-pagos</nobr> e recarga de celulares pré- pagos, dentre outros produtos
                    e serviços que entendermos serem adequados e benéficos ao perfil do Usuário,
                    sempre levando em consideração a concordância do Cliente para tais divulgações e
                    ofertas.
                </p>
                <p class="p12 ft0">
                    O Tanamao divulgará tais produtos e/ou serviços por meio do Aplicativo e/ou do
                    Site, nos termos informados pelo Parceiro, de forma automatizada, considerando o
                    Grupo de perfil e/ou o perfil do Usuário. Para fazer tal oferta, o Tanamao não
                    divulgará nenhum Dado Pessoal, Dado de Conta, Dado de Folha de Pagamento ou Dado
                    Financeiro do Usuário sem seu consentimento, conforme detalhado na Política de
                    Privacidade.
                </p>
                <p class="p13 ft0">
                    Em razão das ofertas o Aplicativo e/ou Site poderá conter links para
                    websites/aplicativos dos Parceiros do Tanamao, para os quais o Usuário poderá
                    ser direcionado caso clique em tais links.
                </p>
                <p class="p12 ft0">
                    O Tanamao não tem controle sobre tais websites e/ou aplicativos dos Parceiros,
                    e, portanto, não garante a disponibilidade ou se responsabiliza por conteúdo,
                    publicidade, produtos, serviços ou outros materiais contidos ou disponibilizados
                    pelos websites e/ou aplicativos dos Parceiros.
                </p>
                <p class="p12 ft0">
                    As plataformas dos Parceiros não estão sujeitas aos Termos de Uso ou à Política
                    de Privacidade do Tanamao. Sendo assim, recomendamos que o Usuário leia
                    atentamente os Termos de Uso e a Política de Privacidade aplicáveis a cada
                    plataforma para a qual seja direcionado.
                </p>
                <p class="p11 ft1">
                    <span class="ft1">6.</span
                    ><span class="ft3">LIMITAÇÕES DE USO E INTERFERÊNCIAS</span>
                </p>
                <p class="p4 ft0">Cliente e Usuário não poderão:</p>
                <p class="p15 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Utilizar o Aplicativo, Site e/ou o Software para divulgar informações de
                        qualquer forma que possa implicar em violação de normas aplicáveis no
                        Brasil, de direitos de propriedade do Tanamao e/ou de terceiros ou dos bons
                        costumes, incluindo, sem limitação, a violação de direitos intelectuais,
                        autorais e de privacidade, ou a produção e divulgação de conteúdo ilegal,
                        imoral, inapropriado ou ofensivo;</span
                    >
                </p>
                <p class="p16 ft9">
                    <span class="ft16">✓</span
                    ><span class="ft18"
                        >Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia,
                        reproduzir, doar, alienar de qualquer forma, transferir total ou
                        parcialmente, sob quaisquer modalidades, gratuita ou onerosamente,
                        provisória ou permanentemente, o Aplicativo e/ou o Software, assim como seus
                        módulos, partes, manuais ou quaisquer informações relativas ao mesmo;</span
                    >
                </p>
                <p class="p17 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Empregar softwares, técnicas e/ou artifícios com o intuito de utilizar
                        indevidamente o Aplicativo, o Site e/ou o Software para práticas nocivas ao
                        Tanamao ou a terceiros, tais como </span
                    ><span class="ft19"
                        >exploits, spamming, flooding, spoofing, crashing, root kits</span
                    >, etc.;
                </p>
                <p class="p16 ft9">
                    <span class="ft16">✓</span
                    ><span class="ft18"
                        >Reproduzir, adaptar, modificar e/ou empregar, no todo ou em parte, para
                        qualquer fim, o Software ou qualquer Conteúdo do Aplicativo ou do Site sem a
                        autorização expressa do Tanamao;</span
                    >
                </p>
                <p class="p18 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Publicar ou transmitir qualquer arquivo que contenha vírus, </span
                    ><span class="ft19">worms</span>, cavalos de troia ou qualquer outro programa
                    contaminante ou destrutivo, ou que de outra forma possa interferir no bom
                    funcionamento do Aplicativo, Site e/ou do Software;
                </p>
                <p class="p19 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Aplicar engenharia reversa para descobrir os princípios tecnológicos do
                        Aplicativo, Site e/ou Software, ou permitir sua aplicação, em proveito
                        próprio ou para beneficiar terceiros;</span
                    >
                </p>
                <p class="p19 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Utilizar o Software para finalidade diversa daquela para a qual foi
                        disponibilizado pelo Tanamao;</span
                    >
                </p>
                <p class="p20 ft9">
                    <span class="ft16">✓</span
                    ><span class="ft18"
                        >Divulgar, utilizar ou modificar indevidamente os dados de outros Usuários;
                        e,</span
                    >
                </p>
                <p class="p20 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Utilizar o Aplicativo e/ou o Software, ou permitir seu uso, para benefício
                        de terceiros.</span
                    >
                </p>
                <p class="p4 ft1">
                    <span class="ft1">7.</span
                    ><span class="ft3">POLÍTICA DE PRIVACIDADE E INFORMAÇÕES CONFIDENCIAIS</span>
                </p>
                <p class="p3 ft0">
                    Ao <nobr>cadastrar-se</nobr> no Tanamao o Usuário concorda com a Política de
                    Privacidade. O Aceite da Política de Privacidade irá autorizar o Tanamao a usar
                    os dados do Usuário na maneira descrita no documento.
                </p>
            </div>
            <div id="id4_2"></div>
        </div>
        <div id="page_5">
            <div id="id5_1">
                <p class="p8 ft1">
                    <span class="ft1">8.</span><span class="ft3">PROPRIEDADE INTELECTUAL</span>
                </p>
                <p class="p1 ft0">
                    O Usuário não adquire, por meio dos presentes Termos de Uso ou da Política de
                    Privacidade, nenhum direito de propriedade intelectual ou outros direitos
                    exclusivos, incluindo patentes, desenhos, bases de dados, marcas, direitos
                    autorais ou direitos sobre informações confidenciais ou segredos de negócio,
                    sobre ou relacionados ao Software, ao Aplicativo e/ou ao Site, os quais são de
                    propriedade exclusiva da FOUR SEVEN SERVIÇOS DE INTERMEDIAÇÃO COMERCIAL LTDA.
                </p>
                <p class="p3 ft0">
                    Caso o Usuário venha a desenvolver um novo módulo ou produto que caracterize
                    cópia, de todo ou em parte, quer seja do dicionário de dados, quer seja do
                    programa, será considerado como parte do Software, ficando, portanto, sua
                    propriedade incorporada pelo Tanamao e seu uso condicionado a estas cláusulas
                    contratuais.
                </p>
                <p class="p3 ft0">
                    O Usuário poderá realizar o download ou imprimir uma cópia das informações,
                    planilhas e gráficos disponibilizados por meio do Site desde que para uso
                    pessoal e não comercializável. Qualquer utilização deste material e/ou do
                    Conteúdo do Site, no todo ou em parte, que não para uso pessoal do Usuário, nos
                    termos destes Termos de Uso, sem que haja prévio consentimento por escrito da
                    FOUR SEVEN SERVIÇOS DE INTERMEDIAÇÃO COMERCIAL LTDA, é expressamente proibida.
                </p>
                <p class="p3 ft0">
                    Todo e qualquer Conteúdo disponibilizado no Aplicativo e/ou no Site, tais como,
                    mas não se limitando a, textos, gráficos, imagens, logos, ícones, fotografias,
                    conteúdo editorial, notificações, softwares e qualquer outro material, pertencem
                    exclusivamente a FOUR SEVEN SERVIÇOS DE INTERMEDIAÇÃO COMERCIAL LTDA e são
                    protegidos pela lei brasileira no que se refere à propriedade intelectual e aos
                    direitos autorais. Em nenhuma hipótese, o Usuário terá acesso ao código fonte do
                    Software ora licenciado, já que o código fonte do Software é de propriedade
                    intelectual da FOUR SEVEN SERVIÇOS DE INTERMEDIAÇÃO COMERCIAL LTDA.
                </p>
                <p class="p11 ft1">
                    <span class="ft1">9.</span
                    ><span class="ft3">LIMITAÇÃO DE RESPONSABILIDADE</span>
                </p>
                <p class="p4 ft0">O Tanamao responderá e garante que:</p>
                <p class="p21 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Não compartilha nenhum Dado Pessoal, Dado de Folha de Pagamento, Dado
                        Financeiro ou Dado de Conta do Usuário com terceiros sem seu
                        consentimento;</span
                    >
                </p>
                <p class="p20 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Mantém total transparência sobre a utilização dos dados; e</span
                    >
                </p>
                <p class="p20 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Coleta, usa, armazena e trata os dados de forma segura e sigilosa.</span
                    >
                </p>
                <p class="p11 ft0">
                    O Tanamao não responderá, em nenhuma hipótese, ainda que em caráter solidário ou
                    subsidiário:
                </p>
                <p class="p15 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Pela autenticidade, validade e precisão dos dados fornecidos pelos Usuários
                        e/ou coletadas nos sites das instituições bancárias, competindo ao Usuário
                        verificar, por conta própria, se tais informações são verdadeiras e
                        completas antes de tomar qualquer decisão nelas baseada;</span
                    >
                </p>
                <p class="p16 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Pelos serviços ou produtos oferecidos no Aplicativo e/ou Site por Parceiros
                        ou quaisquer terceiros, inclusive no que diz respeito a sua disponibilidade,
                        qualidade, quantidade, características essenciais, ofertas, preços, formas
                        de pagamento ou quaisquer outros elementos a ele referentes;</span
                    >
                </p>
                <p class="p22 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Por eventuais prejuízos sofridos pelos Usuários em razão da tomada de
                        decisões com base nas informações disponibilizadas no Aplicativo e no
                        Site;</span
                    >
                </p>
                <p class="p16 ft9">
                    <span class="ft16">✓</span
                    ><span class="ft18"
                        >Por eventuais prejuízos sofridos pelos Usuários em razão de falhas
                        momentâneas nos sistemas de informática ou nos servidores que independam de
                        culpa do Tanamao ou em sua conectividade com a internet de modo geral,
                        devendo o Usuário manter, às suas expensas, linha de telecomunicação, modem,
                        software de comunicação, endereço de correio eletrônico e outros recursos
                        necessários à comunicação com o Tanamao sempre atualizados;</span
                    >
                </p>
                <p class="p23 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Por situações de caso fortuito ou força maior, nos termos do artigo 393 do
                        Código Civil Brasileiro;</span
                    >
                </p>
            </div>
            <div id="id5_2"></div>
        </div>
        <div id="page_6">
            <div id="id6_1">
                <p class="p19 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Por danos causados por programas nocivos ao Software, tais como, mas sem se
                        limitar a vírus, </span
                    ><span class="ft19">trojans e hackers</span>.
                </p>
                <p class="p1 ft0">
                    O Tanamao não garante que as funções contidas no Software atendam inteiramente
                    às necessidades do Usuário, que a operação do Software será ininterrupta ou
                    livre de erros, que qualquer funcionalidade continuará disponível, que os
                    defeitos no Software serão corrigidos imediatamente ou que o Software será
                    compatível ou funcionará com qualquer Software, aplicações ou serviços de
                    terceiros.
                </p>
                <p class="p2 ft1">
                    O Tanamao não se responsabiliza por qualquer decisão financeira tomada pelo
                    Usuário com base nas informações obtidas por meio da utilização do Software<span
                        class="ft0"
                        >, incluindo, sem limitação, qualquer decisão tomada </span
                    ><nobr><span class="ft0">utilizando-se</span></nobr
                    ><span class="ft0">
                        de eventual informação incorreta/desatualizada disponibilizada pelo Tanamao.
                        Será responsabilidade exclusiva do Usuário conferir qualquer informação
                        disponibilizada pelo Tanamao, junto à instituição bancária de origem dos
                        respectivos Dados Financeiros e junto ao Cliente.</span
                    >
                </p>
                <p class="p11 ft1">
                    <span class="ft20">10.</span><span class="ft21">INDENIZAÇÃO</span>
                </p>
                <p class="p3 ft0">
                    O Usuário concorda em defender, indenizar e manter íntegro o Tanamao e suas
                    afiliadas, diretores, empregados e agentes, de e contra quaisquer encargos,
                    ações ou demandas, incluindo, mas não se limitado a honorários advocatícios
                    razoáveis, resultantes da sua eventual utilização indevida do Aplicativo, do
                    Site, do Software e/ou do seu Conteúdo e/ou da violação das condições ora
                    pactuadas.
                </p>
                <p class="p3 ft0">
                    Em nenhum caso o Tanamao será responsável por danos pessoais ou qualquer
                    prejuízo incidental, indireto ou consequente, lucros cessantes, incluindo, sem
                    limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de
                    transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro
                    prejuízo ou perda comercial, decorrentes ou relacionados ao seu uso ou sua
                    inabilidade em usar o Software ou o Tanamao, por qualquer outro motivo.
                </p>
                <p class="p3 ft0">
                    O Tanamao atuará no limite do possível para manter os dados coletados sempre
                    seguros, inclusive, adotando medidas de segurança e proteção compatíveis com a
                    natureza dos dados coletados, fazendo uso e armazenando, de acordo com os temos
                    previsto na Política de Privacidade.
                </p>
                <p class="p1 ft0">
                    O Usuário está ciente de que os Dados Pessoais, Dados de Folha de Pagamento,
                    Dados de Conta e Dados Financeiros disponibilizados pelo Tanamao constituem a
                    totalidade das informações a que o Tanamao teve acesso, o que não significa
                    dizer que tais informações correspondem a todas as empresas existentes no
                    mercado.
                </p>
                <p class="p3 ft0">
                    O Usuário concorda que o Tanamao disponibilize as informações conforme lhe são
                    fornecidas legalmente por fontes públicas (órgãos, registros e entidades
                    públicos) e privadas (pessoas jurídicas de direito privado),
                    <nobr>cabendo-lhe,</nobr> tão somente, atualizar as informações constantes
                    constituídas de Dados Pessoais, Dados de Conta, Dados de Folha de Pagamento e
                    Dados Financeiros, não ingressando o Tanamao no mérito destas informações ou na
                    substância de eventual relação existente entre as fontes, públicas ou privadas,
                    e seus respectivos clientes, razão pela qual não responde perante o Usuário pela
                    veracidade das informações disponibilizadas.
                </p>
                <p class="p14 ft0">
                    Desta forma, não consistirá infração a existência de determinados Dados
                    Pessoais, Dados de Conta, Dados de Folha de Pagamento e Dados Financeiros
                    contendo erros, imprecisões ou mesmo omissões no conteúdo ou na substância das
                    informações existentes no Tanamao, ainda que estes tenham gerado eventuais
                    prejuízos ao Usuário.
                </p>
                <p class="p3 ft0">
                    O Usuário declara estar ciente de que nenhuma indenização lhe será devida pelo
                    Tanamao em razão de erros, imprecisões ou mesmo omissões no conteúdo ou na
                    substância das informações, que têm caráter meramente subsidiário e de
                    referência, sendo imperioso que o Usuário ao constatar qualquer
                </p>
            </div>
            <div id="id6_2"></div>
        </div>
        <div id="page_7">
            <div id="id7_1">
                <p class="p7 ft0">
                    dessas situações comunique imediatamente o Tanamao no <nobr>e-mail</nobr>
                    <a href="mailto:suporte@tanamaobank.com.br"
                        ><span class="ft2">suporte@tanamaobank.com.br</span></a
                    >
                    para que possamos corrigir com presteza o erro, imprecisão ou mesmo omissão no
                    conteúdo ou na substância desta informação.
                </p>
                <p class="p4 ft1">
                    <span class="ft20">11.</span
                    ><span class="ft21">VIGÊNCIA E EXCLUSÃO DA CONTA Tanamao</span>
                </p>
                <p class="p3 ft0">
                    Os Termos de Uso e a Política de Privacidade vigerão por prazo indeterminado, a
                    partir do ato de aceitar do Usuário, podendo ser modificados ou rescindidos
                    unilateralmente pelo Tanamao a qualquer tempo, sem qualquer ônus, mediante
                    simples comunicação através do Aplicativo e/ou do Site ou de mensagem para o
                    <nobr>e-mail</nobr> e/ou SMS para o número telefônico indicado no cadastro
                    pessoal do Usuário.
                </p>
                <p class="p2 ft0">
                    O Usuário poderá, a qualquer momento, solicitar a exclusão da sua Conta Tanamao,
                    encerrando seu relacionamento com o Tanamao, mediante solicitação realizada
                    diretamente através do <nobr>e-mail</nobr>
                    <a href="mailto:suporte@tanamaobank.com.br"
                        ><span class="ft2">suporte@tanamaobank.com.br</span></a
                    ><a href="mailto:suporte@tanamaobank.com.br">. </a>Neste caso, os dados do
                    Usuário serão tratados da forma prevista na Política de Privacidade, mantendo
                    aqueles que não podem ser excluídos em cumprimento ao disposto na legislação
                    brasileira.
                </p>
                <p class="p11 ft1">
                    <span class="ft20">12.</span
                    ><span class="ft21">SUSPENSÃO OU ENCERRAMENTO DO ACESSO AO APLICATIVO</span>
                </p>
                <p class="p24 ft0">
                    O Tanamao poderá, ao seu exclusivo critério, a qualquer tempo, e sem a
                    necessidade de comunicação prévia ao Usuário:
                </p>
                <p class="p15 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Encerrar, modificar ou suspender, total ou parcialmente, o acesso do
                        Usuário ao Aplicativo e/ou ao Site, quando referido acesso ou cadastro
                        violar as condições estabelecidas nestes Termos de Uso e/ou na Política de
                        Privacidade;</span
                    >
                </p>
                <p class="p19 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Excluir, total ou parcialmente, as informações cadastradas pelo Usuário que
                        não estejam em consonância com as disposições destes Termos de Uso; e</span
                    >
                </p>
                <p class="p16 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Acrescentar, excluir, suspender, total ou parcialmente, e/ou modificar o
                        Conteúdo oferecido pelo Tanamao ou por seus Parceiros através do Aplicativo
                        e/ou do Site, independentemente do prazo estipulado em campanhas de
                        divulgação, sejam elas promocionais ou não.</span
                    >
                </p>
                <p class="p3 ft0">
                    Poderá o Tanamao, ainda, a seu exclusivo critério, suspender, modificar ou
                    encerrar as atividades do Aplicativo e/ou do Site, mediante comunicação prévia
                    ao Usuário, salvo nas hipóteses de caso fortuito ou força maior.
                </p>
                <p class="p11 ft1">
                    <span class="ft20">13.</span
                    ><span class="ft21"
                        >MODIFICAÇÕES AOS TERMOS DE USO E/OU A POLÍTICA DE PRIVACIDADE</span
                    >
                </p>
                <p class="p1 ft0">
                    O Tanamao poderá, a qualquer tempo, alterar estes Termos de Uso ou a Política de
                    Privacidade, ao seu exclusivo critério. Quaisquer alterações nestes Termos de
                    Uso e na Política de Privacidade serão informadas por meio do Site e/ou do
                    Aplicativo. Caso o Usuário continue utilizando os serviços disponibilizados pelo
                    Site e/ou pelo Aplicativo, será considerado que ele concorda com as alterações.
                </p>
                <p class="p3 ft0">
                    Será sempre indicada a data da última atualização realizada pelo Tanamao aos
                    presentes Termos de Uso e Política de Privacidade.
                </p>
                <p class="p3 ft0">
                    O Usuário entende e concorda que, assim que publicada a alteração destes Termos
                    de Uso e/ou Política de Privacidade no Site ou no Aplicativo, o uso do Site e do
                    Aplicativo passarão a ser submetidos aos Termos de Uso e/ou Política de
                    Privacidade atualizados.
                </p>
                <p class="p11 ft1">
                    <span class="ft20">14.</span><span class="ft21">COMUNICAÇÃO</span>
                </p>
            </div>
            <div id="id7_2"></div>
        </div>
        <div id="page_8">
            <div id="id8_1">
                <p class="p7 ft0">
                    A comunicação do Tanamao com o Usuário poderá ser mediante número de telefone,
                    SMS e/ou do <nobr>e-mail</nobr> fornecidos, ou pelo chat do Aplicativo, e
                    ocorrerá sempre que necessário, para tratar de assuntos relacionados ao
                    Aplicativo e/ou Site, podendo ocorrer para esclarecer sobre novas
                    funcionalidades, tirar dúvidas de caráter comercial e/ou promocional, ofertar
                    produtos e serviços do Tanamao e/ou dos seus Parceiros, bem como para
                    compreender sobre a experiencia do Usuário.
                </p>
                <p class="p3 ft0">
                    O Tanamao a qualquer tempo, mediante comunicação prévia ao Usuário no endereço
                    de <nobr>e-mail</nobr> por este indicado em seu cadastro pessoal ou por meio de
                    aviso através do Aplicativo e/ou no Site, poderá:
                </p>
                <p class="p15 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17"
                        >Definir preços para oferecimento de determinados conteúdos e/ou serviços,
                        ainda que inicialmente tenham sido ofertados de forma gratuita, sendo a
                        utilização dos mesmos, após o referido aviso, considerada como concordância
                        do Usuário com tais preços; e,</span
                    >
                </p>
                <p class="p16 ft0">
                    <span class="ft16">✓</span
                    ><span class="ft17">Enviar ao Usuário mensagens de </span><nobr>e-mail</nobr> ou
                    outras correspondências de caráter informativo, comercial e/ou promocional,
                    salvo expressa solicitação em contrário pelo Usuário, nos termos da Política de
                    Privacidade.
                </p>
                <p class="p1 ft0">
                    Para qualquer assunto relacionado às condições destes Termos de Uso ou da
                    Política de Privacidade, bem como referente ao Aplicativo, Site, Conteúdo e/ou
                    Software o Usuário deverá entrar em contato com o Tanamao por meio do
                    <nobr>e-mail</nobr>
                    <a href="mailto:suporte@tanamaobank.com.br"
                        ><span class="ft2">suporte@tanamaobank.com.br.</span></a
                    >
                </p>
                <p class="p11 ft1">
                    <span class="ft20">15.</span><span class="ft21">DISPOSIÇÕES GERAIS</span>
                </p>
                <p class="p3 ft0">
                    Estes Termos de Uso não geram nenhum contrato de sociedade, franquia ou relação
                    de trabalho entre o Tanamao e o Usuário e/ou seus Parceiros, sendo que os Termos
                    de Usos e a Política de Privacidade constituem a totalidade do acordo sobre as
                    condições de uso do Aplicativo, Site, Conteúdo e do Software.
                </p>
                <p class="p3 ft0">
                    Caso qualquer disposição destes Termos de Usos ou da Política de Privacidade
                    seja considerada ilegal, nula ou inexequível por qualquer razão, as disposições
                    restantes não serão afetadas e <nobr>manter-se-ão</nobr> válidas e aplicáveis na
                    máxima extensão possível.
                </p>
                <p class="p1 ft0">
                    Qualquer falha do Tanamao para impor ou exercer qualquer disposição destes
                    Termos de Uso, da Política de Privacidade ou direitos conexos, não constitui uma
                    renúncia a esse direito ou disposição.
                </p>
                <p class="p11 ft1">
                    <span class="ft20">16.</span><span class="ft21">LEI E FORO APLICÁVEIS</span>
                </p>
                <p class="p1 ft0">
                    Estes Termos de Uso e a Política de Privacidade serão interpretados
                    exclusivamente segundo as leis da República Federativa do Brasil, sendo que as
                    partes elegem o foro da Comarca de Fortaleza, capital do Estado do Ceará, como o
                    único competente para dirimir quaisquer litígios relacionados ao Aplicativo,
                    Site, Conteúdo, Software, Termos de Uso e/ou da Política de Privacidade.
                </p>
                <p class="p11 ft0">Última revisão: 10 de setembro de 2019.</p>
            </div>
            <div id="id8_2"></div>
        </div>
    </body>
</html>`,
}
