import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IConsignataria } from "models/consignatarias"
import { generateFileMockData } from "./bank_mock/files.mock"

export default generateGenericMock(endpoints.CONSIGNATARIAS, generateConsignatariaMockData, { min: 8, max: 8 })
export const documentosConsignataria = generateGenericMock(`${endpoints.CONSIGNATARIAS}/:id/documentos`, generateFileMockData)
export const verbaConsignataria = generateGenericMock(`${endpoints.CONSIGNATARIAS}/:consignatariaId/verba`, (index: string) => ({
    id: index,
    produto: "Empréstimo 30%",
    codigo: '8989484',
    tipoFinanceiro: true
}))
export const consignatariasDocsMock = generateGenericMock(`${endpoints.CONSIGNATARIAS}/:consignatariaId/docs`, ((index: string) => [generateFileMockData()]))

export function generateConsignatariaMockData(index: string): IConsignataria {
    return {
        id: index || "0",
        atualizadoEm: faker.date.past().toISOString(),
        criadoEm: faker.date.past().toISOString(),
        razao_social: [
            "Banco do Brasil",
            "Bradesco",
            "Caixa Economica Federal",
            "Banco Pan",
            "Itau",
            "Cruzeiro do Sul",
            "Banco Santander",
            "Odonto System"
        ][parseInt(index, 10)],
        consignante_id: "0",
        codigo: "000" + index,
        tipo: faker.helpers.randomize(['Banco', 'Associação', 'Sindicato', 'Benefício']),
        cnpj: faker.helpers.randomize(['24986321780048', '74536810329887']),
        atividade_empresarial: faker.helpers.randomize(['Sorveteria', 'Pizzaria']),
        telefone: '85999999999',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        pais: 'Brasil',
        cep: '',
        docs: [generateFileMockData(), generateFileMockData()],
        bloqueado: faker.helpers.randomize([true, false]),
        motivo: 'Bloqueado por motivos fúteis.',
        verba_descontos: [
            {
                id: index,
                produto_id: "Empréstimo 30%",
                codigo: '123',
                tipoFinanceiro: true
            },
            {
                id: index + 1,
                produto_id: "Recarga",
                codigo: '567',
                tipoFinanceiro: false
            },
        ]
    }
}

