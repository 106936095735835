import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IGroup {
    id: string
    name: string
    description: string
    updatedAt: string
    createdAt: string
    agents: string[]
    businessHours: string[]
    escalation: boolean
    escalationHours: number
    escalationNotifyEmails: string[]
    autoAssign: boolean
    allowAgentAvailability: boolean
}

export const groups: IGenericModel<IGroup> = {
    ...generic(endpoints.bank.GROUPS)
}