import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IChannel } from "models/bank/channels"
import { Channel } from "lib/constants"

export default generateGenericMock(endpoints.bank.CHANNELS, generateChannelsMockData)

export const get_qrcode = generateGenericMock(endpoints.bank.WHATSAPP_QRCODE + "/:id", () => ({
    qrcodeUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png"
}))

export const restart_server_zap = generateGenericMock(endpoints.bank.WHATSAPP_RESTART_SERVER + "/:id", () => ({}))

export function generateChannelsMockData(index: string): IChannel {
    return {
        id: String(index),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        name: faker.random.words(),
        description: faker.random.words(10),
        active: faker.random.boolean(),
        status: faker.helpers.randomize([Channel.Status.CONNECTED, Channel.Status.DISCONNECTED, Channel.Status.QR_CODE]),
        type: faker.helpers.randomize([Channel.Type.EMAIL, Channel.Type.FACEBOOK, Channel.Type.WHATSAPP, Channel.Type.MESSENGER]),
        extra: {
            url: faker.internet.url(),
            phone: faker.phone.phoneNumber(),
            image: faker.image.business(),
            email: faker.internet.domainWord() + "@gm.bank.com",
        },
    }
}

