import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IPerfil } from "models/perfis"

export default generateGenericMock(endpoints.PERFIS, generatePerfilMockData)

export function generatePerfilMockData(index: string, model?: Partial<IPerfil>): IPerfil {
    return {
        id: index || "0",
        nome: faker.random.words(),
        atualizado_em: faker.date.past().toISOString(),
        criado_em: faker.date.past().toISOString(),
        descricao: faker.random.words(10),
        tipo: faker.helpers.randomize(["consignante", "consignataria"]),
        consignataria_id: "0",
        consignante_id: "0",
        ativo: faker.random.boolean(),
        permissoes: {
            perfis: true,
            usuarios: true,
            auditoria: true,
            liberacao_usuarios: true,
            avisos: true,
            consignatarias: true,
            tabela_taxas: true,
            parametros_convenio: true,
            consulta_margem: true,
            reservar_margem: true,
            servidores: true,
            notificacoes: true,
            refinanciamento_contrato: true,
            consultar_contratos: true,
            aprovacao_reservas: true,
            importar_movimentacao: true,
            consultar_importacoes: true,
            remove_anexo_contrato: true,
            simulacao_contrato: true,
            calendario: true,
        },
        ...model
    }
}