import faker from "faker"
// import { generatePerfilMockData } from "mock/bank_mock/perfis.mock"
import { generateUserMockData } from "mock/users.mock"
import { generatePerfilMockData } from "mock/perfis.mock"
// import { generateSecretaryMockData } from "mock/system_mock/secretaries.mock"
import { IUserSession } from "models/session"
// import { generateAppMockData } from "mock/bank_mock/marketplace.mock"

export const generateLoginMockData = (): IUserSession => {
    return {
        ...generateUserMockData("0"),
        cpf: "06072330363",
        balance: 10,
        perfis: [
            generatePerfilMockData("0", { nome: "Administrador", tipo: "consignante" }),
            generatePerfilMockData("1", { nome: "Master Bradesco", tipo: "consignataria" }),
        ],
        // "products": {
        //     "bank": {
        //         "createdAt": faker.date.recent() + '',
        //         "type": faker.helpers.randomize(["trial", "subscription"]),
        //         "plan": {
        //             "state": faker.helpers.randomize(["active", "expired"]),
        //             "name": "Pro",
        //             "maxAgents": faker.random.number({ min: 5, max: 20 })
        //         },
        //         "perfil": generatePerfilMockData("0"),
        //         "preferences": {
        //             isDarkBrand: true,
        //         },
        //     },
        // },
        deleted: false,
        ativo: true,
        remember: true,
        admin: true,
        owner: true,
        token: faker.random.uuid(),
        workspace: {
            id: faker.random.uuid(),
            name: faker.company.companyName(),
            // "subdomain": faker.internet.domainName(),
            // "locale": faker.helpers.randomize(["pt-BR", "eng-US"]),
            // "secretary": generateSecretaryMockData("0"),
            // "logo": faker.image.business(),
            // "apps": [generateAppMockData('0')],
        },
    }
}

export default {
    endpoint: "/users/login",
    latency: 1000,
    method: {
        post: {
            success: {
                statusCode: 200,
                body: generateLoginMockData,
            },
            error: {
                statusCode: 401,
                error: {
                    errors: [
                        {
                            key: null,
                            type: "ValidationError",
                            descriptions: ["E-mail ou senha incorretos. Por favor tente novamente."],
                        },
                    ],
                },
            },
        },
    },
}
