import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IPerson } from "./contacts"

export interface IAgent {
    id: string
    email: string
    updatedAt: string
    createdAt: string
    active: boolean
    perfilId: string
    status: 'invited' | 'registered'
    contact: IPerson
}

export const agents: IGenericModel<IAgent> = {
    ...generic(endpoints.bank.AGENTS),
}