import numeral from "numeral"

// load a locale
numeral.register('locale', 'pt-BR', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: 'R$'
    }
});

// switch between locales
numeral.locale('pt-BR');

const cpf = (v?: string) => {
    if (v) {
        v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
        v = v.substring(0, 11)
        v = v.replace(/(\d{3})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") // Coloca um hífen entre o terceiro e o quarto dígitos
    }
    return v
}

const cnpj = (v?: string) => {
    if (v) {
        v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
        v = v.substring(0, 14)
        v = v.replace(/(\d{2})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, "$1/$2") // Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{4})(\d{1,2})$/, "$1-$2") // Coloca um hífen entre o terceiro e o quarto dígitos
    }
    return v
}

const cell = (v?: string) => {
    if (v) {
        v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
        if (v.length > 11) {
            return cellCountry(v)
        }
        // v = v.substring(0, 11)
        v = v.replace(/^(\d\d)(\d)/g, "($1) $2") // Coloca parênteses em volta dos dois primeiros dígitos
        if (v.length <= 13) {
            v = v.replace(/(\d{4})(\d)/, "$1-$2") // Coloca hífen entre o quarto e o quinto dígitos
        } else {
            v = v.replace(/(\d{5})(\d)/, "$1-$2") // Adicionando possibilidade do nono dígito.
        }
    }
    return v
}

const cellCountry = (v?: string) => {
    if (v) {
        v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
        if (v.length > 13) {
            return v
        }
        // v = v.substring(0, 13)
        if (v.length > 12) {
            v = v.replace(/^(\d\d)(\d\d)(\d)/g, "+$1 ($2) $3") // Coloca parênteses em volta dos dois primeiros dígitos
            v = v.replace(/(\d{5})(\d)/, "$1-$2") // Adicionando possibilidade do nono dígito.
        } else {
            v = v.replace(/^(\d\d)(\d\d)(\d)/g, "+$1 ($2) $3") // Coloca parênteses em volta dos dois primeiros dígitos
            v = v.replace(/(\d{4})(\d)/, "$1-$2") // Adicionando possibilidade do nono dígito.
        }
    }
    return v
}

const cep = (v?: string) => {
    if (v) {
        v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
        v = v.substring(0, 8)
        v = v.replace(/(\d{5})(\d)/, "$1-$2")
    }
    return v
}

const number = (v?: string) => {
    if (v) {
        v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    }
    return v
}

const beginWithSlash = (v?: string) => {
    // if (v.length === 1) {
    //     if(v === '/') {
    //         v = ''
    //     }
    // }
    // if (v.length > 3 && v.substr(v.length - 1) === '}' && v.substr(v.length - 2) !== '}') {
    //     v = v.substring(0, v.length - 2)
    //     if (v.length < 3) {
    //         v = ''
    //     }
    // }
    if (v) {
        v = v.replace(/\//g, "")
        v = v.replace(/ /g, "_")
        // v = v.replace(/{/g, "")
        // v = v.replace(/}/g, "")
        // v = v.replace(/}/g, "")
        v = `/${v}`
    }
    return v
}

export const currency = (v?: string | number) => {
    return `R$ ${numeral(parseFloat((v + ""))).format("0,0.00")}`
}

export const parseTaxa2dec = (value?: string) => {
    let numbers = value?.match(/\d/g)?.join("") || "0"
    const numbersInt = parseInt(numbers, 10)
    let result = (numbersInt / 100).toFixed(2)
    return result
}

export const parseTaxa8dec = (value?: string) => {
    let numbers = value?.match(/\d/g)?.join("") || "0"
    const numbersInt = parseInt(numbers, 10)
    let result = (numbersInt / 100000000).toFixed(8)
    return result
}

export const parseTaxa6dec = (value?: string) => {
    let numbers = value?.match(/\d/g)?.join("") || "0"
    const numbersInt = parseInt(numbers, 10)
    let result = (numbersInt / 1000000).toFixed(6)
    return result
}

export const parseDecimal = (value?: string) => {
    let numbers = value?.match(/\d/g)?.join("") || "0"
    const numbersInt = parseInt(numbers, 10)
    let result = (numbersInt / 100).toFixed(2)
    return result
}

export const formatterCurrency = (value?: string | number) => {
    const number = parseFloat((value + ""))
    return "R$ " + numeral(number).format('0,0.00')
    // return `R$ ${(value + "")?.replace('.', ',')}`
}

export const formatterComma = (value?: string | number) => {
    return (value + "")?.replace('.', ',')
}

export default {
    cpf,
    cell,
    cellCountry,
    cep,
    cnpj,
    number,
    currency,
    beginWithSlash,
}
