import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IContact } from "models/bank/contacts"
import { IOrganization } from "models/bank/organizations"
import { InputType } from "lib/constants"

export default generateGenericMock(endpoints.bank.ORGANIZATIONS, generateMockOrganization)

export function generateMockOrganization(): IOrganization {
    const customType = faker.helpers.randomize([InputType.Boolean, InputType.Date, InputType.Decimal, InputType.MultiSelect, InputType.Number, InputType.Select, InputType.Text, InputType.Textarea])

    return {
        id: faker.random.uuid(),
        // avatar: faker.image.abstract(),
        name: faker.company.companyName(),
        description: faker.company.catchPhraseDescriptor(),
        notes: faker.random.words(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        tagIds: [faker.random.uuid()],
        email: faker.internet.email(),
        phone: faker.phone.phoneNumber(),
        domains: [faker.internet.domainName()],
        customFields: {
            [faker.random.word()]: {
                type: customType,
                value: faker.random.words()
            }
        }
    }
}

