import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateMockContact } from "./contacts.mock"
import { InputType } from "lib/constants"
import { generateMockOrganization } from "./organizations.mock"
import { ITicket, ITicketEventMessage, ITicketEventUpdate } from "models/bank/tickets"
import { ITimelineEvent } from "models/bank/contacts"
import { generateChannelsMockData } from "./channels.mock"
import { StatusEnum, PriorityEnum, TypeEnum } from "lib/constants/Ticket"
import { generateAgentMockData } from "./agents.mock"
import { generateGroupMockData } from "./groups.mock"
import { IInputType } from "components/form/ActionsInput"
import _ from "lodash"
import { generateTagMockData } from "./tags.mock"

export default generateGenericMock(endpoints.bank.EVENTS, generateEventMockData)

function generateEventMessageMock(partial?: Partial<ITicketEventMessage>): ITicketEventMessage {
    return {
        id: faker.random.uuid(),
        createdAt: faker.helpers.randomize(["2020-01-02T00:08:10.393Z", "2020-01-02T00:08:11.393Z", "2020-01-02T00:08:12.393Z", "2020-01-04T00:08:10.393Z"]),
        attachments: [],
        body: `<p>${faker.random.words(faker.random.number({max: 10, min: 1}))}</p>`,
        type: faker.helpers.randomize(['reply', 'note']),
        from: generateMockContact(), // AGENTE OU CONTATO
        fromEntity: faker.helpers.randomize(['contact', 'agent']),
        toEntity: 'channel',
        channel: generateChannelsMockData('0'),
        ...partial
    }
}

export function generateEventMockData(): ITicketEventMessage | ITicketEventUpdate {
    const customType = faker.helpers.randomize([InputType.Boolean, InputType.Date, InputType.Decimal, InputType.MultiSelect, InputType.Number, InputType.Select, InputType.Text, InputType.Textarea])

    return faker.helpers.randomize([
        generateEventMessageMock({ from: { ...generateMockContact(), id: faker.helpers.randomize(["0", "1"]) } }),
        {
            id: faker.random.uuid(),
            createdAt: faker.date.past().toISOString(),
            type: 'update',
            from: generateMockContact(), // AGENTE OU CONTATO
            fromEntity: faker.helpers.randomize(['agent', 'system']),
            set: _.omitBy({
                status: faker.helpers.randomize([undefined, StatusEnum.NEW, StatusEnum.OPEN]),
                priority: faker.helpers.randomize([undefined, PriorityEnum.URGENT, PriorityEnum.LOW]),
                type: faker.helpers.randomize([undefined, TypeEnum.INCIDENT, TypeEnum.QUESTION]),
                assignee: faker.helpers.randomize([undefined, generateAgentMockData("0")]),
                group: faker.helpers.randomize([undefined, generateGroupMockData("0")]),
                [faker.random.word()]: faker.helpers.randomize([undefined, {
                    type: customType,
                    value: faker.random.words()
                }]),
            }, _.isNil),
            add: _.omitBy({
                tag: faker.helpers.randomize([undefined, generateTagMockData()]),
                follower: faker.helpers.randomize([undefined, generateAgentMockData("0")]),
                [faker.random.word()]: faker.helpers.randomize([undefined, {
                    type: customType,
                    value: faker.random.words()
                }]),
            }, _.isNil),
            remove: _.omitBy({
                tag: faker.helpers.randomize([undefined, generateTagMockData()]),
                follower: faker.helpers.randomize([undefined, generateAgentMockData("0")]),
                [faker.random.word()]: faker.helpers.randomize([undefined, {
                    type: customType,
                    value: faker.random.words()
                }]),
            }, _.isNil),
        },
    ])
}