import React from "react"
import { Link } from "react-router-dom"
import { useBreadcrumb, useStore, useActions } from "hooks"
import { UserOutlined } from "@ant-design/icons"
import { Row, Form, Input, Button } from "antd"
import AuthPage from "./AuthPage"
import Paragraph from "antd/lib/typography/Paragraph"

function Forgot() {
    useBreadcrumb({ items: [{ title: "Senha alterada." }] })

    return (
        <AuthPage title="Senha Alterada!">
            <Paragraph>
                Sua senha foi alterada. Você já pode usá-la para entrar no sistema clicando no link abaixo.
            </Paragraph>

            <Row justify="center">
                <Button type="link">
                    <Link to="/user/login">Voltar a página de entrada</Link>
                </Button>
            </Row>
        </AuthPage>
    )
}

export default Forgot
