import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IMacro {
    id: string
    updatedAt: string
    createdAt: string
    name: string
    description: string
    restriction: {
        type: "user" | "group" | "public"
        ids?: string[]
    }
    actions: {
        type: string
        value: any
    }[]
}

export const macros: IGenericModel<IMacro> = {
    ...generic(endpoints.bank.MACROS)
}