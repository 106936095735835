import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { NotificationType } from "products/bank/pages/Servidores/Notificacoes/EditNotificacao"
import { IUser } from "./users"

export interface INotification {
    id: string
    criado_em: string
    remetente: IUser
    // destinatario_tipo: 'cpfs' | 'consignante'
    // consignataria_id?: string
    cpfs?: string[]
    cnpj?: string
    title: string
    // novo?: boolean
    text?: string
    // tipo?: 'mensagem'
    type: NotificationType
    isAll?: boolean | string
}

export const notifications: IGenericModel<INotification> = {
    ...generic(endpoints.NOTIFICATIONS),
}
