import { endpoints } from "config/endpoints.config"
import { action, Action } from "easy-peasy"
import { IGenericModel, generic } from "models/generic"
import shortid from "shortid"
import { IConditions } from "./views"

export type IBotActionType = "message" | "input" | "menu" | "field" | "tag" | "goto" | "resolve" | "macro" | "assign" | "move"

export interface IBotPath {
    name: string
    guid: string
    actions: IBotAction[]
}

export interface IBotAction {
    guid: string
    type: IBotActionType
    name?: string
    text?: string
    inputInvalidText?: string
    // inputPropName?: string
    // inputPropCode?: string
    inputType?: "name" | "email" | "cellphone" | "cpf" | "address" | "city" | "state" | "cep" | "cnpj" | "company" | "custom" | string
    tagIds?: string[]
    groupId?: string
    assigneeId?: string
    goToPath?: string
    options?: {
        // name: string,
        key: string
        goToPath: string
    }[]
    fieldPlaceholder: string
    fieldValue: any
    macroId: string
}

export interface IBot {
    id: string
    updatedAt: string
    createdAt: string
    name: string
    description?: string
    active: boolean
    expire?: {
        hours?: string
        minutes?: string
        message?: string
        expirable: boolean
        autoResolve: boolean
    }
    // conditions: IConditions
    filters: {
        contactType?: "new" | "registered" | "all"
        channels?: string[]
        businessHours?: string[]
    }
    paths: IBotPath[]
}

export interface IBotModel extends IGenericModel<IBot> {
    importedBot?: Partial<IBot> | null
    setImportedBot: Action<IBotModel, Partial<IBot> | null | undefined>
}

export const bots: IBotModel = {
    ...generic(endpoints.bank.BOTS),
    importedBot: undefined,
    setImportedBot: action((state, payload) => {
        state.importedBot = payload
    }),
}