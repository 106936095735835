import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useBreadcrumb, useStore, useActions } from "hooks"
import { UserOutlined } from "@ant-design/icons"
import { Row, Form, Input, Button, Spin } from "antd"
import AuthPage from "./AuthPage"
import Paragraph from "antd/lib/typography/Paragraph"
import { request } from "lib/request"
import { endpoints } from "config/endpoints.config"
import toast from "lib/toast"

function ForgotOperatorSent() {
    useBreadcrumb({ items: [{ title: "Esqueci a senha" }] })
    const [loading, setLoading] = useState(false)
    const params = useParams<any>()

    function onSubmit() {
        setLoading(true)
        return request
            .post(endpoints.FORGOT_PASSWORD, {
                codOperator: params.codOperator,
            })
            .then((response) => {
                toast.success("E-mail enviado!")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        onSubmit()
    }, [])

    return (
        <AuthPage title="E-mail enviado!">
            <Spin spinning={loading}>
                <Paragraph style={{ fontSize: 16 }}>Siga as instruções no seu e-mail para resetar sua senha.</Paragraph>

                <Row justify="center">
                    <Button type="link">
                        <Link to="/user/login" className="blue-link">
                            Voltar a página de entrada
                        </Link>
                    </Button>
                </Row>
            </Spin>
        </AuthPage>
    )
}

export default ForgotOperatorSent
