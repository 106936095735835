import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IUser } from "./users"

export interface IDownload {
    id: string
    criado_em: string
    user: IUser
    name: string
    url?: string
}

export const downloads: IGenericModel<IDownload> = {
    ...generic(endpoints.DOWNLOADS)
}