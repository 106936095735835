import React from "react"
import queryString from "query-string"
import _ from "lodash"
import { MONTHS } from "./dates"
import { IPerson } from "models/bank/contacts"
import colorConvert from "color-convert"
import { format, formatDistanceToNowStrict } from "date-fns"
import { pt } from "date-fns/locale"
import { Modal } from "antd"
import { IPerfilPermission } from "models/perfis"
import moment from "moment"
import banksJson from "lib/json/banks.json"
const { parse } = require("json2csv")

// export function verifyPermission(
//     permissionKey: keyof IPerfilPermission,
// ) {
//     if (
//         (stores?.session?.workspace?.isOwner && !restrictive) ||
//         !permissionKey ||
//         stores?.session?.workspace?.role?.permissions?.[permissionKey]
//     ) {
//         return true
//     }
//     return false
// }

export function getAsset(fileName?: string): string {
    if (!fileName) return ""
    if (fileName.includes("http")) {
        return fileName
    }
    if (fileName.includes("/static/media/") || fileName.includes("data:image/png;base64")) {
        return fileName
    }
    // return stores.general._domain.url + config.endpoints.DOWNLOAD_ASSET + "/" + fileName
    return "https://sigmarh.s3.sa-east-1.amazonaws.com/" + fileName
}

const isNilOrBlank = (v: any) => v != null && v !== ""

export function timeout(delay: any) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

export const buildUrlQuery = (endpoint: any, props: any) => {
    return `${props.match.url}/${endpoint}${props.location.search}`
}

export function getHoursAndMinutes(minutes: number) {
    const hour = Math.floor(minutes / 60)
    const min = Math.round(minutes % 60)
    return (hour ? hour + "h " : "") + (min + "m")
}

export function getDateFormatted(value: any) {
    const date = new Date(value)
    return `${date.getDate()} de ${MONTHS[date.getMonth()]} de ${date.getFullYear()}`
}

export function getDistanceToNowLetter(dateString: string) {
    const distanceArray = formatDistanceToNowStrict(new Date(dateString)).split(" ")
    const suffix = distanceArray[1].includes("month") ? (distanceArray[0] === "1" ? " mês" : " meses") : distanceArray[1][0]
    return distanceArray[0] + suffix
}

/*
 *  Eg: verifyQuery: { query: { my: true }}
 *  Verifica se todas os parametros de query da url correspondem a condição de ativação
 */
export function isQueryMatched(location: any, query: any) {
    const queryParsed = queryString.parse(location.search)
    const verifyQueryKeys = Object.keys(query)
    return verifyQueryKeys.every((k) => query[k] === queryParsed[k] || "")
}

// Adiciona parametros de query na URL
export function spreadQueryParams({ location, history, newParams }: any) {
    const queryParsed = queryString.parse(location.search)
    return queryString.stringify(_.pickBy({ ...queryParsed, ...newParams }, isNilOrBlank))
}

// Adiciona parametros de query na URL
export function addQueryParams({ history, ...props }: any) {
    // console.log(props, { search: spreadQueryParams(props) })
    history.replace({ search: spreadQueryParams(props) })
}

// export function getImage(fileName: any) {
//     if (!fileName) return undefined
//     if (fileName.includes("http")) {
//         return fileName
//     }
//     return stores.general._domain.url + config.api.DOWNLOAD_ASSET + "/" + fileName
// }

export function getHost() {
    const partsLenght = process.env.REACT_APP_CUSTOM_NODE_ENV === "production" ? 3 : 2
    let parts = window.location.host.split(".")
    let subdomain = ""
    if (parts.length >= partsLenght) {
        subdomain = parts[0]
    }
    return { subdomain }
}

export function getCompanyUrl(companyName: string) {
    return `${window.location.protocol}//${companyName}.${window.location.host.replace("www.", "")}`
}

export function removeLastPath(path: string) {
    return path.substring(0, path.lastIndexOf("/"))
}

export function generateRandomFakeList(min: number, max: number, callback: (a: string) => any) {
    const length = _.random(min, max)
    return Array(length)
        .fill("")
        .map((elem, index) => callback(index + ""))
}

export function alphabeticalSort(a: string, b: string) {
    return a.localeCompare(b)
}

export function dateSort(a: string, b: string) {
    return +new Date(b) - +new Date(a)
}

export function leaf(obj: any, pathParam: string) {
    const path = pathParam.split(".")
    var res = obj
    for (var i = 0; i < path.length; i++) res = res?.[path?.[i]]
    return res
}

export function getFullName(agent: IPerson | undefined) {
    if (!agent) return ""
    return agent?.name + " " + agent?.lastName
}

export function uploadImageCallBack(file: any) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open("POST", "https://api.imgur.com/3/image")
        xhr.setRequestHeader("Authorization", "Client-ID fea5af9e6e589b7")
        const data = new FormData()
        data.append("image", file)
        xhr.send(data)
        xhr.addEventListener("load", () => {
            const response = JSON.parse(xhr.responseText)
            resolve(response)
        })
        xhr.addEventListener("error", () => {
            const error = JSON.parse(xhr.responseText)
            reject(error)
        })
    })
}

export function normFile(e: any) {
    // console.log('Upload event:', e)
    if (Array.isArray(e)) {
        return e
    }
    return e && e.fileList
}

export function capitalizeString(text: string) {
    return text[0].toUpperCase() + text.slice(1)
}

export function truncate(str: string, n: number) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str
}

export function getLabelFromEnum(value: any, enumLabel: any) {
    if (!value) return ""
    return enumLabel[value]
}

export const getKeyValue =
    <T extends object, U extends keyof T>(obj: T) =>
    (key: U) =>
        obj[key]

export function keys<O extends object>(obj: O | undefined): Array<keyof O> {
    return !obj ? [] : (Object.keys(obj) as Array<keyof O>)
}

export function getBrandColors(color: string) {
    let brandColorIcon = "#fff"
    let isDarkBrand = true
    const brandColorSelectedHsl = colorConvert.hex.hsl(color)
    if (brandColorSelectedHsl[2] <= 50) {
        brandColorSelectedHsl[2] += 20
    } else {
        brandColorSelectedHsl[2] -= 10
        brandColorIcon = "#333"
        isDarkBrand = false
    }
    return {
        brandColor: color,
        brandColorSelected: "#" + colorConvert.hsl.hex(brandColorSelectedHsl),
        brandColorIcon,
        isDarkBrand,
    }
}

export function onCellClick(onClick: Function) {
    return {
        onClick: (event: any) => {
            onClick()
        },
    }
}

export function getSettingsColumnProps(path: string, history: any) {
    return {
        onCell: (record: any) => onCellClick(() => history.push(`${path}/${record.id}`)),
        className: "pointer",
    }
}

export function formatCompleteDate(date: string) {
    return format(new Date(date), "dd 'de' MMM 'de' yyyy 'às' HH:mm", {
        locale: pt,
    })
}
export function formatDateAbbr(date: string) {
    return capitalizeString(
        format(new Date(date), "MMM dd, yyyy", {
            locale: pt,
        })
    )
}
export function formatDateVeryAbbr(date: string) {
    return capitalizeString(
        format(new Date(date), "MMM dd", {
            locale: pt,
        })
    )
}
export function formatDateUrl(date: string) {
    return format(new Date(date), "dd-MM-yyy")
}

export function formatDateUrlSlash(date: string) {
    if (!date) return ""
    return format(new Date(date), "dd/MM/yyy")
}

export const exportArray = (arr: any[], fileName: string, config?: { autoSuffix: boolean }) => {
    try {
        const csv = parse(arr)
        var element = document.createElement("a")
        element.setAttribute(
            "href",
            // "data:csv;charset=utf-8," + encodeURIComponent(`sep=,\n${csv}`)
            "data:csv;charset=utf-8," + encodeURIComponent(csv)
        )
        element.setAttribute("download", fileName + (config?.autoSuffix ? new Date().getTime() + ".csv" : ""))

        element.style.display = "none"
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
        console.log(csv)
    } catch (err) {
        console.error(err)
    }
}

export function showDeleteModal(props: {
    description: string | React.ReactNode
    onConfirm: (...args: any[]) => any
    okText?: string
    title?: string
    onCancel?: (...args: any[]) => any
}) {
    Modal.confirm({
        title: props.title || "Excluir",
        icon: (
            <i
                className={"far fa-trash"}
                style={{
                    color: "#e74c3c",
                    float: "left",
                    marginRight: 16,
                    fontSize: 20,
                }}
            />
        ),
        content: <div style={{ marginLeft: 38 }}>{props.description}</div>,
        okText: props.okText || "Excluir",
        cancelText: "Cancelar",
        onOk: props.onConfirm,
        // cancelButtonProps: {
        //     className: "normal",
        // },
        okButtonProps: {
            danger: true,
        },
        onCancel: props.onCancel,
    })
}
export function showConfirmModal(props: { description: string | React.ReactNode; onConfirm: (...args: any[]) => any; okText?: string; title?: string }) {
    Modal.confirm({
        title: props.title || "Confirmar",
        icon: (
            <i
                className={"far fa-info-circle"}
                style={{
                    color: "var(--primary)",
                    float: "left",
                    marginRight: 16,
                    fontSize: 20,
                }}
            />
        ),
        content: <div style={{ marginLeft: 38 }}>{props.description}</div>,
        okText: props.okText || "Confirmar",
        cancelText: "Cancelar",
        onOk: props.onConfirm,
        cancelButtonProps: {
            className: "normal",
        },
    })
}

export function scrollToEdit() {
    document.getElementById("edit_page_id")?.scrollIntoView({
        behavior: "smooth",
    })
}

export function openAsPageInNewTab(pageContent: string) {
    let encoded = encodeURIComponent(pageContent)
    let a = document.createElement(`a`)
    a.target = `_blank`
    a.href = `data:text/html;charset=utf-8,${encoded}`
    a.style.display = `none`
    document.body.appendChild(a) // We need to do this,
    a.click() // so that we can do this,
    document.body.removeChild(a) // after which we do this.
}

export function getDayMonthFormatted(value: any) {
    if (!value) return ""
    return moment(value).format("DD [de] MMMM")
}

export const getBankByCode = (code: string = "") => {
    return banksJson?.find((bank) => bank.value === code)
}

export default {
    timeout,
    // getImage,
    buildUrlQuery,
    isQueryMatched,
    addQueryParams,
    spreadQueryParams,
}
