import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IFile {
    id: string
    criado_em: string
    // url: string
    name: string
    // type: "image" | "file" | "audio" | "video" | "zip" | "pdf" | "spreadsheet" | "csv" | "word" | "powerpoint" | "music" | "code"
    type: string
    thumbUrl?: string
    // contact?: IContact
    size: number
    consignataria_id?: string
    deletado?: boolean
    fileName: string
    tags: string
    location?: string
    key?: string
    identifier?: string
}

export const files: IGenericModel<IFile> = {
    ...generic(endpoints.FILES)
}