import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateRandomFakeList } from "lib/helper"

const hours = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23"
]

const minutes = [
    '00',
    '15',
    '30',
    '45',
]



function getHourMinutesMock() {
    return `${faker.helpers.randomize(hours)}:${faker.helpers.randomize(minutes)}`
}

function getDayMock() {
    return {
        active: faker.random.boolean(),
        hours: generateRandomFakeList(0, 5, () => [getHourMinutesMock(), getHourMinutesMock()])
    }
}

export default generateGenericMock(endpoints.bank.BUSINESS_HOURS, generateMockData)

function generateMockData() {
    return {
        id: faker.random.uuid(),
        name: faker.random.words(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        sun: getDayMock(),
        mon: getDayMock(),
        tue: getDayMock(),
        wed: getDayMock(),
        thu: getDayMock(),
        fri: getDayMock(),
        sat: getDayMock(),
    }
}