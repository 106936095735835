import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateRandomFakeList } from "lib/helper"

export default generateGenericMock(endpoints.bank.PREFERENCES, generateMockData)

export function generateMockData() {
    return {
        brandColor: "#0764cb",
        brandColorSelected: "#0764cb",
        brandColorIcon: "#187ded",
        isDarkBrand: true,
    }
}