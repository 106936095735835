import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateMockContact } from "./contacts.mock"
import { IFile } from "models/files"

export default generateGenericMock(endpoints.FILES, generateFileMockData)

export function generateFileMockData(index?: string): IFile {
    return {
        id: index || faker.random.uuid(),
        criado_em: faker.date.past().toISOString(),
        type: faker.helpers.randomize(["image", "video", "file", "audio", "pdf", "spreadsheet", "csv", "word", "powerpoint", "music", "zip", "code"]),
        // type: faker.helpers.randomize(["video"]),
        location: faker.helpers.randomize([
            "https://pbs.twimg.com/media/EiEurnYXkAApe6e?format=jpg&name=small",
            "https://pbs.twimg.com/media/Ee9ktDwUcAA6t49?format=jpg&name=360x360",
            'https://pbs.twimg.com/media/Ee9krFNU4AEfmUu?format=jpg&name=large',
            'https://pbs.twimg.com/media/EaPiM-bVAAE3cS9?format=jpg&name=small',
            'https://pbs.twimg.com/media/EVr_l_SU0AE0Grq?format=jpg&name=small',
            'https://pbs.twimg.com/media/DwYcLdRXgAAFbyf?format=jpg&name=small',
        ]),
        name: faker.random.word(),
        // contact: generateMockContact(),
        size: faker.random.number(),
        tags: faker.random.words(3),
        fileName: faker.random.word(),
    }
}