import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { InputType } from "lib/constants"

const optionsMock = [
    {
        value: "CPF",
        label: "CPF"
    },
    {
        value: "RG",
        label: "RG"
    },
]

const definedCustomFields = [
    {
        type: InputType.Boolean,
        name: "Example Boolean",
    },
    {
        type: InputType.Date,
        name: "Example Date",
    },
    {
        type: InputType.Decimal,
        name: "Example Decimal",
    },
    {
        type: InputType.MultiSelect,
        name: "Example Multi-Select",
    },
    {
        type: InputType.Number,
        name: "Example Number",
    },
    {
        type: InputType.Select,
        name: "Example Select",
    },
    {
        type: InputType.Text,
        name: "Example Text",
    },
    {
        type: InputType.Textarea,
        name: "Example Textarea",
    },
]

export default generateGenericMock(endpoints.bank.CUSTOM_FIELDS, generateMockData, { max: 0, min: 0 })

export function generateMockData() {
    const definedTypeName = faker.helpers.randomize(definedCustomFields)
    const entity = faker.helpers.randomize(["contact", "ticket", "organization"])
    // const entity = "ticket"
    const name = definedTypeName.name + faker.random.number()
    const type = definedTypeName.type

    return {
        id: faker.random.uuid(),
        name,
        description: faker.random.words(20),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        type,
        entity,
        options: (type === "select" || type === "multi-select") ? optionsMock : undefined,
        placeholder: `${entity}:${name.toLocaleLowerCase().split(' ').join('_')}${faker.random.number()}`
    }
}