import { useMemo } from "react"
import { getHost } from "lib/helper"

export function useHost() {
    const { subdomain } = useMemo(() => getHost(), [])
    const isInAppSubdomain = useMemo(() => subdomain && subdomain !== "www", [
        subdomain,
    ])
    return { subdomain, isInAppSubdomain }
}
