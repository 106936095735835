import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateMockContact } from "mock/bank_mock/contacts.mock"

export default generateGenericMock(endpoints.admin.ORGANIZATION, () => ({
    name: faker.company.companyName(),
    subdomain: faker.internet.domainName(),
    logo: "https://static.dribbble.com/users/1303230/screenshots/10786710/1anime_avatar_4x.jpg"
}))
export const verify_subdomain_mock = generateGenericMock(endpoints.VERIFY_SUBDOMAIN, () => ({}))