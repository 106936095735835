import { generateRandomFakeList } from "lib/helper"
import _ from "lodash"

export function generateGenericMock(endpoint: string, generateMockData: Function, { getBody, min, max, isObjectReturn }: any = { min: 1 }) {
    function generateCommonError() {
        return {
            statusCode: 404,
            error: {
                errors:
                    [
                        {
                            key: null,
                            type: "ValidationError",
                            descriptions: [
                                "Algum Erro"
                            ]
                        }
                    ]
            }
        }
    }

    function generateMockMethod() {
        return {
            success: {
                statusCode: 200,
                body: (model?: any) => generateMockData("0", model),
            },
            error: generateCommonError()
        }
    }

    return [
        {
            endpoint: endpoint + '/:id',
            latency: 500,
            method: {
                get: generateMockMethod(),
                patch: generateMockMethod(),
                delete: {
                    success: {
                        statusCode: 204,
                        body: null,
                    },
                    error: generateCommonError()
                },
                post: {
                    success: {
                        statusCode: 200,
                        body: getBody || ((payload: any) => {
                            return generateMockData()
                        })
                    },
                }
            },
        },
        {
            endpoint: endpoint,
            latency: 500,
            method: {
                get: {
                    success: {
                        statusCode: 200,
                        body: getBody || ((payload: any) => {
                            if (isObjectReturn) {
                                return generateMockData()
                            }
                            const data = generateRandomFakeList(min ?? payload?.limit ?? 1, max ?? payload?.limit ?? 40, (i: string) => generateMockData(i))
                            return {
                                data,
                                total: _.random(data.length, data.length * 3)
                            }
                        })
                    },
                    error: generateCommonError()
                },
                post: generateMockMethod(),
                patch: {
                    success: {
                        statusCode: 200,
                        body: (model?: any) => [generateMockData("0", model)],
                    },
                    error: generateCommonError()
                },
                delete: {
                    success: {
                        statusCode: 204,
                        body: null,
                    },
                    error: generateCommonError()
                },
            },
        }
    ]
}