import { createTypedHooks } from 'easy-peasy';
import { IStoreModel } from 'models';

export * from "./useHost"
export * from "./useGenericStore"
export * from "./useFetchOneFromParamId"
export * from "./useBreadcrumb"
export * from "./useFetchDependency"

// Store
const { useStoreActions, useStoreState, useStoreDispatch, ...rest } = createTypedHooks<IStoreModel>();

const useStore = useStoreState
const useActions = useStoreActions
const useStoreInstance = rest.useStore

export {
    useStoreInstance,
    useActions,
    useStore
}