import { useEffect, useState } from "react"
import { useStore, useActions } from "hooks"
import { useParams, useLocation } from "react-router-dom"
import { Actions } from "easy-peasy"
import queryString from "query-string"
import { IStoreModel } from "models"

type IUseFetchOneFromParamIdConfigs = {
    cloneIdKey: string
}

export type IStorePath = (actions: Actions<IStoreModel>) => any

export type IUseFetchOneFromParamId = <Result>(
    mapActions: (actions: Actions<IStoreModel>) => Result,
    config?: {
        existingItem?: any,
        idName?: string
    }
) => any

const useFetchOneFromParamId: IUseFetchOneFromParamId = (storePath, config) => {
    const idString = config?.idName || "id"
    const { all }: any = useStore(storePath as any)
    const [item, setItem] = useState()
    let params = useParams<any>()
    const id = params[idString]
    const location = useLocation()
    const { fetchOne, setModel }: any = useActions(storePath)

    const changeItem = (value: any) => {
        setItem(value)
        setModel(value)
    }

    const setItemClone = (data: any) => {
        const query = queryString.parse(location.search)
        if (query.cloneFromId) {
            const { id, ...rest } = data
            changeItem({ ...rest, nome: "Copia de " + rest.nome })
        } else {
            changeItem(data)
        }
    }

    
    useEffect(() => {
        const itemFromAll = all.find((i: any) => i.id === id)
        if (JSON.stringify(item) !== JSON.stringify(itemFromAll)) {
            changeItem(itemFromAll)
        }
    }, [all])

    useEffect(() => {
        const query = queryString.parse(location.search)
        if (config?.existingItem) {
            changeItem(config?.existingItem)
        } else {
            if (id !== "new" || query.cloneFromId) {
                const newId = id !== "new" ? id : query.cloneFromId
                const itemInAll = all.find((x: any) => x.id === id)
                if (itemInAll) {
                    setItemClone(itemInAll)
                } else {
                    fetchOne({ id: newId }).then((data: any) => {
                        setItemClone(data)
                    })
                }
            } else {
                changeItem({})
            }
        }
    }, [id])


    return item
}

export { useFetchOneFromParamId }
