import Login from "products/auth/Login"
import Forgot from "products/auth/Forgot"
import ForgotSent from "products/auth/ForgotEmailSent"
import Reset from "products/auth/ResetPassword"
import ResetPasswordSuccess from "products/auth/ResetPasswordSuccess"
import JoinRegister from "products/auth/JoinRegister"

import Register from "products/auth/register/Register"
import RegisterCNPJ from "products/auth/register/RegisterCNPJ"
import RegisterContinuation from "products/auth/register/RegisterContinuation"
import ForgotOperatorSent from "products/auth/ForgotOperatorSent"
import RegisterCNPJContinuation from "products/auth/register/RegisterCNPJContinuation"

/* Routes
================================================= */

const routes = [
    { path: "/user/login", component: Login },
    { path: "/user/forgot/sent", component: ForgotSent },
    { path: "/user/forgot", component: Forgot },
    { path: "/user/forgot-operator/:codOperator", component: ForgotOperatorSent },
    { path: "/user/reset/success", component: ResetPasswordSuccess },
    { path: "/user/reset", component: Reset },

    // Register
    // { path: "/user/register/creating", exact: true, component: Registering },
    { path: "/user/register", exact: true, component: Register },
    { path: "/user/register-cnpj", exact: true, component: RegisterCNPJ },
    { path: "/user/register-cnpj-continuation", exact: true, component: RegisterCNPJContinuation },
    { path: "/user/register-continuation", exact: true, component: RegisterContinuation },

    // Join
    { path: "/user/join-workspace", component: JoinRegister },
    // { path: "/user/join/register/success", component: JoinRegisterSuccess },
    // { path: "/user/join/register", component: JoinRegister }
]

export default routes
