import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateMockContact } from "./contacts.mock"
import { Ticket, InputType, Channel } from "lib/constants"
import { generateMockOrganization } from "./organizations.mock"
import { ITicket, ITicketEventMessage, ITicketEventUpdate } from "models/bank/tickets"
import { ITimelineEvent } from "models/bank/contacts"
import { generateChannelsMockData } from "./channels.mock"
import { StatusEnum, PriorityEnum, TypeEnum } from "lib/constants/Ticket"
import { generateAgentMockData } from "./agents.mock"
import { generateGroupMockData } from "./groups.mock"
import { IInputType } from "components/form/ActionsInput"
import _ from "lodash"
import { generateTagMockData } from "./tags.mock"

export default generateGenericMock(endpoints.bank.TICKETS, generateTicketMockData)
export const tickets_export_mock = generateGenericMock(endpoints.bank.TICKETS_EXPORT, () => ({}))
export const tickets_events_mock = generateGenericMock(endpoints.bank.TICKETS + '/:id/events', generateEventMockData)
export const tickets_send_message_mock = generateGenericMock(endpoints.bank.TICKETS + '/:id/message', generateEventMessageMock)
export const tickets_execute_macro_mock = generateGenericMock(endpoints.bank.TICKETS + '/:id/execute-macro/:id', generateTicketMockData)

function generateEventMessageMock(partial?: Partial<ITicketEventMessage>): ITicketEventMessage {
    return {
        id: faker.random.uuid(),
        createdAt: faker.helpers.randomize(["2020-01-02T00:08:10.393Z", "2020-01-02T00:08:11.393Z", "2020-01-02T00:08:12.393Z", "2020-01-04T00:08:10.393Z"]),
        attachments: [],
        body: `<p>${faker.random.words(faker.random.number({max: 10, min: 1}))}</p>`,
        type: faker.helpers.randomize(['reply', 'note']),
        from: generateMockContact(), // AGENTE OU CONTATO
        fromEntity: faker.helpers.randomize(['contact', 'agent']),
        toEntity: 'channel',
        channel: generateChannelsMockData('0'),
        ...partial
    }
}

export function generateEventMockData(): ITicketEventMessage | ITicketEventUpdate {
    const customType = faker.helpers.randomize([InputType.Boolean, InputType.Date, InputType.Decimal, InputType.MultiSelect, InputType.Number, InputType.Select, InputType.Text, InputType.Textarea])

    return faker.helpers.randomize([
        generateEventMessageMock({ from: { ...generateMockContact(), id: faker.helpers.randomize(["0", "1"]) }, fromEntity: 'agent' }),
        // {
        //     id faker.random.uuid(),
        //     createdAt: faker.date.past().toISOString(),
        //     type: 'update',
        //     from: generateMockContact(), // AGENTE OU CONTATO
        //     fromEntity: faker.helpers.randomize(['agent', 'system']),
        //     set: _.omitBy({
        //         status: faker.helpers.randomize([undefined, StatusEnum.NEW, StatusEnum.OPEN]),
        //         priority: faker.helpers.randomize([undefined, PriorityEnum.URGENT, PriorityEnum.LOW]),
        //         type: faker.helpers.randomize([undefined, TypeEnum.INCIDENT, TypeEnum.QUESTION]),
        //         assignee: faker.helpers.randomize([undefined, generateAgentMockData("0")]),
        //         group: faker.helpers.randomize([undefined, generateGroupMockData()]),
        //         [faker.random.word()]: faker.helpers.randomize([undefined, {
        //             type: customType,
        //             value: faker.random.words()
        //         }]),
        //     }, _.isNil),
        //     add: _.omitBy({
        //         tag: faker.helpers.randomize([undefined, generateTagMockData()]),
        //         follower: faker.helpers.randomize([undefined, generateAgentMockData("0")]),
        //         [faker.random.word()]: faker.helpers.randomize([undefined, {
        //             type: customType,
        //             value: faker.random.words()
        //         }]),
        //     }, _.isNil),
        //     remove: _.omitBy({
        //         tag: faker.helpers.randomize([undefined, generateTagMockData()]),
        //         follower: faker.helpers.randomize([undefined, generateAgentMockData("0")]),
        //         [faker.random.word()]: faker.helpers.randomize([undefined, {
        //             type: customType,
        //             value: faker.random.words()
        //         }]),
        //     }, _.isNil),
        // },
    ])
}

export function generateTicketMockData(index: string): ITicket {
    const customType = faker.helpers.randomize([InputType.Boolean, InputType.Date, InputType.Decimal, InputType.MultiSelect, InputType.Number, InputType.Select, InputType.Text, InputType.Textarea])

    return {
        id: index || "0",
        number: faker.random.number(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        tagIds: faker.helpers.randomize([[], [], [], ["0"]]),
        subject: faker.random.words(),
        contact: generateMockContact(),
        organization: generateMockOrganization(),
        status: faker.helpers.randomize([Ticket.StatusEnum.NEW, Ticket.StatusEnum.OPEN, Ticket.StatusEnum.CLOSED, Ticket.StatusEnum.PENDING, Ticket.StatusEnum.SOLVED]),
        type: faker.helpers.randomize([Ticket.TypeEnum.TASK, Ticket.TypeEnum.INCIDENT, Ticket.TypeEnum.PROBLEM, Ticket.TypeEnum.QUESTION]),
        priority: faker.helpers.randomize([Ticket.PriorityEnum.HIGH, Ticket.PriorityEnum.LOW, Ticket.PriorityEnum.NORMAL, Ticket.PriorityEnum.URGENT]),
        description: faker.random.words(),
        lastMessageEvent: {
            id: faker.random.uuid(),
            authorId: faker.random.uuid(),
            authorType: faker.helpers.randomize(['agent', 'contact']),
            body: faker.random.words(),
            createdAt: faker.date.past().toISOString(),
        },
        assigneeId: faker.helpers.randomize([undefined, "0"]),
        groupId: faker.random.uuid(),
        channelId: "0",
        channelType: faker.helpers.randomize([Channel.Type.EMAIL, Channel.Type.FACEBOOK, Channel.Type.WHATSAPP]),
        followerIds: [faker.random.uuid()],
        dueDate: faker.date.future().toISOString(),
        mainEvent: generateEventMessageMock({ type: 'reply', fromEntity: 'contact' }),
        unreadMessagesCount: faker.helpers.randomize([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 6, 76, 120]),
        customFields: {
            [faker.random.word()]: {
                type: customType,
                value: faker.random.words()
            },
            "ticket:example_text": {
                type: InputType.Text,
                value: faker.random.words()
            },
            "ticket:example_textarea": {
                type: InputType.Textarea,
                value: faker.random.words()
            },
            "ticket:example_number": {
                type: InputType.Number,
                value: faker.random.number()
            },
            "ticket:example_decimal": {
                type: InputType.Decimal,
                value: faker.random.number()
            },
            "ticket:example_select": {
                type: InputType.Select,
                value: faker.random.words()
            },
            "ticket:example_multi-select": {
                type: InputType.MultiSelect,
                value: [faker.random.word(), faker.random.word()]
            },
            "ticket:example_boolean": {
                type: InputType.Boolean,
                value: faker.random.boolean()
            },
            "ticket:example_date": {
                type: InputType.Date,
                value: faker.date.past()
            },
        },
        invisible: false
    }
}