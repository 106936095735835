import login from "./login.mock"
import workspace_info from "./workspace_info.mock"
import forgot_passwordMock from "./forgot_password.mock"
import reset_password from "./reset_password.mock"
import join_workspace_register from "./join_workspace_register"
import register from "./register.mock"

export default [
    login,
    register,
    ...workspace_info,
    ...forgot_passwordMock,
    ...reset_password,
    ...join_workspace_register
]