import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { ISecretary } from "models/system/secretaries"
import { generateRegionMockData } from "./regions.mock"

export default generateGenericMock(endpoints.system.SECRETARIES, generateSecretaryMockData)

export const resend_email = generateGenericMock(endpoints.system.SECRETARIES + "/:id/resend-email", () => ({}))

export function generateSecretaryMockData(index: string): ISecretary {
    return {
        id: index || "0",
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        name: faker.random.words(),
        regionId: faker.helpers.randomize(["0", "1"]),
        adminEmail: faker.internet.email()
    }
}

