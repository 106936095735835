import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateRandomFakeList } from "lib/helper"
import { IBot } from "models/bank/bots"

export const actionsMock = [
    {
        type: "set_subject",
        value: "Novo assunto"
    },
    {
        type: "set_status",
        value: faker.helpers.randomize(["open", "pending", "solved"])
    },
    {
        type: "set_priority",
        value: faker.helpers.randomize(["low", "normal", "high", "urgent"])
    },
    {
        type: "set_type",
        value: faker.helpers.randomize(["question", "incident", "problem", "task"])
    },
    {
        type: "set_group",
        value: faker.helpers.randomize(["1", "2", "3", "4"])
    },
    {
        type: "set_agent",
        value: faker.helpers.randomize(["current_user", "1", "2", "3", "4"])
    },
    {
        type: "set_tag",
        value: faker.helpers.randomize(["5", "6", "9", "10", "2"])
    },
    {
        type: "unset_tag",
        value: faker.helpers.randomize(["5", "6", "9", "10", "2"])
    },
    {
        type: "add_follower",
        value: faker.helpers.randomize(["5", "6", "9", "10", "2"])
    },
    {
        type: "add_response",
        value: faker.helpers.randomize(["5", "6", "9", "10", "2"])
    },
    {
        type: "add_comment",
        value: faker.helpers.randomize(["esse ticket é muito bom", "esse usuário é muito inteligente"])
    },
]

export default generateGenericMock(endpoints.bank.BOTS, generateMockData)

export function generateMockData(index: string): IBot {
    return {
        id: index || "0",
        name: faker.random.words(),
        description: faker.random.words(20),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        active: faker.random.boolean(),
        expire: {
            hours: faker.random.number() + "",
            minutes: faker.random.number() + "",
            message: faker.random.words(),
            expirable: faker.random.boolean(),
            autoResolve: faker.random.boolean(),
        },
        // conditions: {
        //     all: generateRandomFakeList(0, 5, () =>
        //         faker.helpers.randomize([
        //             {
        //                 field: "ticket:status",
        //                 operator: faker.helpers.randomize(["is", "is_not"]),
        //                 value: faker.helpers.randomize(["new", "pending", "open", "solved", "closed"]),
        //             },
        //             {
        //                 field: "ticket:type",
        //                 operator: faker.helpers.randomize(["is", "is_not"]),
        //                 value: faker.helpers.randomize(["question", "incident", "problem", "task"]),
        //             },
        //         ]))
        // },
        filters: {
            contactType: faker.helpers.randomize(["new", "registered", "all"]),
            channels: faker.helpers.randomize([["0"], []]),
            businessHours: faker.helpers.randomize([["0"], []]),
        },
        paths: generateRandomFakeList(1, 10, (i: string) =>
            ({
                name: faker.random.words(),
                guid: i,
                actions: generateRandomFakeList(1, 10, (index: string) =>
                    ({
                        guid: index,
                        type: faker.helpers.randomize(["message", "input", "menu", "field", "tag", "goto", "resolve", "macro", "move", "assign"]),
                        name: faker.random.words(),
                        text: faker.random.words(),
                        inputInvalidText: faker.random.words(),
                        // inputPropName: faker.random.words(),
                        // inputPropCode: faker.random.words(),
                        inputType: faker.helpers.randomize(["name", "email", "cellphone", "cpf", "address", "city", "state", "cep", "cnpj", "company", "custom"]),
                        tagIds: faker.helpers.randomize([["0"], ["0", "1"]]),
                        assigneeId: "0",
                        groupId: "0",
                        goToPath: "0",
                        options: generateRandomFakeList(1, 7, (indexAction: string) => ({
                            name: faker.random.words(),
                            key: indexAction,
                            goToPath: faker.random.number({ min: 0, max: 5 }) + ""
                        })),
                        fieldPlaceholder: faker.random.word(),
                        fieldValue: faker.random.words(),
                        macroId: "0"
                    }))
            })
        ),
    }
}