import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IContrato } from "models/contratos"
import { generateServidorMatriculaMockData } from "./servidores.mock"

export default generateGenericMock(
    endpoints.CONTRATOS,
    generateContratosMockData
)
export const refinanciarContrato = generateGenericMock(
    endpoints.REFINANCIAR_CONTRATOS,
    () => {
        return { success: true }
    },
    { isObjectReturn: true }
)

export const aprovarContrato = generateGenericMock(
    endpoints.CONTRATOS + "/approve/:id?",
    () => {
        return { id: "0" }
    },
    { isObjectReturn: true }
)

export const reprovarContrato = generateGenericMock(
    endpoints.CONTRATOS + "/disapprove/:id?",
    () => {
        return { id: "0" }
    },
    { isObjectReturn: true }
)

export const contratoNotes = generateGenericMock(
    endpoints.CONTRATOS + "/:id/notes/:id?",
    () => ({
        id: "3",
        usuario_id: "0",
        usuario_nome: "Jose",
        texto: faker.random.words(),
        criado_em: faker.date.past().toISOString(),
    }),
    { isObjectReturn: true }
)

export function generateContratosMockData(
    index: string,
    model?: Partial<IContrato>
) {
    return {
        id: index || "0",
        numero: faker.random.number() + "",
        produto: {
            id: index,
            produto_id: "Empréstimo 30%",
            codigo: "123",
            tipoFinanceiro: true,
        },
        criado_em: faker.date.past().toISOString(),
        data_contrato: faker.date.past().toISOString(),
        primeiro_vencimento: faker.date.past().toISOString(),
        nr_parcelas: 12,
        nr_parcelas_pagas: 6,
        situacao: faker.helpers.randomize([
            "Reservado",
            "Cancelado",
            "Expirado",
            "Aprovado",
            "Liquidado",
            "Quitado",
            "Em processo de refinanciamento",
            "Em processo de portabilidade",
            "Liquidado por refinanciamento",
            "Liquidado por portabilidade",
        ]),
        valor_financiado: faker.random.number(),
        valor_parcela: faker.random.number(),
        valor_liberado: faker.random.number(),
        servidor_matricula: generateServidorMatriculaMockData("0"),
        consignataria_id: "0",
        comprovante_url: "https://i.imgur.com/CP5J8vw.png",
        parcelas: [
            {
                numero: parseInt(index, 10) + 1,
                valor: faker.random.number(),
                valor_descontado: faker.random.number(),
                data_vencimento: faker.date.past().toISOString(),
                data_pagamento: faker.date.past().toISOString(),
                status: faker.helpers.randomize(["Descontado", "Em aberto"]),
            },
            {
                numero: parseInt(index, 10) + 2,
                valor: faker.random.number(),
                valor_descontado: faker.random.number(),
                data_vencimento: faker.date.past().toISOString(),
                data_pagamento: faker.date.past().toISOString(),
                status: faker.helpers.randomize(["Descontado", "Em aberto"]),
            },
        ],
        anotacoes: [
            {
                id: index,
                usuario_id: "0",
                usuario_nome: "Jose",
                texto: faker.random.words(),
                criado_em: faker.date.past().toISOString(),
            },
            {
                id: index + 1,
                usuario_id: "1",
                usuario_nome: "Maria",
                texto: faker.random.words(),
                criado_em: faker.date.past().toISOString(),
            },
        ],
    }
}
