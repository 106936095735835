import React, { useState, useEffect, useMemo } from "react"
import { useParams, RouteComponentProps, Prompt } from "react-router-dom"
import { IStorePath, useFetchOneFromParamId } from "hooks/useFetchOneFromParamId"
import { useActions, useStore } from "hooks"
import { Form, message, Drawer, Button, Spin, Modal, PageHeader, Card } from "antd"
import { removeLastPath, scrollToEdit } from "lib/helper"
import { FormInstance, FormProps } from "antd/lib/form"
import Icon from "../Icon"
import cx from "classnames"
// import Modal, { ModalTransition } from "@atlaskit/modal-dialog"

import styles from "./style.module.css"
import ActionsFooter from "./ActionsFooter"
import toast from "lib/toast"

type ISubmitBeforeConfig = {
    setSaving?: Function
}

export interface ISettingsPageProps<T> extends RouteComponentProps<{ id: string }> {
    children: React.ReactNode
    storePath: IStorePath
    onSubmitSuccess?: (response: T) => void
    onSubmitBefore?: (model: T, onSubmit: Function, config?: ISubmitBeforeConfig) => void
    routeName: string
    title?: string
    titleOverwrite?: string | boolean | React.ReactNode
    okText?: string | boolean
    successMessage?: string | boolean
    contentClassname?: string
    defaultValues?: Partial<T>
    form: FormInstance
    formProps?: FormProps
    pageComponent: "drawer" | "modal" | "page"
    width?: number
    onBack?: Function
    hideFooterWhenSaved?: boolean
    item?: T
    // setItem?: any
    onReset?: Function
    saved?: boolean
    saveWithPut?: boolean
    setSaved?: Function
    modelHOC?: (model: Partial<T>) => Partial<T>
    parseInitialItem?: (model: Partial<T>) => Partial<T>
    extraFooter?: React.ReactNode
    footer?: React.ReactNode
    actions?: React.ReactNode
}

function SettingsPageEdit<T extends Object>(props: ISettingsPageProps<T>) {
    const [_saved, _setSaved] = useState(true)
    const [saving, setSaving] = useState(false)
    const [visible, setVisible] = useState(true)
    const [drawerOpened, setDrawerOpened] = useState(false)
    let { id } = useParams<any>()
    let { save } = useActions(props.storePath)
    const { loading }: any = useStore(props.storePath as any)
    const item: T = useFetchOneFromParamId(props.storePath, props.item)
    const isEditing = id !== "new"
    const isFetching = (id && isEditing && !item) || loading.one

    const saved = useMemo(() => props.saved ?? _saved, [_saved, props.saved])
    const setSaved = useMemo(() => props.setSaved ?? _setSaved, [props.setSaved])

    useEffect(() => {
        if (item || id === "new") {
            props.form.resetFields()
            props.onReset?.()
            // console.log('props.form', props.form.getFieldsValue(), item)
        }
        return () => {
            props.form.resetFields()
            props.onReset?.()
        }
        // props.setItem?.(item)
    }, [item, id])

    useEffect(() => {
        if (!loading.all) {
            setTimeout(() => {
                scrollToEdit()
            }, 100)
        }
    }, [id, loading.all])

    const onClose = (forcedBack?: boolean) => {
        if (drawerOpened || props.pageComponent === "modal") {
            if (!forcedBack && !saved) {
                goBack()
            } else {
                setVisible(false)
            }
        } else {
            goBack()
        }
    }

    const goBack = () => {
        props.onBack ? props.onBack() : props.history.push(removeLastPath(props.location.pathname))
    }

    const onSubmit = async (model: any) => {
        setSaving(true)
        try {
            return save({
                ...item,
                ...(props.modelHOC ? props.modelHOC(model) : model),
                saveWithPut: props.saveWithPut,
            })
                .then((response: T) => {
                    toast.success(
                        props.successMessage ||
                            (isEditing
                                ? `${(model as any)?.nome || props.title} atualizado.`
                                : `${(model as any)?.nome || props.title} criado.`)
                    )
                    setSaving(false)
                    setSaved(true)
                    if (!props.onSubmitSuccess) {
                        onClose(true)
                    } else {
                        props.onSubmitSuccess?.(response)
                    }
                })
                .finally(() => {
                    setSaving(false)
                })
        } catch (e) {
            setSaving(false)
        }
    }

    const content = (
        <>
            {props.pageComponent !== "page" && (
                <Prompt
                    when={!saved}
                    message="Você tem mudanças não salvas, tem certeza que deseja fechar?"
                />
            )}
            <Spin spinning={isFetching}>
                <div className={props.contentClassname}>
                    <Form
                        initialValues={
                            id === "new"
                                ? props.defaultValues
                                : props.parseInitialItem
                                ? props.parseInitialItem(props.item || item || props.defaultValues)
                                : props.item || item || props.defaultValues
                        }
                        layout="vertical"
                        onFinish={(model) => {
                            props.onSubmitBefore
                                ? props.onSubmitBefore(model as T, onSubmit, { setSaving })
                                : onSubmit(model)
                        }}
                        form={props.form}
                        // hideRequiredMark
                        {...props.formProps}
                        onValuesChange={(changedValues, allValues) => {
                            setSaved(false)
                            props.formProps?.onValuesChange?.(changedValues, allValues)
                        }}
                    >
                        {props.children}
                    </Form>
                </div>
            </Spin>
        </>
    )

    // const footer = (
    //     <div
    //         style={{
    //             display: "flex",
    //             justifyContent: "flex-end",
    //             padding: props.pageComponent === "page" ? "2px 0 2px 8px" : "2px 24px",
    //         }}
    //         key="footer"
    //     >
    //         <Button
    //             onClick={() => onClose()}
    //             style={{ marginRight: 8, marginLeft: 15 }}
    //             // className="normal"
    //             type="text"
    //         >
    //             Cancelar
    //         </Button>
    //         <Button
    //             type="primary"
    //             htmlType="submit"
    //             onClick={props.form.submit}
    //             loading={saving}
    //             // style={{ width: 90 }}
    //             disabled={saved}
    //         >
    //             {props.okText || (!isEditing ? "Criar" : "Salvar")}
    //         </Button>
    //     </div>
    // )

    const footer = props.footer || (
        <ActionsFooter
            okText={props.okText || ((!isEditing ? "Criar" : "Salvar") as any)}
            okProps={{
                onClick: props.form.submit,
                loading: saving,
                disabled: saved,
            }}
            cancelProps={{
                onClick: () => onClose(),
            }}
            key="1"
        />
    )

    if (props.pageComponent === "drawer") {
        return (
            <Drawer
                title={
                    props.titleOverwrite ||
                    `${!isEditing ? "Criar" : "Editar"} ${props.title?.toLocaleLowerCase()}`
                }
                width={props.width || 620}
                onClose={() => onClose()}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                afterVisibleChange={() => {
                    if (!drawerOpened) {
                        setDrawerOpened(true)
                    } else {
                        !visible && goBack()
                    }
                }}
                maskClosable
                footer={footer}
                closeIcon={<Icon name="far fa-times" />}
                forceRender
                // className="trello-theme"
            >
                {content}
            </Drawer>
        )
    }

    if (props.pageComponent === "modal") {
        return (
            <Modal
                title={
                    props.titleOverwrite ||
                    `${!isEditing ? "Criar" : "Editar"} ${props.title?.toLocaleLowerCase()}`
                }
                afterClose={() => goBack()}
                onCancel={() => onClose()}
                visible={visible}
                maskClosable
                footer={footer}
                closeIcon={<Icon name="far fa-times" />}
            >
                {content}
            </Modal>
        )
    }

    if (id !== "new" && !item) {
        return <div></div>
    }

    return (
        <div className={cx(styles.edit_page)} id="edit_page_id">
            {/* <PageHeader
                title={
                    props.titleOverwrite ||
                    `${!isEditing ? "Criar" : "Editar"} ${props.title?.toLocaleLowerCase()}`
                }
                className="site-page-header"
                style={{ padding: "20px 0" }}
                onBack={() => goBack()}
                extra={props.hideFooterWhenSaved ? [] : [footer]}
            ></PageHeader> */}
            {/* <div className={styles.card}>{content}</div> */}
            <Card
                title={
                    props.titleOverwrite ||
                    `${!isEditing ? "Criar" : "Editar"} ${props.title?.toLocaleLowerCase()}`
                }
                extra={props.actions}
            >
                {content}
                {props.hideFooterWhenSaved ? null : <div style={{ marginTop: 20 }}>{footer}</div>}
            </Card>
            {props.extraFooter}
        </div>
    )
}

export default SettingsPageEdit
