import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateUserMockData } from "./users.mock"
import { IAviso } from "models/avisos"

export default generateGenericMock(endpoints.AVISOS, generateAvisosMockData)

export function generateAvisosMockData(index: string, model?: Partial<IAviso>): IAviso {
    return {
        id: index || "0",
        criado_em: faker.date.recent(5).toISOString(),
        Usuario: generateUserMockData("0"),
        destinatario_tipo: faker.helpers.randomize(["consignataria", "consignante"]),
        consignataria_id: "0",
        titulo: faker.random.words(),
        texto: faker.random.words(),
        // tipo: faker.helpers.randomize(["mensagem", "evento", "feriado"]),
        // data_inicial: faker.date.past().toISOString(),
        // data_final: faker.date.future().toISOString(),
        // destinatario_nome: faker.name.firstName() + " " + faker.name.lastName()
    }
}