import { useEffect, useState } from "react"
import { useActions, IStorePath } from "hooks"
import { IBreadcrumb } from "models/app"
import { ITimelineEvent, IContact } from "models/bank/contacts"
import { ITicket } from "models/bank/tickets"
import { useParams } from "react-router-dom"
import { IPaginatedResponse } from "models/generic"

// export const useTimeline = (contact: Partial<IContact>) => {
//     const [timeline, setTimeline] = useState<ITimelineEvent[]>([])
//     const { fetchDependency } = useActions((state) => state.bank.contacts)

//     // TODO: DELETAR - TEST ONLY
//     let { id } = useParams<any>()

//     useEffect(() => {
//         fetchDependency({
//             // TODO: DELETAR - TEST ONLY
//             id id, // contact.id,
//             dependencyArrayKey: "timeline",
//         }).then((result: IPaginatedResponse<ITimelineEvent>) => {
//             setTimeline(result?.data || [])
//         })
//     }, [contact?.id])

//     return timeline
// }

// export default useTimeline
export const useFetchDependency = <T extends { id: string }, D>(entity: Partial<T>, propertyName: string, storePath: IStorePath): D[] => {
    const [dependencyItem, setDependencyItem] = useState<D[]>([])
    const { fetchDependency } = useActions(storePath)

    // TODO: DELETAR - TEST ONLY
    let { id } = useParams<any>()

    useEffect(() => {
        if (entity) {
            fetchDependency({
                id: entity.id!,
                dependencyArrayKey: propertyName,
            }).then((result: IPaginatedResponse<D>) => {
                setDependencyItem(result?.data || [])
            })
        }
    }, [entity?.id])

    return dependencyItem
}

export default useFetchDependency
