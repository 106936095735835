import { endpoints } from "config/endpoints.config"
import { thunk, Thunk } from "easy-peasy"
import { request } from "lib/request"
import toast from "lib/toast"
import { generateGenericMock } from "mock/genericMock"
import { IGenericModel, generic } from "models/generic"

export interface ITaxaParcela {
    id: string
    criado_em: string
    atualizado_em: string
    numero: number
    cet: number
    juros: number
    iof: number
}

export interface ITaxa {
    id: string
    criado_em: string
    atualizado_em: string
    nome: string
    vigencia_de: string
    vigencia_ate: string
    ativo: boolean
    parcelas: ITaxaParcela[]
}

export interface ITaxaModel extends IGenericModel<ITaxa> {
    removeParcela: Thunk<ITaxaModel, { parcelaId: string, taxaId: string }>
    addParcela: Thunk<ITaxaModel, { taxaId: string, parcela: any }>
}

export const taxas: ITaxaModel = {
    ...generic(endpoints.TAXAS),
    removeParcela: thunk(async (actions, { taxaId, parcelaId }, { getState }) => {
        return request.delete(endpoints.TAXAS + `/${taxaId}/parcela/${parcelaId}`).then(() => {
            const taxa = getState().all.find(c => c.id === taxaId)
            actions.patchElementOnAll({
                id: taxaId,
                parcelas: taxa?.parcelas.filter(v => v.id !== parcelaId)
            })
            toast.info('Parcela excluída.')
        })
    }),
    addParcela: thunk(async (actions, { taxaId, parcela }, { getState }) => {
        if (parcela?.id) {
            return request.save(endpoints.TAXAS + `/${taxaId}/parcela${parcela.id ? `/${parcela.id}` : ''}`, parcela).then((response) => {
                const taxa = getState().all.find(c => c.id === taxaId)
                const parcelas = taxa!.parcelas
                parcelas[taxa!.parcelas.findIndex(p => p.id === response.data.id)] = response.data
                actions.patchElementOnAll({
                    id: taxaId,
                    parcelas: [...parcelas]
                })
                toast.info('Parcela editada.')
            })
        } else {
            return request.save(endpoints.TAXAS + `/${taxaId}/parcela${parcela.id ? `/${parcela.id}` : ''}`, parcela).then((response) => {
                const taxa = getState().all.find(c => c.id === taxaId)
                actions.patchElementOnAll({
                    id: taxaId,
                    parcelas: [...taxa!.parcelas, response.data]
                })
                toast.info('Parcela adicionada.')
            })

        }
    }),
}