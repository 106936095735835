import { Action, action, Thunk, thunk } from "easy-peasy"
import { request } from "lib/request"
import _ from "lodash"
import { message } from "antd"
import { IView } from "./bank/views"
import { capitalizeString } from "lib/helper"
import model from "models"
import toast from "lib/toast"

export interface IPaginatedResponse<T> {
    total: number
    data: T[]
}

interface ILoading {
    all: boolean
    more: boolean
    one: boolean
    saving: boolean
    removing: boolean
    custom: string
}

export interface IGenericModel<T> {
    all: T[]
    model: any
    setAll: Action<IGenericModel<T>, T[]>
    addAll: Action<IGenericModel<T>, T[]>
    loading: ILoading
    cacheExpired: boolean
    patchLoading: Action<IGenericModel<T>, Partial<ILoading>>
    setCacheExpired: Action<IGenericModel<T>, boolean>
    setTotal: Action<IGenericModel<T>, number>
    setModel: Action<IGenericModel<T>, Partial<T> | null>
    setElementOnAll: Action<IGenericModel<T>, T>
    patchElementOnAll: Action<IGenericModel<T>, Partial<T>>
    patchModel: Action<IGenericModel<T>, Partial<T>>
    removeElementFromAll: Action<IGenericModel<T>, { id: string; keyName?: string }>
    fetchAll: Thunk<
        IGenericModel<T>,
        {
            limit?: number
            offset?: number
            config?: {
                reset?: boolean
                loading?: string
            }
            [param: string]: any
        },
        any,
        any,
        Promise<IPaginatedResponse<T>>
    >
    fetchOne: Thunk<IGenericModel<T>, { id: string }>
    getGenericItemById: Thunk<IGenericModel<T>, { id: string | undefined }, any, any, T | undefined>
    getGenericItemsByIds: Thunk<IGenericModel<T>, string[], any, any, T[]>
    save: Thunk<IGenericModel<T>, Partial<T>>
    saveModel: Thunk<IGenericModel<T>, Partial<T>>
    saveAllPositions: Thunk<IGenericModel<T>>
    remove: Thunk<IGenericModel<T>, { id: string; showMessage?: boolean }>
    batchRemove: Thunk<IGenericModel<T>, { data: { id: string }[]; showMessage?: boolean }>
    total?: number
    getAll: Thunk<IGenericModel<T>, { limit?: number; offset?: number }>
    reset: Action<IGenericModel<T>>
    patchMany: Thunk<IGenericModel<T>, Partial<T>[]>
    fetchDependency: Thunk<
        IGenericModel<T>,
        {
            id: string
            dependencyArrayKey: string
            endpoint?: string
        }
    >
}

export const generic = <T extends { id?: string; position?: number }>(endpoint: string): IGenericModel<T> => ({
    model: undefined,
    all: [],
    cacheExpired: false,
    setAll: action((state, payload) => {
        state.all = payload
    }),
    addAll: action((state, payload) => {
        state.all = _.uniqBy([...state.all, ...payload], "id")
    }),
    setTotal: action((state, payload) => {
        state.total = payload
    }),
    setModel: action((state, payload) => {
        state.model = payload
    }),
    total: undefined,
    loading: {
        all: false,
        more: false,
        custom: "",
        one: false,
        saving: false,
        removing: false,
    },
    patchLoading: action((state, payload) => {
        state.loading = { ...state.loading, ...payload }
    }),
    setCacheExpired: action((state, payload) => {
        state.cacheExpired = payload
    }),
    fetchAll: thunk(async (actions, payloadParam) => {
        const { config, ...payload } = payloadParam || {}
        actions.patchLoading({
            all: !payloadParam?.offset || payloadParam?.offset! === 0,
            more: payloadParam?.offset! > 0,
        })
        return request
            .get(endpoint, payload)
            .then((response) => {
                if (response.data?.length !== undefined) {
                    const data: T[] = response.data
                    actions.setTotal(data?.length)
                    actions.setCacheExpired(false)
                    if (config?.reset) {
                        actions.setAll(data)
                    } else {
                        actions.addAll(data)
                    }
                    return { total: data?.length, data }
                } else {
                    const paginatedResponse: IPaginatedResponse<T> = response.data
                    actions.setTotal(paginatedResponse.total)
                    actions.setCacheExpired(false)
                    if (config?.reset) {
                        actions.setAll(paginatedResponse.data)
                    } else {
                        actions.addAll(paginatedResponse.data)
                    }
                    return paginatedResponse
                }
            })
            .finally(() => {
                actions.patchLoading({
                    all: false,
                    more: false,
                })
            })
    }),
    fetchOne: thunk(async (actions, { id }) => {
        actions.patchLoading({
            one: true,
        })
        return request
            .get(endpoint + "/" + id)
            .then((response) => {
                actions.setModel(response.data)
                return response.data
            })
            .finally(() => {
                actions.patchLoading({
                    one: false,
                })
            })
    }),
    save: thunk(async (actions, modelWithConfigs) => {
        const { saveWithPut, ...model } = modelWithConfigs as any
        actions.patchLoading({
            saving: true,
        })
        const saveEndpoint = model.id ? endpoint + "/" + model.id : endpoint
        return request
            .save(saveEndpoint, model, { saveWithPut } as any)
            .then((response) => {
                actions.setElementOnAll(response.data)
                return response.data
            })
            .finally(() => {
                actions.patchLoading({
                    saving: false,
                })
            })
    }),
    saveModel: thunk(async (actions, model, { getState }) => {
        actions.patchLoading({
            saving: true,
        })
        const saveEndpoint = model.id ? endpoint + "/" + model.id : endpoint
        const oldModel = { ...getState().model }
        actions.patchModel(model)
        actions.patchElementOnAll(model)
        return request
            .save(saveEndpoint, model)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                actions.patchModel(oldModel)
                actions.patchElementOnAll(oldModel)
            })
            .finally(() => {
                actions.patchLoading({
                    saving: false,
                })
            })
    }),
    patchMany: thunk(async (actions, models) => {
        actions.patchLoading({
            saving: true,
        })
        return request
            .patch(endpoint, models)
            .then((response) => {
                response.data?.forEach?.((elem: T) => {
                    actions.setElementOnAll(elem)
                })
                return response.data
            })
            .finally(() => {
                actions.patchLoading({
                    saving: false,
                })
            })
    }),
    remove: thunk(async (actions, { id, showMessage = true }) => {
        actions.patchLoading({
            removing: true,
            custom: id,
        })
        return request
            .delete(endpoint + "/" + id)
            .then(() => {
                actions.removeElementFromAll({ id })
                if (showMessage) {
                    toast.info("Excluído.")
                }
            })
            .finally(() => {
                actions.patchLoading({
                    saving: false,
                    custom: "",
                })
            })
    }),
    batchRemove: thunk(async (actions, payload) => {
        actions.patchLoading({
            saving: true,
            custom: "batch-remove",
        })
        return request
            .delete(endpoint, {
                data: payload.data,
            })
            .then(() => {
                payload.data.forEach((elem) => {
                    actions.removeElementFromAll({ id: elem.id })
                })
                if (payload.showMessage !== false) {
                    toast.info("Excluídos.")
                }
            })
            .finally(() => {
                actions.patchLoading({
                    saving: false,
                    custom: "",
                })
            })
    }),
    setElementOnAll: action((state, item) => {
        const index = state.all.findIndex((x) => x.id === item.id)
        if (index > -1) {
            state.all[index] = item
        } else {
            state.all = [...state.all, item]
            // if (state.total) {
            state.total = (state.total || 0) + 1
            // }
        }
    }),
    patchElementOnAll: action((state, item) => {
        console.log("item", item)
        const index = state.all.findIndex((x) => x.id === item.id)
        if (index > -1) {
            state.all[index] = { ...state.all[index], ...item }
        }
        state.all = [...state.all]
    }),
    patchModel: action((state, item) => {
        state.model = { ...state.model, ...item }
    }),
    removeElementFromAll: action((state, { id, keyName }) => {
        if (state.total) {
            state.total = state.total - 1
        }
        state.all = state.all.filter((item) => (item as any)[keyName || "id"] !== id)
    }),
    saveAllPositions: thunk(async (actions, payload, { getState }) => {
        actions.patchLoading({
            saving: true,
        })
        return request
            .patch(
                endpoint,
                getState().all.map((x) => ({ id: x.id, position: x.position }))
            )
            .then((response) => {
                // actions.setElementOnAll(response.data)
                return response.data
            })
            .finally(() => {
                actions.patchLoading({
                    saving: false,
                })
            })
    }),
    getAll: thunk(async (actions, payload, { getState }) => {
        if (getState().cacheExpired || (!getState().all.length && !getState().loading.all)) {
            return actions.fetchAll(payload)
        }
    }),
    getGenericItemById: thunk((actions, { id }, { getState }) => {
        if (!id) return undefined
        actions.getAll({})
        return getState().all.find((item) => item.id === id)
    }),
    getGenericItemsByIds: thunk((actions, ids, { getState }) => {
        if (!ids || !ids.length) return []
        actions.getAll({})
        const items: any = _.compact(ids.map((id) => getState().all.find((item) => item.id === id)))
        return items || []
    }),
    reset: action((state) => {
        state.all = []
        state.total = undefined
    }),
    fetchDependency: thunk(async (actions, payloadParam, { getState }) => {
        const { dependencyArrayKey, endpoint: paramEndpoint, ...payload } = payloadParam || {}
        if ((payload as any).id) {
            actions.patchLoading({
                custom: "dependency",
            })
            return request
                .get(paramEndpoint || endpoint + "/" + (payload as any).id + "/" + dependencyArrayKey, payload)
                .then((response) => {
                    ;(actions.patchElementOnAll as any)({
                        id: payloadParam.id,
                        [dependencyArrayKey]: response.data.data,
                    })
                    actions.setModel({
                        ...getState().model,
                        [dependencyArrayKey]: response.data.data,
                    })
                    return response.data
                })
                .finally(() => {
                    actions.patchLoading({
                        custom: "",
                    })
                })
        }
    }),
})

export function genericFetchSet<T>(key: string, endpoint: string): any {
    return {
        [key]: [],
        [`set${capitalizeString(key)}`]: action((state, payload) => {
            ;(state as any)[key] = payload
        }),
        [`fetch${capitalizeString(key)}`]: thunk(async (actions) => {
            return request
                .get(endpoint)
                .then((response) => {
                    ;(actions as any)[`set${capitalizeString(key)}`](response.data)
                    return response.data
                })
                .finally(() => {
                    ;(actions as any).patchLoading?.({ one: false })
                })
        }),
    }
}

export function genericFetchSetPaginated<T>(key: string, endpoint: string): any {
    return {
        [key]: [],
        [`set${capitalizeString(key)}`]: action((state, payload) => {
            ;(state as any)[key] = payload
        }),
        [`fetch${capitalizeString(key)}`]: thunk(async (actions) => {
            return request
                .get(endpoint)
                .then((response) => {
                    ;(actions as any)[`set${capitalizeString(key)}`](response.data.data)
                    return response.data.data
                })
                .finally(() => {
                    ;(actions as any).patchLoading?.({ one: false })
                })
        }),
    }
}
