import { CompanyType, Gender, MaritalStatus, PersonRoleType } from "lib/constants"
import { atom } from "recoil"

export interface IAddress {
    cep: string
    street: string
    number: string
    complement: string
    reference: string
    neighborhood: string
    state: string
    city: string
}

export interface IRegisterModel {
    cpf?: string
    cnpj?: string
    nickname?: string
    account_type?: "pessoa_fisica" | "pessoa_juridica"
    name_pessoa_fisica?: string
    gender?: Gender.Male | Gender.Female | Gender.Other
    maritalStatus?:
        | MaritalStatus.Divorced
        | MaritalStatus.Married
        | MaritalStatus.NotMarried
        | MaritalStatus.Other
        | MaritalStatus.Separate
        | MaritalStatus.Single
        | MaritalStatus.Widower
    birthDate?: string
    email?: string
    cellphone?: string
    password?: string
    shortPassword?: string
    name?: string
    motherName?: string
    publiclyExposedPerson?: boolean
    document_front?: string
    document_back?: string
    document_front_cpf?: string
    document_type?: "rg" | "cnh"
    social_contract?: string
    proof_residence?: string
    annual_billing?: string
    cnpj_card?: string
    address?: IAddress
    monthlyIncome?: number
    job?: string
    patrimony?: number

    // PJ
    companyType?: CompanyType
    companyActivity?: string
    constitutionDate?: string
    personRoleType: PersonRoleType
    persons: {
        name: string
        taxNumber: string
        cellphone: string
        email: string
        personRoleType: PersonRoleType
        birthDate: string
        nationality: string
        motherName: string
        personDocuments: {
            fileName: string
            documentType: number
        }[]
    }[]
}

export const registerAtom = atom<IRegisterModel | null>({
    key: "registerAtom",
    default: null,
})
