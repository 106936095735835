/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useMemo } from "react"
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect, useLocation } from "react-router-dom"
// import Landing from "products/landing"
import Products from "products"
import { useHost, useActions, useStore } from "hooks"
import config from "config"
import { RequestInspector } from "nanui"
import Viewer from "react-viewer"
import queryString from "query-string"

import "react-toastify/dist/ReactToastify.css"
import { IEnv } from "lib/request"
// import FormModal from "components/common/FormModal"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useRecoilState, useRecoilValue } from "recoil"
import { kinboxAtom } from "atoms/kinbox"
import AskPasswordModal from "components/AskPasswordModal"
import { askPasswordAtom, modalState } from "atoms/app"
// import "antd/dist/antd.css"

function App() {
    const imagesViewer = useStore((state) => state.app.imagesViewer)
    const { setDomainEnv, setImagesViewer, setModal } = useActions((actions) => actions.app)
    const _isProduction = process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
    // const { isInAppSubdomain } = useHost()
    const location = useLocation()
    const [kinbox, setKinbox] = useRecoilState(kinboxAtom)
    const [askPassword, setAskPassword] = useRecoilState(askPasswordAtom)
    const modalRecoil = useRecoilValue(modalState)
   

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#0078d4" }} spin />

    useEffect(() => {
        Spin.setDefaultIndicator(<Spin indicator={antIcon} />)
    }, [])

    useEffect(() => {
        const query = queryString.parse(location.search)
        if (query.auth_secret) {
            document.body.classList.add("kinbox_iframe")
            setKinbox({
                isKinbox: true,
            })
        }
        // console.log(window.location, window.parent.location)
    }, [location.search])

    useEffect(() => {
        ;(window as any)?.Kinbox?.on?.("conversation", function (data: any) {
            console.log(data)
            setKinbox({
                data,
                isKinbox: true,
            })
        })
    }, [])

    return (
        <div className="App" style={{ height: "100vh" }}>
            <Products />
            {/* {isInAppSubdomain ? (
                _domain.key && <Products />
            ) : (
                <Redirect to="/user/login" />
            )} */}
            {!_isProduction && <RequestInspector envs={config.envs} left onChange={(env: IEnv) => setDomainEnv(env)} />}
            <div id="modal-root" />
            <a id="downloadAnchorElem" style={{ display: "none" }} />
            {askPassword?.show && (
                <AskPasswordModal
                    onClose={() => {
                        setAskPassword({
                            show: false,
                        })
                    }}
                />
            )}
            {!!imagesViewer?.length && (
                <Viewer
                    visible={!!imagesViewer?.length}
                    onClose={() => {
                        setImagesViewer([])
                    }}
                    images={imagesViewer}
                    // drag={false}
                    // zoomSpeed={1}
                    // scalable={false}
                    // downloadable
                    // noNavbar
                    // downloadInNewWindow
                    // disableMouseZoom
                    // onMaskClick={(e) => {
                    //     setImagesViewer([])
                    // }}
                    zoomSpeed={0.5}
                    scalable={false}
                    downloadable
                    noNavbar
                    minScale={0.5}
                    // downloadInNewWindow
                    // disableMouseZoom
                    onMaskClick={(e) => {
                        const hasClass = (e.target as any).classList.contains("react-viewer-image")
                        if (!hasClass) {
                            setImagesViewer([])
                        }
                    }}
                />
            )}
            {modalRecoil}
        </div>
    )
}

export default withRouter(App)
