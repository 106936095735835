import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IMatricula } from "./contratos"
import { IUser } from "./users"

export interface IMargem {}

// export interface IUserApp {
//     id?: string
//     nome: string
//     tipo: 'consignante' | 'consignataria' | 'app'
//     cpf?: string // '111.222.333-75',
//     data_nascimento?: string // '11/02/1989',
//     login?: string // 'logintest',
//     email?: string // 'email@gmail.com',
//     senha: string // '123456',
//     profissao?: string // 'profissaoteste',
//     celular?: string // '(85) 91111-22-22'
//     consignante_id: string
//     pessoa_id?: string
//     status: 'aguardando_liberacao' | 'liberado' | 'recusado'
//     atualizado_em: string
//     criado_em: string
//     ativo: boolean
//     foto?: string
//     codigoValidacao?: string
//     conta_bancaria?: IContaBancaria
//     deletado: boolean
//     dataValues?: any
//   }

export interface ITelefone {
    tipo: "Celular" | "Comercial" | "Recado" | "Residencial"
    numero: string
}

// export interface IMatricula {
//     id: string
//     numero: string
//     entidade: string
//     orgao: string
//     margem: IMargem
//     criado_em: string
//     cargo: string
//     situacao: "Normal"
//     fim_contrato: "Indeterminado"
// }

export interface IContaBancaria {
    bankName?: string
    bankCode?: string
    bankAgency?: string
    bankCc?: string
    bankCcDigit?: string
    bankType?: string // "1" para c/c e “2” para c/p,
}

export interface IServidor {
    id: string
    nome: string
    criado_em: string
    cpf: string
    rg: string
    matriculas: IServidorMatricula[]

    // outros dados
    tipo?: "Proprio" | "Dependente" | "Conjugue"
    sexo?: "Masculino" | "Feminino"
    nascido_em?: string
    estado_civil?:
        | "Casado"
        | "Divorciado"
        | "Outros"
        | "Separado Judicialmente"
        | "Solteiro"
        | "Viuvo"
    dependentes?: number
    formacao?: string
    nome_mae?: string
    nome_pai?: string
    email?: string
    email_secundario?: string
    titulo_eleitor?: string
    secao?: string
    zona?: string
    cargo?: string
    atividade?:
        | "Comissionado"
        | "Temporário"
        | "Aposentado"
        | "Pensionista"
        | "Suspenso"
        | "Empregado público"
        | "Estatutário"
    telefones?: ITelefone[]
    emails?: string[]
    endereco?: string
    bairro?: string
    cidade?: string
    uf?: string
    pais?: string
    cep?: string
    tipo_conta?: string
    banco?: string
    numero_agencia?: string
    // digito_agencia?: string
    cc?: string
    // digito_cc?: string
    Usuario: IUser
}

export interface IServidorMatricula {
    id: string
    numero: string
    criado_em: string
    orgao: string
    cargo: string
    fim_contrato: string
    entidade: string
    situacao: string
    Margem: { [nome: string]: number }
    HistoricoMargem: IHistoricoMargem[]
    Pessoa: Partial<IServidor>
}

export interface IServidorMatriculaLimite {
    id: string
    limites: {
        maxLoanValue: number
        minLoanValue: number
        freeMarginValue: number
        minTime: number
    }
    Matricula: IMatricula
    datasDesconto: string[]
    nome: string
}

interface IHistoricoMargem {
    data_competencia: string | Date
    valor: number
}

export const servidores: IGenericModel<IServidor> = {
    ...generic(endpoints.SERVIDORES),
}

export const servidorMatriculas: IGenericModel<IServidorMatricula> = {
    ...generic(endpoints.SERVIDOR_MATRICULA),
}

export const servidorMatriculaLimite: IGenericModel<IServidorMatriculaLimite> = {
    ...generic(endpoints.SERVIDOR_MATRICULA_LIMITE),
}
