import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IApprove {
    _id: string
    type: string
    taxNumber: string
    operator: string
    favored: string
    value: number
    payload: {
        value: number
        contact: {
            name: string
            cpf: string
        }
        account: {
            type: string
            BankAccount: string
            BankBranch: string
            BankName: string
            Bank: string
            AccountType: string
        }
        paymentDate: string
        Infos: {
            ReceiverName: string
            ReceiverBank: string
            ReceiverBankBranch: string
            ReceiverBankAccount: string
            ReceiverBankAccountDigit: string
            ReceiverAccountType: string
            PixKeyType: string
            PixKeyValue: string
            ReceiverTaxNumber: string
            SearchProtocol: number
        }
        shortPassword: string
    }
    datePayment: string
    status: string
    createdAt: string
    updatedAt: string
    __v: number
    id: string
}
export const approve: IGenericModel<IApprove> = {
    ...generic(endpoints.APPROVE_LIST),
}
