import { Button, Col, Row } from "antd"
import { ButtonProps } from "antd/lib/button"
import React from "react"

import styles from "./style.module.css"

interface IActionsFooterProps {
    okText: string
    okProps: ButtonProps
    cancelText?: string
    cancelProps: ButtonProps
}

function ActionsFooter(props: IActionsFooterProps) {
    return (
        <Row gutter={15}>
            <Col>
                <Button type="primary" htmlType="submit" {...props.okProps}>
                    {props.okText}
                </Button>
            </Col>
            <Col>
                <Button type="text" {...props.cancelProps}>
                    {props.cancelText || "Cancelar"}
                </Button>
            </Col>
        </Row>
    )
}

export default ActionsFooter
