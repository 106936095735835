import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"

export default generateGenericMock(endpoints.bank.CONTRACTS, generateContractMockData)

export function generateContractMockData(index: string) {
    return {
        id: index || "0",
        name: faker.random.words(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        description: faker.random.words(10),
    }
}