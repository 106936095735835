import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IUser } from "./users"

export interface ICalendarEvent {
    id: string
    criado_em: string
    atualizado_em: string
    visible: "consignataria" | "todas_consignatarias" | "consignante"
    // consignataria_id: string
    showToAllUsers: boolean
    Usuario: IUser
    title: string
    start: string
    end: string
    allDay?: boolean
    resource?: any
}

export const calendario_eventos: IGenericModel<ICalendarEvent> = {
    ...generic(endpoints.CALENDARIO_EVENTOS)
}