import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateRandomFakeList } from "lib/helper"
import { actionsMock } from "./macros.mock"

export default generateGenericMock(endpoints.bank.AUTOMATIONS, generateMockData)

export function generateMockData() {
    return {
        id: faker.random.uuid(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        name: faker.random.words(),
        description: faker.random.words(20),
        conditions: {
            all: generateRandomFakeList(0, 5, () =>
                faker.helpers.randomize([
                    {
                        field: "ticket:status",
                        operator: faker.helpers.randomize(["is", "is_not"]),
                        value: faker.helpers.randomize(["new", "pending", "open", "solved", "closed"]),
                    },
                    {
                        field: "ticket:type",
                        operator: faker.helpers.randomize(["is", "is_not"]),
                        value: faker.helpers.randomize(["question", "incident", "problem", "task"]),
                    },
                ]))
        },
        actions: generateRandomFakeList(0, 5, () => faker.helpers.randomize(actionsMock)),
    }
}