import { endpoints } from "config/endpoints.config"
import { thunk, Thunk } from "easy-peasy"
import { request } from "lib/request"
import toast from "lib/toast"
import { IGenericModel, generic } from "models/generic"
import { IFile } from "./files"

export interface IVerbaDesconto {
    id: string
    produto_id: string
    codigo: string
    tipoFinanceiro: boolean
}

export interface IConsignataria {
    id: string
    atualizadoEm: string
    criadoEm: string
    // nome: string
    razao_social: string
    codigo: string
    consignante_id: string
    tipo: 'Banco' | 'Associação' | 'Sindicato' | 'Benefício'
    cnpj: string
    motivo?: string
    bloqueado: boolean
    atividade_empresarial?: string
    nome_fantasia?: string
    sigla?: string
    inscricao_estadual?: string
    inscricao_municipal?: string
    crc?: string
    inicio_vigencia_crc?: string
    fim_vigencia_crc?: string
    contrato_gestora?: string
    inicio_contrato_gestora?: string
    fim_contrato_gestora?: string
    telefone?: string
    celular?: string
    endereco?: string
    bairro?: string
    cidade?: string
    uf?: string
    pais?: string
    cep?: string
    docs?: IFile[]
    verba_descontos: IVerbaDesconto[]
}

export interface IConsignatariaModel extends IGenericModel<IConsignataria> {
    removeVerba: Thunk<IConsignatariaModel, { verbaId: string }>
    addVerba: Thunk<IConsignatariaModel, { verbaDesconto: any }>
    removeDoc: Thunk<IConsignatariaModel, { docId: string }>
    addDocs: Thunk<IConsignatariaModel, { docs: IFile[] }>
}

export const consignatarias: IConsignatariaModel = {
    ...generic(endpoints.CONSIGNATARIAS),
    removeVerba: thunk(async (actions, { verbaId }, { getState }) => {
        const consignataria = getState().model as IConsignataria
        return request.delete(endpoints.CONSIGNATARIAS + `/${consignataria.id}/verba/${verbaId}`).then(() => {
            actions.patchElementOnAll({
                id: consignataria.id,
                verba_descontos: consignataria?.verba_descontos.filter(v => v.id !== verbaId)
            })
            toast.info('Verba excluída.')
        })
    }),
    addVerba: thunk(async (actions, { verbaDesconto }, { getState }) => {
        const consignataria = getState().model as IConsignataria
        return request.post(endpoints.CONSIGNATARIAS + `/${consignataria.id}/verba`, verbaDesconto).then((response) => {
            actions.patchElementOnAll({
                id: consignataria.id,
                verba_descontos: [...consignataria!.verba_descontos, response.data]
            })
            toast.info('Verba adicionada.')
        })
    }),
    removeDoc: thunk(async (actions, { docId }, { getState }) => {
        const consignataria = getState().model as IConsignataria

        return request.delete(endpoints.CONSIGNATARIAS + `/${consignataria.id}/docs/${docId}`).then(() => {
            actions.patchElementOnAll({
                id: consignataria.id,
                docs: consignataria?.docs?.filter(v => v.id !== docId)
            })
            toast.info('Documento excluído.')
        })
    }),
    addDocs: thunk(async (actions, { docs }, { getState }) => {
        const consignataria = getState().model as IConsignataria

        return request.post(endpoints.CONSIGNATARIAS + `/${consignataria.id}/docs`, docs).then((response) => {
            actions.patchElementOnAll({
                id: consignataria.id,
                docs: [...consignataria!.docs!, ...response.data]
            })
            toast.info('Documento adicionado.')
        })
    })
}
