import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateUserMockData } from "./users.mock"
import { ILog } from "models/logs"

export default generateGenericMock(endpoints.LOGS, generateNotificationMockData)

export function generateNotificationMockData(index: string, model?: Partial<ILog>): ILog {
    return {
        id: index || "0",
        criado_em: faker.date.recent(5).toISOString(),
        user: generateUserMockData("0"),
        consignataria_id: "0",
        icon: faker.helpers.randomize(["fal fa-axe-battle", "fal fa-book-dead", "fal fa-bow-arrow", "fal fa-dice-d4", "fal fa-flame", "fal fa-flask-potion"]),
        cpf_servidor: faker.random.number() + "",
        nome_servidor: faker.name.firstName() + " " + faker.name.lastName(),
        matricula_servidor: faker.random.number() + "",
        orgao_servidor: faker.random.word(),
        num_contrato: faker.random.number() + "",
        identificador: faker.random.number() + "",
        evento: faker.helpers.randomize(['Alteração de dados', "Alteração de configurações", "Reserva de margem", "Liquidação de contratos", "Suspensão de contratos", "Edição de consignatária", "Envio de notificação", "Averbação de contratos", "Importação de dados", "Aprovação/Reprovação de importação", "Aprovação/Reprovação de fechamento", "Aprovação/Reprovação do arquivo retorno"]),
        descricao: faker.random.words()
    }
}