import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IProduto } from "models/produtos"

export default generateGenericMock(endpoints.PRODUTOS, generateFileMockData)

export function generateFileMockData(index?: string): IProduto {
    return {
        id: index || faker.random.uuid(),
        criado_em: faker.date.past().toISOString(),
        nome: faker.random.word(),
        tipo_margem: 'string',
        tipo_produto: 'string',
        codigo: 'string',
        parcelas_determinadas: true,
        consignante_id: '01',
        atualizado_em: faker.date.past().toISOString(),
        ativo: true
    }
}