import { Action, action, Thunk, thunk, computed, Computed, ActionOn, actionOn } from "easy-peasy"
import { request } from "lib/request"
import { endpoints } from "config/endpoints.config"
import { getBrandColors } from "lib/helper"
import { message, notification } from "antd"
import { Defaults } from "lib/constants"
import { IUser } from "./users"
import { IPerfil } from "./perfis"
import toast from "lib/toast"
import { PixKeyType } from "atoms/transfer"
import { IAccount } from "products/auth/Login"

export interface IPreferences {
    brandColor?: string
    brandColorSelected?: string
    brandColorIcon?: string
    isDarkBrand?: boolean
    favicon?: string
}

export enum PixActionStatusWebhook {
    Created = "Created",
    Registering = "Registering",
    Registered = "Registered",
    Disabled = "Disabled",
    Canceled = "Canceled",
    Error = "Error",
}

export interface IUserSession extends IUser {
    id: string
    deleted: boolean
    admin: boolean
    owner: boolean
    remember: boolean
    token: string
    nickname?: string
    balance: number
    name?: string
    account_type?: "pessoa_juridica" | "pessoa_fisica"
    accounts?: {
        AccountConditionType: number
        AccountKey: string
        AccountStatus: number
        Bank: string
        BankAccount: string
        BankAccountDigit: string
        BankBranch: string
        CreationDate: string
        SpbAccount: {
            Bank: string
            BankBranch: string
            BankAccount: string
            BankAccountDigit: string
        }
        UpdateDate: string
        id: string
        _id: string
    }[]
    workspace: {
        id: string
        name: string
        // subdomain: string
        // locale: string
        // secretary: ISecretary
        // logo: string
        // apps: IApp[]
    }
    secretarias?: string[]
    // products: {
    //     bank: {
    //         createdAt: string
    //         type: string
    //         plan: IPlan
    //         preferences: IPreferences
    //         role: IPerfil
    //     }
    // }
    perfis: IPerfil[]
    pixKeys?: {
        Bank: string
        BankBranch: string
        BankAccount: string
        BankAccountDigit: string
        key: string
        keyType: PixKeyType
        documentNumber: number
        status: PixActionStatusWebhook
        _id: string
        updatedAt: string
        createdAt: string
    }[]
    selectedAccount?: IAccount
}

export interface ISessionModel {
    user?: IUserSession
    perfil?: IPerfil
    loading: boolean
    remember: boolean
    setUser: Action<ISessionModel, IUserSession>
    setPerfil: Action<ISessionModel, IPerfil>
    setRemember: Action<ISessionModel, boolean>
    logout: Action<ISessionModel>
    restoreSession: Thunk<ISessionModel>
    login: Thunk<
        ISessionModel,
        {
            cpf: string
            password: string
            remember: boolean
            recaptchaKey?: string
            selectedAccount: IAccount
        }
    >
    loginOperator: Thunk<ISessionModel, { codOperator: string; password: string; selectedAccount: IAccount }>
    register: Thunk<ISessionModel, Partial<IUser>>
    sendForgotPassword: Thunk<ISessionModel, { email: string }>
    resetPassword: Thunk<ISessionModel, { password: string; email: string; token: string; codOperator?: string; taxNumber?: string }>
    joinAgent: Thunk<
        ISessionModel,
        {
            email: string
            name: string
            token: string
            lastName: string
            workspace: string
            productName: string
        }
    >
    isLoggedIn: Computed<ISessionModel, boolean>
    patchPreferences: Thunk<ISessionModel, Partial<IPreferences>>
    getUserData: Thunk<ISessionModel, void>
    setLoading: Action<ISessionModel, boolean>
}

const session: ISessionModel = {
    user: undefined,
    perfil: undefined,
    loading: false,
    remember: false,
    setRemember: action((state, payload) => {
        state.remember = payload
    }),
    setLoading: action((state, payload) => {
        state.loading = payload
    }),
    setUser: action((state, payload) => {
        state.user = { ...payload, remember: state.remember }
        localStorage.setItem("user", JSON.stringify({ ...payload, remember: state.remember }))
    }),
    setPerfil: action((state, payload) => {
        state.perfil = payload
        localStorage.setItem("perfil", JSON.stringify(payload))
    }),
    logout: action((state) => {
        state.user = undefined
        localStorage.removeItem("user")
        localStorage.removeItem("userChat")
        localStorage.removeItem("nanui-token")
        localStorage.removeItem("nanui-token-renewal")
    }),
    restoreSession: thunk((actions) => {
        const userString = localStorage.getItem("user")
        if (userString) {
            try {
                const user = JSON.parse(userString)
                actions.setUser(user)
            } catch (e) {
                console.log(e)
                actions.logout()
            }
        }
    }),
    login: thunk(async (actions, { remember, ...payload }) => {
        actions.setLoading(true)
        actions.setRemember(remember)
        request
            .post(endpoints.LOGIN, payload)
            .then((response) => {
                actions.setUser({ ...response.data, type: payload.selectedAccount.type })
            })
            .finally(() => {
                actions.setLoading(false)
            })
    }),
    loginOperator: thunk(async (actions, payload) => {
        actions.setLoading(true)
        actions.setRemember(true)
        request
            .post(endpoints.LOGIN_OPERATOR, payload)
            .then((response) => {
                actions.setUser({ ...response.data, type: payload.selectedAccount.type })
            })
            .finally(() => {
                actions.setLoading(false)
            })
    }),
    register: thunk(async (actions, payload) => {
        actions.setLoading(true)
        return request
            .post(endpoints.REGISTER, payload)
            .then((response) => {
                actions.setLoading(false)
                // toast.success("Pré-cadastro efetuado. Aguarde sua liberação por um gestor.", 20000)
            })
            .finally(() => {
                actions.setLoading(false)
            })
    }),
    sendForgotPassword: thunk(async (actions, payload) => {
        actions.setLoading(true)
        return request
            .post(endpoints.FORGOT_PASSWORD, payload)
            .then((response) => {
                toast.success("E-mail enviado!")
            })
            .finally(() => {
                actions.setLoading(false)
            })
    }),
    joinAgent: thunk(async (actions, payload) => {
        actions.setLoading(true)
        return request
            .post(endpoints.JOIN_WORKSPACE, payload)
            .then((response) => {
                toast.success("Registrado com sucesso!")
                actions.setUser(response.data)
            })
            .finally(() => {
                actions.setLoading(false)
            })
    }),
    resetPassword: thunk(async (actions, payload) => {
        actions.setLoading(true)
        return request
            .post(endpoints.RESET_PASSWORD, payload)
            .then((response) => {
                toast.success("Senha atualizada!")
            })
            .finally(() => {
                actions.setLoading(false)
            })
    }),
    getUserData: thunk(async (actions, payload, { getState }) => {
        return request.get(endpoints.USER_DATA, payload).then((response) => {
            console.log("getUserData", response.data)
            actions.setUser({ ...getState().user!, ...response.data })
        })
    }),
    isLoggedIn: computed((state) => !!state.user),
    patchPreferences: thunk(async (actions, model, { getState }) => {
        return request
            .patch(endpoints.bank.PREFERENCES, {
                ...model,
                ...getBrandColors(model.brandColor || Defaults.BRAND_COLOR),
            })
            .then((response) => {
                toast.success("Preferências salvas.")
                actions.setUser({
                    ...getState().user!,
                    // products: {
                    //     ...getState().user!.products,
                    //     bank: {
                    //         ...getState().user!.products.bank,
                    //         preferences: {
                    //             ...getState().user!.products.bank.preferences,
                    //             ...model,
                    //             ...getBrandColors(model.brandColor || Defaults.BRAND_COLOR),
                    //         },
                    //     },
                    // },
                })
            })
    }),
}

export default session
