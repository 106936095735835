import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IUser } from "models/users"

export default generateGenericMock(endpoints.USERS, (index: string) => generateUserMockData(index, { status: 'aguardando_liberacao' }))
export const liberacao_mock = generateGenericMock(endpoints.USERS + "?status=aguardando_liberacao", (index: string) => generateUserMockData(index, { status: 'aguardando_liberacao' }))

export function generateUserMockData(index: string, model?: Partial<IUser>): IUser {
    return {
        id: index || "0",
        atualizado_em: faker.date.past().toISOString(),
        criado_em: faker.date.past().toISOString(),
        email: faker.internet.email(),
        ativo: faker.random.boolean(),
        status: faker.helpers.randomize(['aguardando_liberacao', 'liberado', 'recusado']),
        avatar: faker.helpers.randomize([undefined, 'https://i.pravatar.cc/300']),
        nome: faker.name.firstName() + " " + faker.name.lastName(),
        celular: faker.phone.phoneNumber(),
        cpf: faker.random.number({min: 11111111111, max: 99999999999}) + '',
        tipo: faker.helpers.randomize(['consignante', 'consignataria']),
        login: faker.internet.userName()
    }
}
