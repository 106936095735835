import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"

const filesMockList = [
    [{
        uid: "rc-upload-1594324565717-3",
        lastModified: 1592517055423,
        lastModifiedDate: "2020-06-18T21:50:55.423Z" as any,
        name: "Arquivo de texto.txt",
        size: 1863,
        type: "text/plain",
        percent: 100,
        status: "done",
        response: {
            url:
                "https://bank-api.korujadev2.online/api/assets/download/5232a020-c216-11ea-b54d-253a967046cb.txt",
        },
    }],
    [{
        uid: "rc-upload-1594324565717-4",
        lastModified: 1592517055423,
        lastModifiedDate: "2020-06-18T21:50:55.423Z" as any,
        name: "Snake.jpg",
        size: 1863,
        type: "image/jpeg",
        percent: 100,
        status: "done",
        response: {
            url:
                "https://cdn.dribbble.com/users/719060/screenshots/8010297/media/d1ea5963e6b2b5ea0ec8d185294feaa6.jpg",
        },
    }],
]

export default generateGenericMock(endpoints.bank.RESPONSES, generateMockData)

export function generateMockData() {
    return {
        id: faker.random.uuid(),
        name: faker.random.words(),
        text: faker.random.words(20),
        files: faker.helpers.randomize(filesMockList),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        restriction: faker.helpers.randomize([
            {
                type: faker.helpers.randomize(["user", "group"]),
                ids: faker.helpers.randomize([["1"], ["1", "2"]]),
            },
            {
                type: "public"
            }
        ]),
    }
}