export const endpoints = {
    // Auth
    LOGIN: "/app/users/login",
    LOGIN_OPERATOR: "/app/operator/login",
    LIST_LOGIN: "/app/users/list-login",
    REGISTER: "/app/users/register",
    FORGOT_PASSWORD: "/app/users/forgot-password",
    RESET_PASSWORD: "/app/users/reset-password",
    JOIN_WORKSPACE: "/users/join-workspace",
    USER_PROFILE: "/users/profile",
    CHANGE_PASSWORD: "/app/users/change-password",
    UPLOAD: "/assets/upload",
    UPLOAD_BATCH: "/app/batch-transfers/upload",
    UPLOAD_FUNCIONARIOS: "/app/consignor/upload",
    // UPLOAD: "https://run.mocky.io/v3/8baef51b-cd02-4738-93db-b69b2c97aac2",
    APPS: "/apps",
    WORKSPACE_INFO: "/workspace/info",
    VERIFY_SUBDOMAIN: "/verify-subdomain",
    CONSIGNANTES: "/consignantes",
    CONSIGNATARIAS: "/consignatarias",
    PARAMETROS_CONVENIO: "/consignantes/parametros-convenio",
    PRIMEIRO_DESCONTO: "/consignantes/primeiro-desconto",
    TAXAS: "/consignatarias/taxas",
    PRODUTOS: "/produto",
    RELATORIOS: "/relatorios",

    USER_DATA: "/app/users/user-data",

    // HOME
    EXTRATOS: "/app/transactions/extract",

    // SERVICES
    CONTACTS: "/app/users/contacts",
    TRANSFER: "/app/transactions/transfer-money",
    BOLETO: "/app/transactions/boletos/info",
    BOLETO_PAY: "/app/transactions/boletos/pay",
    BOLETO_GERAR: "/app/transactions/boletos",
    DARF_PAY: "/app/transactions/boletos/pay/darf",
    GPS_PAY: "/app/transactions/boletos/pay/gps",
    FGTS_PAY: "/app/transactions/boletos/pay/fgts",
    APPROVE_LIST: "/app/transactions/listTransactions",
    APPROVE_TRANSACTION: "/app/transactions/approve-transaction",
    APPROVE_DESAPROVE: "/app/transactions/disapprove-transaction",

    // RECARGA
    RECARGA_GET_VALUES: "/app/recharge/get-values-ddd",
    RECARGA_SOLICITAR_VENDA: "/app/recharge",
    RECARGA_CONFIRMAR: "/app/recharge/confirm-sales",

    // PIX
    PIX_KEYS: "app/pix/keys",
    PIX_KEYS_CONFIRM: "app/pix/keys/confirm",
    PIX_KEYS_CONFIRM_RESEND: "app/pix/keys/resend-code",
    PIX_INFO_PIX_KEYS: "app/pix/payment/get-infos-pix-key",
    PIX_INFO_PIX_HASH_CODE: "app/pix/payment/get-infos-pix-hash-code",
    PIX_GENERATE_STATIC_QR: "app/pix/payment/generate-static-pix-qrcode",
    PIX_GENERATE_DYNAMIC_QR: "app/pix/payment/generate-dynamic-pix-qrcode",

    // OPERATORS
    USERS: "/app/operator",

    // Admin
    admin: {
        ACCOUNT: "/admin/account",
        CHANGE_PASSWORD: "/admin/account/change-password",
        ORGANIZATION: "/admin/organization",
    },

    system: {
        REGIONS: "/system/regions",
        SECRETARIES: "/system/secretaries",
    },

    PERFIS: "/perfis",
    SERVIDORES: "/servidor-matricula/servidor",
    SERVIDOR_MATRICULA: "/servidor-matricula",
    SERVIDOR_MATRICULA_LIMITE: "/servidor-matricula/servidor/limites",
    SIMULAR: "/servidor-matricula/servidor/simular",
    NOTIFICATIONS: "/app/push-notification",
    AVISOS: "/avisos",
    DOWNLOADS: "/downloads",
    IMPORTACOES: "/importacoes",
    LOGS: "/logs",
    CALENDARIO_EVENTOS: "/calendar-events",
    APPROVE_USERS: "/users/approve",
    FILES: "/files",
    RESERVAR_MARGEM: "/loan/reservar-margem",
    CONTRATOS: "/loan/contratos",
    RESERVA_APPROVE: "/loan/reservas/approve",
    LIQUIDAR_TOTAL: "/loan/liquidar/total",
    LIQUIDAR_PARCIAL: "/loan/liquidar/parcial",
    LIQUIDAR_AMORTIZAR: "/loan/liquidar/amortizar",
    RESERVA_PENDENCIAR: "/loan/reservas/pendenciar",
    RESERVA_RESOLVER_PENDENCIA: "/loan/reservas/resolver-pendencia",
    RESERVA_REMOVER_PENDENCIA: "/loan/reservas/remover-pendencia",
    RESERVA_DISAPPROVE: "/loan/reservas/disapprove",
    REFINANCIAR_CONTRATOS: "/refinanciar-contratos",
    TREASURY_IMPORTACOES: "/app/batch-transfers",
    TREASURY_IMPORTACOES_RECEIPT: "/app/batch-transfers/receipt",

    // Econtract
    bank: {
        // Settings
        VIEWS: "/bank/views",
        CONTRACTS: "/bank/contracts",
        AGENTS: "/bank/agents",
        GROUPS: "/bank/groups",
        TAGS: "/bank/tags",
        BUSINESS_HOURS: "/bank/business-hours",
        RESPONSES: "/bank/responses",
        CUSTOM_FIELDS: "/bank/custom-fields",
        BOTS: "/bank/bots",
        MACROS: "/bank/macros",
        AUTOMATIONS: "/bank/automations",
        SLAS: "/bank/slas",
        CONTACTS_EXPORT: "/bank/contacts-export",
        ORGANIZATIONS: "/bank/organizations",
        PREFERENCES: "/bank/preferences",
        SEARCH: "/bank/search",
        CONTACTS: "/bank/contacts",

        // Channels
        CHANNELS: "/bank/channels",
        WHATSAPP_QRCODE: "/bank/channels/whatsapp/qrcode",
        WHATSAPP_RESTART_SERVER: "/bank/channels/whatsapp/restart",

        // Business
        TICKETS: "/bank/tickets",
        TICKETS_EXPORT: "/bank/tickets/export",
        CONVERSATIONS: "/bank/conversations",
        EVENTS: "/bank/events",

        // Report
        REPORT_TICKETS: "/bank/report/tickets",
        REPORT_TICKETS_AGENT_PERFORMANCE: "/bank/report/tickets-agents",
        REPORT_TICKETS_GROUP_PERFORMANCE: "/bank/report/tickets-groups",
        REPORT_CONVERSATIONS: "/bank/report/conversations",
        REPORT_CONVERSATIONS_AGENT_PERFORMANCE: "/bank/report/conversations-agents",
        REPORT_CONVERSATIONS_GROUP_PERFORMANCE: "/bank/report/conversations-groups",
    },
}
