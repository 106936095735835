export enum Type {
    EMAIL = "email",
    FACEBOOK = "facebook",
    WHATSAPP = "whatsapp",
    MESSENGER = "messenger",
}

export enum Status {
    CONNECTED = "connected",
    DISCONNECTED = "disconnected",
    QR_CODE = "qrcode",
}

export const TypeLabel = {
    email: "E-mail",
    facebook: "Facebook",
    whatsapp: "Whatsapp",
    messenger: "Messenger",
}

export default {
    Type,
    TypeLabel,
    Status
}

export const ChannelDetails = {
    [Type.WHATSAPP]: {
        excludable: true,
        replyable: true
    },
    [Type.FACEBOOK]: {
        excludable: false,
        replyable: false
    },
    [Type.EMAIL]: {
        excludable: false,
        replyable: false
    },
    [Type.MESSENGER]: {
        excludable: false,
        replyable: false
    }
}