import React, { useState } from "react"
import { message } from "antd"

export const toast = {
    success: (content: string | React.ReactNode, duration: number = 5) => {
        console.log('content', content)
        return message.success({
            content,
            duration,
            icon: <i className="fas fa-check-circle" />,
        })
    },
    error: (content: string | React.ReactNode, duration: number = 5) => {
        return message.error({
            content,
            duration,
            icon: <i className="fas fa-do-not-enter" />,
        })
    },
    info: (content: string | React.ReactNode, duration: number = 5) => {
        return message.info({
            content,
            duration,
            icon: <i className="fas fa-info-circle" />,
        })
    },
    warning: (content: string | React.ReactNode, duration: number = 5) => {
        return message.warning({
            content,
            duration,
            icon: <i className="fas fa-exclamation-circle" />,
        })
    },
    loading: (content: string | React.ReactNode, duration: number = 5) => {
        return message.loading({
            content,
            duration,
            icon: <i className="far fa-spinner-third toast-loading" />,
        })
    },
}

export default toast
