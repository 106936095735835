import { atom } from "recoil"

interface IKinbox {
    contact: {
        id: string
        customFields?: {
            [key: string]: {
                type: string
                value: string
            }
        }
        name: string
        avatar: string
        email: string
        phone: string
    }
    conversation: {
        id: string
        identifier: string
        assignee: {
            id: string
        }
        group: {
            id: string
        }
        channel_type: string
        link: string
        tags: {
            id: string
        }[]
    }
    user: {
        id: string
        name: string
        phone: string
        avatar: string
        email: string
        isActive: boolean
    }
    isDev: boolean
}

export const kinboxAtom = atom<{ data?: IKinbox | undefined; isKinbox: boolean } | undefined>({
    key: "kinboxAtom",
    default: undefined,
})
