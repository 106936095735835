import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

interface IBusinessHoursDay {
    active: boolean
    hours: string[]
}

export interface IBusinessHours {
    id: string
    name: string
    updatedAt: string
    createdAt: string
    sun: IBusinessHoursDay
    mon: IBusinessHoursDay
    tue: IBusinessHoursDay
    wed: IBusinessHoursDay
    thu: IBusinessHoursDay
    fri: IBusinessHoursDay
    sat: IBusinessHoursDay
}

export const businessHours: IGenericModel<IBusinessHours> = {
    ...generic(endpoints.bank.BUSINESS_HOURS),
}
