import app, { IAppModel } from "./app"
import session, { ISessionModel } from "./session"
import bank, { IEcontractModel } from "./bank"
import system, { ISystemModel } from "./system"
import { marketplace, IMarketPlace } from "./marketplace"
import { IGenericModel } from "./generic"
import { perfis, IPerfil } from "./perfis"
import { users, IUser } from "./users"
import { consignantes, IConsignante } from "./consignantes"
import { consignatarias, IConsignatariaModel } from "./consignatarias"
import { calendario_eventos, ICalendarEvent } from "./calendario_eventos"
import { notifications, INotification } from "./notifications"
import { logs, ILog } from "./logs"
import { downloads, IDownload } from "./downloads"
import { files, IFile } from "./files"
import { servidores, IServidor, servidorMatriculas, IServidorMatricula, servidorMatriculaLimite, IServidorMatriculaLimite } from "./servidores"
import { taxas, ITaxa, ITaxaModel } from "./taxas"
import { contratos, IContrato } from "./contratos"
import { importacoes, IImportacao } from "./importacoes"
import { IParametroConvenio, parametrosConvenio } from "./parametrosConvenio"
import { IProduto, produtos } from "./produtos"
import { approveUsers, IApproveUser } from "./approveUsers"
import { IAviso, avisos } from "./avisos"
import { IExtract, extratos } from "./extrato"
import { ITreasuryImport, treasury_imports } from "./treasury_imports"
import { approve, IApprove } from "./approve"

export interface IStoreModel {
    session: ISessionModel
    app: IAppModel
    bank: IEcontractModel
    system: ISystemModel
    marketplace: IMarketPlace
    perfis: IGenericModel<IPerfil>
    users: IGenericModel<IUser>
    consignantes: IGenericModel<IConsignante>
    consignatarias: IConsignatariaModel
    calendario_eventos: IGenericModel<ICalendarEvent>
    notifications: IGenericModel<INotification>
    avisos: IGenericModel<IAviso>
    logs: IGenericModel<ILog>
    downloads: IGenericModel<IDownload>
    files: IGenericModel<IFile>
    servidores: IGenericModel<IServidor>
    servidorMatriculas: IGenericModel<IServidorMatricula>
    servidorMatriculaLimite: IGenericModel<IServidorMatriculaLimite>
    taxas: ITaxaModel
    contratos: IGenericModel<IContrato>
    importacoes: IGenericModel<IImportacao>
    parametrosConvenio: IGenericModel<IParametroConvenio>
    produtos: IGenericModel<IProduto>
    approveUsers: IGenericModel<IApproveUser>
    extratos: IGenericModel<IExtract>
    treasury_imports: IGenericModel<ITreasuryImport>
    approve: IGenericModel<IApprove>
}

const model: IStoreModel = {
    session,
    users,
    extratos,
    treasury_imports,
    taxas,
    consignantes,
    consignatarias,
    app,
    bank,
    approve,
    system,
    marketplace,
    perfis,
    calendario_eventos,
    notifications,
    avisos,
    logs,
    downloads,
    files,
    servidores,
    servidorMatriculas,
    servidorMatriculaLimite,
    contratos,
    importacoes,
    parametrosConvenio,
    produtos,
    approveUsers,
}

export default model
