import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IUser } from "./users"

export interface IAviso {
    id: string
    criado_em: string
    Usuario: IUser
    destinatario_tipo: 'consignataria' | 'consignante'
    consignataria_id?: string
    titulo: string
    novo?: boolean
    texto?: string
    // destinatario_nome: string
    // tipo: "mensagem" | "evento" | "feriado"
    // data_inicial: string
    // data_final: string
    // visualizada?: boolean
    // apagada?: boolean
    // enviada?: boolean
}

export const avisos: IGenericModel<IAviso> = {
    ...generic(endpoints.AVISOS)
}