import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IRegion {
    id: string
    name: string
    updatedAt: string
    createdAt: string
    subdomain: string
    avatar: string
    secretaryIds: string[] 
}

export const regions: IGenericModel<IRegion> = {
    ...generic(endpoints.system.REGIONS),
}