import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IReportTickets } from "products/bank/pages/Reports/ReportTickets"

export default generateGenericMock(endpoints.bank.REPORT_TICKETS, genMockReportConversation)

export function genMockReportConversation(): IReportTickets {
    return {
        initialDate: faker.date.past().toISOString(),
        finalDate: faker.date.past().toISOString(),
        tme: faker.random.number({ max: 200 }),
        tma: faker.random.number({ max: 200 }),
        tmr: faker.random.number({ max: 200 }),
        new: faker.random.number({ max: 200 }),
        open: faker.random.number({ max: 200 }),
        pending: faker.random.number({ max: 200 }),
        resolved: faker.random.number({ max: 200 }),
        closed: faker.random.number({ max: 200 }),
    }
}