import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IPerfil } from "./perfis"
import { IContaBancaria } from "./servidores"

export enum TypeOperator {
    Administrator = "administrator",
    Approver = "approver",
    Operator = "operator",
    CNPJ = "cnpj",
    CPF = "cpf",
}

export interface IUser {
    id: string
    atualizado_em: string
    criado_em: string
    ativo: boolean
    status: "aguardando_liberacao" | "liberado" | "recusado"
    nome: string
    avatar?: string
    celular?: string
    tipo: "consignante" | "consignataria" | "app"
    consignataria_id?: string
    consignante_id?: string
    login: string
    senha?: string
    profissao?: string
    perfis?: Partial<IPerfil>[]
    conta_bancaria?: IContaBancaria
    foto?: string
    codigoValidacao?: string
    UsuarioPerfil?: {
        Perfil: IPerfil
    }[]
    deletado?: boolean
    pushTokens?: IUserPushToken[]
    dataValues?: any

    cpf?: string
    name?: string
    email?: string
    cellphone?: string
    type?: TypeOperator
    password?: string
    shortPassword?: string
}

export interface IUserPushToken {
    deviceId?: string
    pushToken: string
}

export const users: IGenericModel<IUser> = {
    ...generic(endpoints.USERS),
}
