import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateRandomFakeList } from "lib/helper"

export default generateGenericMock(endpoints.bank.GROUPS, generateGroupMockData)

export function generateGroupMockData(index: string) {
    return {
        id: index,
        name: faker.random.words(),
        description: faker.random.words(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        agents: generateRandomFakeList(0, 5, () => faker.random.uuid()),
        businessHours: generateRandomFakeList(0, 5, () => faker.random.uuid()),
        escalation: faker.random.boolean(),
        escalationHours: faker.random.number(72),
        escalationNotifyEmails: generateRandomFakeList(0, 5, () => faker.internet.email()),
        autoAssign: faker.random.boolean(),
        allowAgentAvailability: faker.random.boolean(),
    }
}

