import React from "react"
import styles from "./style.module.css"
import logo from "assets/images/logos/logo.svg"
import logoIcon from "assets/images/logos/logo-icon.svg"

function TermsSection({ children, title }) {
    return (
        <div className="terms-section">
            <h2>{title}</h2>
            {children}
        </div>
    )
}

export default function Privacy(props) {
    return (
        <div className="landing-space">
            <div className={styles.logo}>
                <img src={window.innerWidth > 500 ? logo : logoIcon} alt="logo" />
            </div>
            <div className="landing-section">
                <div className="midcol">
                    <div className="center">
                        <h1 style={{ fontSize: 50, marginBottom: 10, textAlign: "center" }}>Política de Privacidade</h1>
                    </div>
                </div>
            </div>
            <div className="landing-section">
                <div className="midcol" style={{ maxWidth: 800 }}>
                    <TermsSection>
                        <p>
                            Esta Política de Privacidade compreende o Aplicativo Ta Na Mão Gestão (“Aplicativo”), bem como o site www.tanamaobank.com.br (“Site”),
                            doravante, em conjunto, denominados simplesmente "Tanamao", e tem por objetivo fornecer uma visão ampla e transparente das práticas
                            relacionadas à Coleta, Uso, Armazenamento, Compartilhamento e Eliminação de Dados pelo Tanamao.
                        </p>
                        <p>
                            A Tanamao respeita a privacidade dos usuários de seu site e garante que as informações pessoais que você fornecer sejam tratadas
                            confidencialmente. Usamos seus dados para processar pedidos o mais rápido e fácil possível. Além disso, usaremos seus dados somente
                            com sua permissão.
                        </p>
                        <p>
                            A Tanamao não venderá suas informações pessoais a terceiros e só compartilhará as informações com terceiros envolvidos no
                            processamento de seu pedido.
                        </p>
                    </TermsSection>
                    <TermsSection title="1. INTRODUÇÃO">
                        <p>
                            Respeitamos a confiança depositada pelos Usuários no Tanamao, por esse motivo adotamos rigorosos padrões de proteção para os nossos
                            sistemas e não iremos usar os seus dados de qualquer forma que não esteja autorizada e prevista nesta Política de Privacidade.
                        </p>
                        <p>
                            A nossa Política de Privacidade divide os dados dos Usuários nas seguintes categorias: Dados de Folha de Pagamento, Dados Pessoais,
                            Dados Financeiros, Dados de Conta e Dados de Acesso. Cada categoria é definida e explicada com detalhes e exemplos mais à frente.
                        </p>
                    </TermsSection>
                    <TermsSection title="2. QUAIS DADOS SÃO COLETADOS">
                        <p>
                            Os Dados que o Tanamao coleta possuem três fontes diferentes: (a) dados disponibilizados pelo Cliente ao Tanamao; (b) dados
                            disponibilizados pelo Usuário ao se cadastrar no Tanamao; (c) dados coletados pelo Tanamao com o uso do Aplicativo ou do Site:
                        </p>
                        <p>
                            <b>(a) Dados disponibilizados pelo Cliente</b> - Esses dados incluem as informações que o Cliente disponibilizar, de forma pré
                            autorizada pelo Usuário, através do Software, levando em consideração o sigilo das informações no ato de disponibilização ao
                            Tanamao, abrangendo:
                        </p>
                        <p style={{ marginLeft: 20 }}>
                            <b>(i) Dados de Folha de Pagamento:</b> quaisquer dados disponibilizados pelo Cliente e que permitam a identificação e validação do
                            Usuário no ato de cadastramento no Tanamao. No caso, inclui, entre outros, CPF, data de nascimento, número de matrícula, remuneração
                            e descontos processados em folha de pagamento, fornecidos ao Software, podendo estes dados alternarem de acordo com cada formatação
                            de contratação junto ao Cliente.
                        </p>
                        <p>
                            <b>(b) Dados disponibilizados pelo Usuário</b> - Esses dados incluem as informações que o Cliente disponibilizar, de forma pré
                            autorizada pelo Usuário, através do Software, levando em consideração o sigilo das informações no ato de disponibilização ao
                            Tanamao, abrangendo:
                        </p>
                        <p style={{ marginLeft: 20 }}>
                            <b>(i) Dados Pessoais:</b> quaisquer dados disponibilizados pelo Cliente e que permitam a identificação e validação do Usuário no
                            ato de cadastramento no Tanamao. No caso, inclui, entre outros, CPF, data de nascimento, número de matrícula, remuneração e
                            descontos processados em folha de pagamento, fornecidos ao Software, podendo estes dados alternarem de acordo com cada formatação de
                            contratação junto ao Cliente.
                        </p>
                        <p style={{ marginLeft: 20 }}>
                            <b>(ii) Dados de Conta:</b> quaisquer dados que o Usuário forneça ao Tanamao, tais como, mas não se limitando, há dados de
                            instituições bancárias, número de agência bancária, número de conta, valor da dívida e número de contratos que o Usuário possui, bem
                            como quaisquer outros dados de contas correntes, investimentos, contas poupança e/ou cartões de crédito dos Usuários.
                        </p>
                        <p>
                            <b>(c) Dados coletados pelo Tanamao:</b> Esses dados incluem todas as informações que coletamos do Usuário ao usar ou acessar o
                            Tanamao:
                        </p>
                        <p style={{ marginLeft: 20 }}>
                            <b>(i) Dados Financeiros:</b> quaisquer dados do Usuário coletados pelo Tanamao diretamente junto ao Cliente, podendo ser ele ente
                            público ou empresa privada, com o qual o Usuário possua vínculo de emprego, que detalhem, informações sobre a(s) sua(s) dívidas(s) e
                            contrato(o), incluindo, renda mensal do Usuário, valor de margem consignável, a natureza e tipo de suas verbas, dívidas e contratos,
                            podendo ser consignadas em folha ou não, entre outros dados do tipo relevantes ao planejamento financeiro do Usuário, incluindo,
                            ainda, mas não se limitando, a dados que tenham sido inseridos pelo Usuário em sua Conta Tanamao para individualização de sua
                            experiência.
                        </p>
                        <p style={{ marginLeft: 20 }}>
                            <b>(ii) Dados de Acesso:</b> quaisquer dados do Usuário coletados pelo Tanamao diretamente junto ao Cliente, podendo ser ele ente
                            público ou empresa privada, com o qual o Usuário possua vínculo de emprego, que detalhem, informações sobre a(s) sua(s) dívidas(s) e
                            contrato(o), incluindo, renda mensal do Usuário, valor de margem consignável, a natureza e tipo de suas verbas, dívidas e contratos,
                            podendo ser consignadas em folha ou não, entre outros dados do tipo relevantes ao planejamento financeiro do Usuário, incluindo,
                            ainda, mas não se limitando, a dados que tenham sido inseridos pelo Usuário em sua Conta Tanamao para individualização de sua
                            experiência.
                        </p>
                        <p style={{ marginLeft: 40 }}>
                            <b>(1) Informações coletadas de Usuários com o uso do Site:</b> Site. Inclui, entre outros dados do tipo, o navegador de acesso do
                            Usuário, informações sobre a sua tela e resolução do computador, endereço do protocolo de Internet (IP), tempo médio gasto, data e
                            cidade de acesso do Tanamao.
                        </p>
                        <p style={{ marginLeft: 40 }}>
                            <b>(2) Informações coletadas de Usuários com o uso do Aplicativo:</b> Inclui, entre outros dados do tipo, o modelo do dispositivo
                            móvel, sistema operacional, provedor de conexão ou de telefonia, localização do Usuário (cidade, estado e país).
                        </p>
                        <p style={{ marginLeft: 40 }}>
                            <b>(3) Comunicação entre Usuário e o Tanamao:</b> Se você usar as ferramentas de comunicação disponibilizadas pelo Tanamao, dentre
                            as quais podemos citar e-mail, telefone, chat e/ou SMS, podemos manter um registro de toda comunicação havida entre o Tanamao e o
                            Usuário.
                        </p>
                        <p style={{ marginLeft: 40 }}>
                            <b>(4) Cookies:</b> conforme disposto no Item "Cookies" desta Política de Privacidade.
                        </p>
                        <p>
                            Periodicamente, o Tanamao poderá solicitar complementações de dados ao Usuário, sendo certo que você sempre terá a opção de
                            fornecê-los ou não.
                        </p>
                    </TermsSection>
                    <TermsSection title="3. COMO USAMOS OS DADOS COLETADOS">
                        <p>O Tanamao utilizará os dados coletados para as seguintes finalidades:</p>
                        <p>
                            <b>(a) Funcionamento do Tanamao.</b> Usaremos os dados coletados para que o Usuário, ao acessar o Tanamao, possa alcançar o
                            desempenho esperado, bem como para desenvolver e melhorar os serviços e funcionalidades ofertados, realizar estatísticas genéricas
                            para monitoramento de utilização do Tanamao, avaliar risco de operação, resolver problemas do Software, verificar a proteção do
                            Tanamao contra erros, fraudes ou qualquer outro crime eletrônico, entre outros. O Tanamao poderá acessar os seus Dados de
                            Conta/Dados Pessoais/Dados de Folha de Pagamento/Dados Financeiros para lhe prestar atendimento quando você precisar.
                        </p>
                        <p>
                            <b>(b) Ligações telefônicas, envio de e-mail e notificações pelo Tanamao.</b> O Tanamao poderá ligar para os Usuários e/ou enviar
                            e-mails ou notificações com alertas e comunicados relacionados ao Tanamao a fim de auxiliá-los a explorar todas as funcionalidades
                            do Tanamao, como notificações alertando para realização de planejamento financeiro, oferta de produtos ou serviços, melhoria nas
                            taxas de juros práticas pelas instituições bancárias, podendo ser a instituição bancária com a qual o Usuário possui dívida ou
                            contra, bem como podendo ser também instituição bancária diversa daquela que o Usuário possui dívida ou contrato, possibilitando um
                            portabilidade de dívida ou contrato com condições mais vantajosas ao Usuário, bem como informações sobre inclusão de novas
                            funcionalidades, lembretes de uso do Site/Aplicativo, entre outros do tipo.
                        </p>
                        <p>
                            <b>(c) Análise Financeira.</b> O Tanamao poderá processar os seus Dados de Conta/Dados Pessoais/Dados de Folha de Pagamento/Dados
                            Financeiros de forma codificada e automatizada, com uso do Software, a fim de gerar informações e estatísticas financeiras gerais
                            não individualizadas (“Dados Gerais”). Por exemplo, o Software poderá informar ao Tanamao que no mês de janeiro a incidência de
                            pessoas que renegociaram suas dívidas ultrapassou os 30% ou que 40% dos Usuários pertencentes a empresa X estão com dívidas com
                            idade superior a 3 anos ou que 10% da margem consignável de 60% dos Usuários pertencentes a empresa X está comprometida. Nós
                            poderemos usar comercialmente os Dados Gerais para a promoção, ainda que indireta do Tanamao, bem como para auxiliar estudos,
                            pesquisas e reportagens.
                        </p>
                        <p>
                            <b>(iv) Desenvolvimento de novas formas de promoção ou serviços.</b> O Tanamao poderá usar os Dados Gerais (inclusive, com
                            verificação dos Usuários que integram cada categoria/estatística) preservando a honra, intimidade, vida privada, imagem e sigilo,
                            para identificar necessidades específicas e benéficas aos Usuários integrantes de cada categoria/estatística, com objetivo de
                            fornecer e/ou desenvolver produtos e serviços voltados para subsidiar o ciclo de crédito, análise de risco, prevenção à fraude,
                            entre outros relacionados, como alcançar resultados estáticos com a finalidade de favorecer os Usuários.
                        </p>
                        <p>
                            <b>(d) Desenvolvimento de novas formas de promoção ou serviços.</b> O Tanamao poderá usar os Dados Gerais (inclusive, com
                            verificação dos Usuários que integram cada categoria/estatística) preservando a honra, intimidade, vida privada, imagem e sigilo,
                            para identificar necessidades específicas e benéficas aos Usuários integrantes de cada categoria/estatística, com objetivo de
                            fornecer e/ou desenvolver produtos e serviços voltados para subsidiar o ciclo de crédito, análise de risco, prevenção à fraude,
                            entre outros relacionados, como alcançar resultados estáticos com a finalidade de favorecer os Usuários.
                        </p>
                        <p>
                            <b>(e) Processamento de dados para instituições bancárias.</b> O Tanamao poderá processar os seus Dados Financeiros, de forma
                            automatizada e codificada, com o uso do Software, a fim de organizar e estruturar esses dados de acordo com certas categorias e
                            padrões de despesas e recebimentos monetários. Os seus Dados Financeiros, processados e estruturados, poderão ser retransmitidos,
                            com fim comercial, pelo Tanamao, a sua instituição bancária de origem. Notem que nunca iremos transmitir Dados Financeiros a uma
                            instituição bancária que não tenha sido a fonte original dos mesmos (por exemplo, os seus Dados Financeiros foram retirados do banco
                            X e do banco Y, os Dados Financeiros processados do banco X, somente serão transmitidos para o banco X e os do banco Y, somente ao
                            banco Y).
                        </p>
                        <p>
                            <b>(f) Transmissão de dados para terceiros, mediante autorização do Usuário.</b> O Tanamao poderá transmitir a terceiros Dados
                            Pessoais, Dados de Folha de Pagamento, Dados de Conta ou Dados Financeiros, mas, para tanto, iremos solicitar a prévia autorização
                            dos Usuários. Por exemplo, se em algum momento poderemos disponibilizar aos Usuários a escolha de autorizar a transmissão de seus
                            Dados Financeiros a uma instituição bancária X, para viabilizar a concessão de crédito por essa. Vale frisar, esse tipo de
                            transferência de dados será condicionada a autorização expressa (por opt in) dos Usuários, isso nunca ocorrerá sem o seu
                            consentimento prévio e informado.
                        </p>
                        <p>
                            <b>(g) Como o Tanamao usa dados de Usuários ao fazer anúncios publicitários.</b> Conforme já explicamos, o Tanamao poderá, de forma
                            automatizada e codificada, agrupar os Usuários de acordo com categorias e critérios a fim de criar um grupo perfil de Usuários
                            (“Grupo”). Os Dados Pessoais e os Dados Financeiros de quem integra cada Grupo não serão informados ou transmitidos a ninguém (estes
                            dados serão apenas guardados de forma codificada nos nossos servidores). Nossos anunciantes somente irão saber, por exemplo, que
                            existe um Grupo anônimo de 5.000 usuários do Tanamao que possuem até 25% de seu orçamento comprometido.
                        </p>
                        <p style={{ marginLeft: 20 }}>
                            <b>(i) Que tipo de anúncio publicitário o Tanamao irá fazer?</b> Poderemos fazer anúncios publicitários por meio do seu e-mail ou no
                            próprio Site/Aplicativo. Queremos que cada Usuário receba anúncios que sejam do seu interesse e possam eventualmente auxiliá-lo no
                            seu caminho a organização financeira, por isso oferecemos aos nossos parceiros escolher o seu público alvo (o seu Grupo). Pode ser
                            que o critério para determinar o Grupo seja por região, tipo e forma de gasto, habilidade de manter um orçamento mensal, entre
                            outros. Nossos anunciantes podem eventualmente usar cookies (entre outras tecnologias do tipo) a fim de oferecer, medir anúncios e
                            tornar seus anúncios mais eficazes. Para entender melhor como isso poderá ocorrer, leia o item Cookies.
                        </p>
                        <p style={{ marginLeft: 20 }}>
                            <b>(ii) O Tanamao irá transmitir quaisquer dados aos parceiros?</b> NÃO. Qualquer anúncio publicitário, seja o mesmo direcionado ao
                            seu e-mail ou inserido no Site/Aplicativo, será veiculado pelo Tanamao e não pelo anunciante. Os parceiros nos fornecerão as peças
                            publicitárias e nós iremos direcioná-las aos Usuários, logo esses não terão acesso aos seus dados. Nós poderemos, eventualmente,
                            fornecer informações aos parceiros se houver consentimento do usuário ou depois de remover os seus Dados Pessoais ou até mesmo
                            depois de agregar vários dados que não permitam ao anunciante individualizar o Usuário.
                        </p>
                        <p style={{ marginLeft: 20 }}>
                            <b>(iii) Os parceiros poderão ter acesso aos meus dados em alguma hipótese?</b> O Tanamao nunca irá transmitir os seus dados aos
                            parceiros sem a sua autorização, MAS lembre-se que caso você clique em um banner publicitário ou de qualquer forma aceite ser
                            direcionado ao site do anunciante, você não estará mais sujeito a Política de Privacidade do Tanamao.
                        </p>
                    </TermsSection>
                    <TermsSection title="4. COMO ARMAZENAMOS AS INFORMAÇÕES COLETADAS.">
                        <p>
                            Consideramos a segurança de todos os dados disponibilizados por nossos Usuários nossa prioridade. Apesar de qualquer sistema de
                            proteção estar sujeito a defeitos e possíveis violações que levem ao vazamento de dados, buscamos sempre evitar que isso ocorra ao
                            adotar um nível de proteção alto, com a mesma tecnologia de codificação dos grandes bancos e com certificados de empresas
                            especializadas em segurança.
                        </p>
                        <p>
                            Todos os Dados dos Usuários são criptografados. As informações são protegidas com a tecnologia SSL (Secure Socket Layer), garantindo
                            que todos os Dados Pessoais, Dados de Conta, Dados de Folha de Pagamento e Dados Financeiros do Usuário jamais sejam divulgados.
                            Além disso, essa tecnologia visa impedir que as informações sejam transmitidas ou acessadas por terceiros.
                        </p>
                        <p>
                            Por todo o tempo em que o Usuário mantiver a sua conta ativa, todas as informações coletadas serão armazenadas com alto padrão de
                            segurança em servidores próprios ou em servidores operados e controlados exclusivamente pelo Tanamao, localizados no Brasil ou no
                            exterior.
                        </p>
                        <p>
                            O Tanamao é responsável pela administração dos Dados Pessoais, Dados de Conta, Dados de Folha de Pagamento e Dados Financeiros,
                            envolvendo-se nas atividades de recepção, arquivamento e disponibilização das informações de interesse econômico nele(s)
                            armazenada(s).
                        </p>
                    </TermsSection>
                    <TermsSection title="5. COMPARTILHAMENTO DE DADOS COM TERCEIROS">
                        <p>
                            Em adição as hipóteses que já mencionamos no item 3.1 acima, os seus dados poderão também ser compartilhados com terceiros, nas
                            seguintes hipóteses:
                        </p>
                        <p>
                            <b>(a) Novos Negócios.</b> Processos de aquisição, venda, fusão, reorganização societária ou qualquer outra mudança de controle do
                            Tanamao. Nesse caso, o Tanamao irá garantir a continuidade da proteção de seus Dados Pessoais, Dados Financeiros, Dados de Folha de
                            Pagamento e Dados de Conta e irá comunicar o Usuário, com antecedência, caso essa transferência implique qualquer alteração em sua
                            Política de Privacidade.
                        </p>
                        <p>
                            <b>(b) Requisição Judicial.</b> O Tanamao pode compartilhar dados pessoais em caso de requisição judicial.
                        </p>
                        <p>
                            <b>(c) Com a autorização do Usuário.</b> Em demais casos, havendo a necessidade de compartilhamento das informações, enviaremos ao
                            Usuário uma notificação solicitando sua aprovação ou reprovação.
                        </p>
                        <p>
                            Qualquer Parceiro em potencial somente poderá receber as informações após a assinatura de um contrato de divulgação confidencial
                            (CDA – confidencial disclosure agreement) prevendo que os dados compartilhados serão utilizados única e exclusivamente para os fins
                            da concretização da parceria, sendo destruídos caso a mesma não se concretize. O Tanamao se compromete em não divulgar nenhum Dado
                            Financeiro sem a devida anonimização e autorização do Usuário.
                        </p>
                    </TermsSection>
                    <TermsSection title="6. COOKIES">
                        <p>
                            Nós usamos cookies e tecnologias similares, como pixels e tags, para certificar-se de que os serviços prestados pelo Tanamao estão
                            de acordo com o melhor padrão esperado pelo Usuário. Os cookies recolhidos pelo Tanamao nos fornecem somente estatísticas e não
                            serão utilizados para propósitos diversos dos expressamente previstos nesta Política de Privacidade e nos Termos de Uso.
                        </p>
                        <p>
                            <b>(a) O que é cookie?</b> Cookie é um pequeno arquivo adicionado ao seu aparelho ou computador para fornecer uma experiência
                            personalizada de acesso ao Tanamao.
                        </p>
                        <p>
                            <b>(b) Como o Tanamao faz a coleta de cookies?</b> O Tanamao utiliza empresas especializadas em veiculação de propagandas, dentro ou
                            fora do Tanamao, como, por exemplo, Google e Facebook. O Tanamao entende que ao aceitar esta Política de Privacidade você leu e
                            concorda com as políticas de privacidade para a utilização dos cookies das empresas de análise.
                        </p>
                        <p>
                            <b>(c) Que tipos de cookies o Tanamao utiliza?</b> O Tanamao permite a coleta de dois tipos de cookies: salvo e temporário.
                        </p>
                        <p>
                            Um cookie salvo é aquele que é introduzido no seu terminal de acesso (ex.: computador, tablet, etc.) quando você entra na sua Conta
                            Tanamao. Este cookie serve para armazenar informações, como nome e senha, de maneira que o Usuário não tenha que se conectar sempre
                            que entrar no Tanamao. Um cookie temporário é aquele que é usado para identificar uma visita específica ao site do Tanamao. Estes
                            cookies são removidos do terminal de acesso (ex.: computador, tablet, etc.) do Usuário assim que este finaliza a utilização do
                            navegador e são utilizados para armazenar informações temporárias.
                        </p>
                        <p>
                            <b>(d) Para que os cookies são utilizados?</b> O Tanamao utiliza cookies para vários fins, incluindo: - Ações de marketing para
                            remarketing: este recurso nos permite atingir os Usuários do Site para lembrá-los de efetuarem o cadastro no Tanamao ou para que
                            voltem a acessar a plataforma com facilidade. - Entender o comportamento de uso do site e do aplicativo para melhor desenvolvimento
                            do produto.
                        </p>
                        <p>
                            <b>(d) É possível limitar a coleta de cookies?</b> Os navegadores em geral permitem que seja desabilitada a coleta de cookies, desta
                            forma, caso você não altere as políticas de coleta de cookie do seu navegador nós iremos considerar que você concorda com a coleta
                            de cookies pelo site. Infelizmente, o Tanamao poderá não funcionar da forma desejada caso seja desabilitada a coleta de cookies.
                        </p>
                        <p>
                            <b>(d) Cookies e Informações de Conta.</b> Nenhum Dado de Conta, Dado Pessoal, Dado de Folha de Pagamento ou Dado Financeiro poderá
                            ser coletado por meio de cookies e tecnologias similares, como pixels e tags.
                        </p>
                    </TermsSection>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                <br />
            </div>
        </div>
    )
}
