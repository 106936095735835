import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IParametroConvenio } from "models/parametrosConvenio"

export default generateGenericMock(endpoints.PARAMETROS_CONVENIO, (index: string) => generateParametroConvenioMockData(index))

export function generateParametroConvenioMockData(index: string, model?: Partial<IParametroConvenio>): IParametroConvenio {
    const tipoValor: any = faker.helpers.randomize(["boolean", "string", "decimal", "integer"])
    let valor: any = ''

    switch (tipoValor) {
        case 'boolean':
            valor = faker.random.boolean()
            break;
        case 'decimal':
            valor = faker.random.number({ min: 111, max: 9999, precision: 2 })
            break;
        case 'integer':
            valor = faker.random.number({ min: 1, max: 999 })
            break;
    }

    return {
        id: index || "0",
        atualizado_em: faker.date.past().toISOString(),
        criado_em: faker.date.past().toISOString(),
        ativo: faker.random.boolean(),
        tipo: "Consignante",
        valor,
        codigo: faker.internet.domainName().toUpperCase(),
        descricao: faker.random.words(),
        comentario: faker.helpers.randomize([faker.random.words(), "", "", ""]),
        tipoValor
    }
}
