import React, { Suspense, lazy, useEffect, useState } from "react"
import { Route, Switch, RouteComponentProps, withRouter, Redirect } from "react-router-dom"
// import { useChatStore, useActions } from "store"
// import "@trendmicro/react-radio/dist/react-radio.css"
// import Login from "./admin/pages/auth/Login"
// import { Container } from './styles';
import mock from "mock"
import { initializeIcons } from "@uifabric/icons"
import { initRequest } from "lib/request"
import config from "config"
import Auth from "./auth"

// import "tippy.js/dist/tippy.css"
// import "tippy.js/animations/shift-away.css"
import { useActions, useStore, useHost } from "hooks"
import AppSpinner from "components/ui/AppSpinner"
import { Spin } from "antd"
import Privacy from "./landing/pages/Privacy"
import PrivacyGestao from "./landing/pages/PrivacyGestao"

const Bank = lazy(() => import("./bank"))
// const Chat = lazy(() => import("./chat"))
const Admin = lazy(() => import("./admin"))

function Products({ location, history, ...props }: RouteComponentProps) {
    const _isProduction = process.env.REACT_APP_CUSTOM_NODE_ENV === "production"

    // const user = useChatStore((state) => state.session.user)
    // const setUser = useActions((action) => action.session.setUser)
    const [retrieved, setRetrieved] = useState(false)
    // const [retrievedApps, setRetrievedApps] = useState(false)
    const { setUser, restoreSession, logout } = useActions((actions) => actions.session)
    const { retrieveLocalPreferences } = useActions((actions) => actions.app)
    // const { fetchAll: fetchApps } = useActions((actions) => actions.marketplace)
    const { user } = useStore((state) => state.session)
    // const { all: apps } = useStore((state) => state.marketplace)
    // const { subdomain } = useHost()

    useEffect(() => {
        const axiosInstance = initRequest({
            defaultEnv: _isProduction ? config.envs.find((env) => env.key === "production")! : config.envs[0],
            mock,
            renewTokenEndpoint: "",
            isProduction: _isProduction,
            onRefreshedToken: (tokenRefreshResponseData: any) => {
                let actualWorkspace = JSON.parse(localStorage.getItem("workspace")!)
                let workspace = tokenRefreshResponseData.workspaces.find((x: any) => x.workspaceId == actualWorkspace.workspaceId)
                setUser(tokenRefreshResponseData)
                // saveUser(tokenRefreshResponseData)
                // saveWorkspace(workspace)
                return [workspace.token, workspace.renewalToken]
            },
        })
        // axiosInstance.interceptors.request.use((request: any) => {
        //     return request
        // })
        axiosInstance.interceptors.response.use(
            (response: any) => {
                return response
            },
            (error: any) => {
                // const errorConfig = error.config
                // if (errorConfig) {
                //     removeLoader(
                //         errorConfig.method + errorConfig.url.substring(errorConfig.baseURL.length)
                //     )
                // }
                if (error.response && error.response.status === 401) {
                    // history.push("/user/logout")
                    console.log(error)
                    logout()
                }
                return Promise.reject(error)
            }
        )
        // recoverDomain()
        restoreSession()
        retrieveLocalPreferences()
        setRetrieved(true)
        initializeIcons()
    }, [])

    // check logic for authentication redirects
    useEffect(() => {
        if (retrieved) {
            const isAuthInPath = location.pathname.includes("/user/")
            if (!user && !isAuthInPath) {
                history.push("/user/login")
            } else if (user && isAuthInPath && !location.pathname.includes("/user/join-workspace")) {
                history.replace("/")
            }
        }
    }, [history, location.pathname, retrieved, user])

    // baixar os apps antes de mostrar página
    // useEffect(() => {
    //     if (retrieved && user && !retrievedApps) {
    //         fetchApps({}).then(() => {
    //             setRetrievedApps(true)
    //         })
    //     }
    // }, [retrieved, user])

    return (
        // <AppSpinner />
        // <Spin spinning />

        <Suspense fallback={<AppSpinner />}>
            {retrieved && (
                <Switch>
                    <Route path="/user/privacy/gestao" component={PrivacyGestao} />
                    <Route path="/user/privacy" component={Privacy} />
                    <Route path="/admin" component={Admin} />
                    <Route path="/user" component={Auth} />
                    <Route path="/" component={Bank} />
                    {/* {subdomain === "admin" && <Route path="/system" component={System} />} */}
                </Switch>
            )}
        </Suspense>
    )
}

export default withRouter(Products)
