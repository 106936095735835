import React, { useState, useEffect } from "react"
import { useParams, RouteComponentProps, Prompt } from "react-router-dom"
import {
    IStorePath,
    useFetchOneFromParamId,
} from "hooks/useFetchOneFromParamId"
import { useActions, useStore } from "hooks"
import { Form, message, Drawer, Button, Spin, Modal } from "antd"
import { removeLastPath } from "lib/helper"
import { FormInstance, FormProps } from "antd/lib/form"
import Icon from "../Icon"
// import Modal, { ModalTransition } from "@atlaskit/modal-dialog"

// import styles from './style.module.css';

type ISubmitBeforeConfig = {
    setSaving?: Function
}

export interface IFormModalProps<T> {
    children: React.ReactNode
    title?: string
    form: FormInstance
    onSubmit?: (model: Partial<T>) => Promise<any>
    onSubmitBefore?: (
        model: T,
        onSubmit: Function,
        config?: ISubmitBeforeConfig
    ) => void
    type?: "drawer" | "modal"
    titleOverwrite?: string | boolean
    okText?: string | boolean
    successMessage?: string | boolean
    contentClassname?: string
    defaultValues?: Partial<T>
    formProps?: FormProps
    width?: number
    onClose?: Function
    hideFooterWhenSaved?: boolean
    item?: T
    isEditing?: boolean
    saving?: boolean
    isFetching?: boolean
    okProps?: any
}

function FormModal<T extends Object>(props: IFormModalProps<T>) {
    const [saved, setSaved] = useState(true)
    const [visible, setVisible] = useState(true)
    const [loading, setLoading] = useState(false)
    const [drawerOpened, setDrawerOpened] = useState(false)
    // let { id } = useParams<any>()
    // let { save } = useActions(props.storePath)
    // const { loading } = useStore(props.storePath as any)
    // const item: T = useFetchOneFromParamId(props.storePath, props.item)
    const id = (props.item as any)?.id
    const isEditing = props.isEditing || (props.item as any)?.id
    // const isFetching = (id && isEditing && !props.item) || loading.one

    // useEffect(() => {
    //     if (props.item) {
    //         props.form.resetFields()
    //     }
    // }, [props.item])

    const onClose = (forcedBack?: boolean) => {
        if (drawerOpened || props.type === "modal") {
            if (!forcedBack && !saved) {
                goBack()
            } else {
                setVisible(false)
            }
        }
    }

    const goBack = () => {
        props.onClose?.()
    }

    const onSubmit = async (model: any) => {
        setSaved(true)
        setLoading(true)
        props.onSubmit?.(model)?.finally?.(() => {
            setSaved(false)
        })
    }

    const content = (
        <>
            <Prompt
                when={!saved}
                message="Você tem mudanças não salvas, tem certeza que deseja fechar?"
            />
            <Spin spinning={!!props.isFetching}>
                <div className={props.contentClassname}>
                    <Form
                        initialValues={props.item || props.defaultValues}
                        layout="vertical"
                        onFinish={(model) =>
                            props.onSubmitBefore
                                ? props.onSubmitBefore(model as T, onSubmit)
                                : onSubmit(model)
                        }
                        form={props.form}
                        hideRequiredMark
                        {...props.formProps}
                        onValuesChange={(changedValues, allValues) => {
                            setSaved(false)
                            props.formProps?.onValuesChange?.(
                                changedValues,
                                allValues
                            )
                        }}
                    >
                        {props.children}
                    </Form>
                </div>
            </Spin>
        </>
    )

    const footer = (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: props.type === "modal" ? "2px 8px" : "2px 24px",
            }}
        >
            <Button
                onClick={() => props.onClose?.()}
                style={{ marginRight: 8, marginLeft: 15 }}
                // className="normal"
            >
                Cancelar
            </Button>
            <Button
                type="primary"
                htmlType="submit"
                onClick={props.form.submit}
                loading={loading || props.saving}
                style={{ width: 90 }}
                {...props.okProps}
            >
                {props.okText || (!isEditing ? "Criar" : "Salvar")}
            </Button>
        </div>
    )

    if (props.type === "drawer") {
        return (
            <Drawer
                title={
                    props.titleOverwrite ||
                    `${
                        !isEditing ? "Criar" : "Editar"
                    } ${props.title?.toLocaleLowerCase()}`
                }
                width={props.width || 620}
                onClose={() => onClose()}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                afterVisibleChange={() => {
                    if (!drawerOpened) {
                        setDrawerOpened(true)
                    } else {
                        !visible && goBack()
                    }
                }}
                maskClosable
                footer={(!props.hideFooterWhenSaved || !saved) && footer}
                closeIcon={<Icon name="far fa-times" />}
                forceRender
                // className="trello-theme"
            >
                {content}
            </Drawer>
        )
    }

    // const actions = [
    //     { text: "Close", onClick: () => goBack() },
    //     { text: "Secondary Action", onClick: () => goBack() },
    // ]

    // return (
    //     <ModalTransition>
    //         {visible && (
    //             <Modal actions={actions as any} onClose={() => goBack()} heading="Modal Title">
    //                 {content}
    //             </Modal>
    //         )}
    //     </ModalTransition>
    // )
    return (
        <Modal
            title={
                props.titleOverwrite ||
                `${
                    !isEditing ? "Criar" : "Editar"
                } ${props.title?.toLocaleLowerCase()}`
            }
            onCancel={() => props.onClose?.()}
            visible={visible}
            maskClosable
            footer={footer}
            closeIcon={<Icon name="far fa-times" />}
        >
            {content}
        </Modal>
    )
}

export default FormModal
