import { IEnv } from "lib/request"

export const envs: IEnv[] = [
    { url: "mock", key: "mock" },
    { url: "mock-error", key: "mock-error" },
    // { url: "https://api.tanamao-sandbox.online/v1", key: "staging" },
    // { url: "https://tanamao-api-staging-tsdcf.ondigitalocean.app/v1", key: "staging" },
    { url: "https://tanamao-staging-upupa.ondigitalocean.app/v1", key: "staging" },
    { url: "https://tanamao-api-xwcjv.ondigitalocean.app/v1", key: "production" },
    { url: "http://localhost:3000/v1", key: "localhost" },
]
