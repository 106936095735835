import views_mock from "./views.mock"
import { views_count_mock } from "./views.mock"
import contracts_mock from "./contracts.mock"
import agents_mock from "./agents.mock"
import users_mock from "../users.mock"
import groups_mock from "./groups.mock"
import tags_mock from "./tags.mock"
import businessHours_mock from "./businessHours.mock"
import responses_mock from "./responses.mock"
import customFields_mock from "./customFields.mock"
import bots_mock from "./bots.mock"
import macros_mock from "./macros.mock"
import automations_mock from "./automations.mock"
import slas_mock from "./slas.mock"
import preferences from "./preferences.mock"
import channels, { get_qrcode, restart_server_zap } from "./channels.mock"
import contacts, { contacts_timeline_mock, export_contacts } from "./contacts.mock"
import organizations from "./organizations.mock"
import tickets, { tickets_export_mock, tickets_events_mock, tickets_send_message_mock, tickets_execute_macro_mock } from "./tickets.mock"
import conversations, { conversation_events_mock } from "./conversations.mock"
import search from "./search.mock"
import events from "./events.mock"
import files from "./files.mock"
import marketplace, { install_app_mock, uninstall_app_mock } from "./marketplace.mock"

// Reports
import reportConversations from "./reportConversations.mock"
import reportConversationsAgents from "./reportConversationsAgents.mock"
import reportConversationsGroups from "./reportConversationsGroups.mock"
import reportTickets from "./reportTickets.mock"
import reportTicketsAgents from "./reportTicketsAgents.mock"
import reportTicketsGroups from "./reportTicketsGroups.mock"


export default [
    ...views_mock,
    ...users_mock,
    ...views_count_mock,
    ...contracts_mock,
    ...bots_mock,
    ...agents_mock,
    ...groups_mock,
    ...tags_mock,
    ...businessHours_mock,
    ...responses_mock,
    ...customFields_mock,
    ...macros_mock,
    ...automations_mock,
    ...slas_mock,
    ...preferences,
    ...channels,
    ...get_qrcode,
    ...restart_server_zap,
    ...contacts,
    ...export_contacts,
    ...organizations,
    ...tickets_export_mock,
    ...contacts_timeline_mock,
    ...tickets_events_mock,
    ...tickets_send_message_mock,
    ...tickets_execute_macro_mock,
    ...tickets,
    ...events,
    ...conversations,
    ...conversation_events_mock,
    ...search,
    ...marketplace,
    ...install_app_mock,
    ...uninstall_app_mock,
    ...files,

    // Reports
    ...reportConversations,
    ...reportConversationsAgents,
    ...reportConversationsGroups,
    ...reportTickets,
    ...reportTicketsAgents,
    ...reportTicketsGroups
]