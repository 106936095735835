import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useBreadcrumb, useStore, useActions, useHost, useGenericStore } from "hooks"
// import { UserOutlined, LockOutlined } from "@ant-design/icons"

import styles from "./style.module.css"
import { Row, Col, Form, Input, Button, Select, Alert, Radio, notification, Avatar } from "antd"
import AuthPage from "../AuthPage"
// import Icon from "components/common/Icon"
import { MaskedInput } from "antd-mask-input"
import masks from "lib/masks"
import { IUser } from "models/users"
import { CompanyType, Gender, MaritalStatus, PersonRoleType } from "lib/constants"
import { IRegisterModel, registerAtom } from "atoms/registerAtom"
import Uploader from "components/form/Uploader"
import cx from "classnames"
import ImageUploader from "components/form/ImageUploader"
import { useRecoilState } from "recoil"
import { paymentAtom } from "atoms/payment"
import toast from "lib/toast"

function RegisterCNPJ() {
    useBreadcrumb({ items: [{ title: "Solicitação de acesso" }] })
    const [loading, setLoading] = useState(false)
    const [registerModel, setRegisterModel] = useRecoilState(registerAtom)

    const { uploadAssets } = useActions((actions) => actions.app)

    const history = useHistory()

    async function onSubmit(model) {
        if (model.personRoleType === PersonRoleType.Assignee + "" && !model.procuracao?.[0]?.preview) {
            toast.error("Necessário subir o arquivo de procuração")
            return
        }

        if (model.cnpj_card?.[0]?.preview && model.social_contract?.[0]?.preview && model.annual_billing?.[0]?.preview && model.proof_residence?.[0]?.preview) {
            setLoading(true)
            let { data } = await uploadAssets(model.cnpj_card)
            setLoading(false)
            if (!data?.[0].fileName) {
                toast.error("Erro ao subir arquivo")
                throw new Error("Error uploading file")
            }
            model.cnpj_card = data?.[0].fileName

            let result2 = await uploadAssets(model.social_contract)
            if (!result2.data?.[0].fileName) {
                toast.error("Erro ao subir arquivo")
                throw new Error("Error uploading file")
            }
            model.social_contract = result2.data?.[0].fileName

            let result3 = await uploadAssets(model.annual_billing)
            if (!result3.data?.[0].fileName) {
                toast.error("Erro ao subir arquivo")
                throw new Error("Error uploading file")
            }
            model.annual_billing = result3.data?.[0].fileName

            let result4 = await uploadAssets(model.proof_residence)
            if (!result4.data?.[0].fileName) {
                toast.error("Erro ao subir arquivo")
                throw new Error("Error uploading file")
            }
            model.proof_residence = result4.data?.[0].fileName

            setRegisterModel((p) => ({
                ...p,
                ...model,
            }))
            history.push("/user/register-cnpj-continuation")
        } else {
            toast.error("Necessário subir os arquivos de cartão cnpj e contrato social")
        }
    }

    return (
        <AuthPage title="Cadastro CNPJ" maxWidth={990}>
            <Form name="normal_login" onFinish={onSubmit as any} layout="vertical">
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="CNPJ"
                            name="cnpj"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <MaskedInput mask="11.111.111/1111-11" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Tipo de empresa"
                            name="companyType"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value={CompanyType.MEI}>MEI</Select.Option>
                                <Select.Option value={CompanyType.LTDA}>LTDA</Select.Option>
                                <Select.Option value={CompanyType.SA}>SA</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Atividade da sua empresa"
                            name="companyActivity"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Data de constituição da empresa"
                            name="constitutionDate"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <MaskedInput mask="11/11/1111" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Qual seu papel na empresa?"
                            name="personRoleType"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value={PersonRoleType.AccountHolder + ""}>Titular da conta</Select.Option>
                                <Select.Option value={PersonRoleType.Assignee + ""}>Procurador</Select.Option>
                                <Select.Option value={PersonRoleType.Holder + ""}>Portador</Select.Option>
                                <Select.Option value={PersonRoleType.LegalRepresentative + ""}>Representante legal</Select.Option>
                                <Select.Option value={PersonRoleType.Partner + ""}>Sócio</Select.Option>
                                <Select.Option value={PersonRoleType.User + ""}>Usuário</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}></Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item shouldUpdate>
                            {({ getFieldValue }) => {
                                const image = getFieldValue("cnpj_card")
                                return (
                                    <Form.Item
                                        name={"cnpj_card"}
                                        label="Cartão CNPJ"
                                        // valuePropName="url"
                                        // getValueFromEvent={(e) => {
                                        //     return e?.file?.response?.url
                                        // }}
                                    >
                                        <Uploader
                                            className={cx(styles.avatar_uploader)}
                                            dropOptions={{
                                                accept: {
                                                    "image/png": [".png", ".jpeg", ".jpg"],
                                                    "image/jpeg": [".png", ".jpeg", ".jpg"],
                                                    "application/pdf": [".pdf"],
                                                },
                                                multiple: false,
                                            }}
                                            showUploadButton
                                        >
                                            <img src={image?.[0].preview} style={{ width: 100, height: 100, objectFit: "cover", borderRadius: 12 }} alt="" />
                                        </Uploader>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item shouldUpdate>
                            {({ getFieldValue }) => {
                                const image = getFieldValue("social_contract")

                                // console.log("image", image)
                                return (
                                    <Form.Item
                                        name={"social_contract"}
                                        label="Contrato social"
                                        // valuePropName="url"
                                        // getValueFromEvent={(e) => {
                                        //     return e?.file?.response?.url
                                        // }}
                                    >
                                        <Uploader
                                            buttonLabel="Escolha arquivo"
                                            className={cx(styles.avatar_uploader)}
                                            dropOptions={{ multiple: false }}
                                            showUploadButton
                                        >
                                            {image?.[0] ? (
                                                <i
                                                    className="fal fa-check"
                                                    style={{
                                                        fontSize: 20,
                                                        padding: 10,
                                                        background: "#e6f5ec",
                                                        borderRadius: "50%",
                                                        width: 60,
                                                        height: 60,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        color: "#27ae60",
                                                        minWidth: 60,
                                                        border: "1px solid #27ae60",
                                                    }}
                                                ></i>
                                            ) : (
                                                <img
                                                    src={image?.[0].preview}
                                                    style={{ width: 100, height: 100, objectFit: "cover", borderRadius: 12 }}
                                                    alt=""
                                                />
                                            )}
                                        </Uploader>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item shouldUpdate>
                            {({ getFieldValue }) => {
                                const image = getFieldValue("proof_residence")

                                // console.log("image", image)
                                return (
                                    <Form.Item
                                        name={"proof_residence"}
                                        label="Comprovante de residencia"
                                        // valuePropName="url"
                                        // getValueFromEvent={(e) => {
                                        //     return e?.file?.response?.url
                                        // }}
                                    >
                                        <Uploader
                                            buttonLabel="Escolha arquivo"
                                            className={cx(styles.avatar_uploader)}
                                            dropOptions={{ multiple: false }}
                                            showUploadButton
                                        >
                                            {image?.[0] ? (
                                                <i
                                                    className="fal fa-check"
                                                    style={{
                                                        fontSize: 20,
                                                        padding: 10,
                                                        background: "#e6f5ec",
                                                        borderRadius: "50%",
                                                        width: 60,
                                                        height: 60,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        color: "#27ae60",
                                                        minWidth: 60,
                                                        border: "1px solid #27ae60",
                                                    }}
                                                ></i>
                                            ) : (
                                                <img
                                                    src={image?.[0].preview}
                                                    style={{ width: 100, height: 100, objectFit: "cover", borderRadius: 12 }}
                                                    alt=""
                                                />
                                            )}
                                        </Uploader>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item shouldUpdate>
                            {({ getFieldValue }) => {
                                const image = getFieldValue("annual_billing")

                                // console.log("image", image)
                                return (
                                    <Form.Item
                                        name={"annual_billing"}
                                        label="Faturamento Anual"
                                        // valuePropName="url"
                                        // getValueFromEvent={(e) => {
                                        //     return e?.file?.response?.url
                                        // }}
                                    >
                                        <Uploader
                                            buttonLabel="Escolha arquivo"
                                            className={cx(styles.avatar_uploader)}
                                            dropOptions={{ multiple: false }}
                                            showUploadButton
                                        >
                                            {image?.[0] ? (
                                                <i
                                                    className="fal fa-check"
                                                    style={{
                                                        fontSize: 20,
                                                        padding: 10,
                                                        background: "#e6f5ec",
                                                        borderRadius: "50%",
                                                        width: 60,
                                                        height: 60,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        color: "#27ae60",
                                                        minWidth: 60,
                                                        border: "1px solid #27ae60",
                                                    }}
                                                ></i>
                                            ) : (
                                                <img
                                                    src={image?.[0].preview}
                                                    style={{ width: 100, height: 100, objectFit: "cover", borderRadius: 12 }}
                                                    alt=""
                                                />
                                            )}
                                        </Uploader>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item shouldUpdate>
                            {({ getFieldValue }) => {
                                if (getFieldValue("personRoleType") !== PersonRoleType.Assignee + "") {
                                    return null
                                }

                                const image = getFieldValue("procuracao")
                                return (
                                    <Form.Item
                                        name={"procuracao"}
                                        label="Procuração"
                                        // valuePropName="url"
                                        // getValueFromEvent={(e) => {
                                        //     return e?.file?.response?.url
                                        // }}
                                    >
                                        <Uploader
                                            className={cx(styles.avatar_uploader)}
                                            dropOptions={{
                                                multiple: false,
                                            }}
                                            showUploadButton
                                        >
                                            <img src={image?.[0].preview} style={{ width: 100, height: 100, objectFit: "cover", borderRadius: 12 }} alt="" />
                                        </Uploader>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Row gutter={20} align="middle">
                        <Col>
                            <Link to="/user/login">
                                <Button>Voltar ao início</Button>
                            </Link>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                // className="login-form-button"
                                // style={{ minWidth: 160, display: "block" }}
                                // size="large"
                            >
                                Continuar
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </AuthPage>
    )
}

export default RegisterCNPJ
