import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { ITicket, ITicketEvent, ITicketModel, ISendMessage, ITicketEventMessage } from "./tickets"
import { Thunk, ActionOn, actionOn, ThunkOn, thunkOn, thunk, Action, action } from "easy-peasy"
import { IEcontractModel } from "."
import { IStoreModel } from "models"
import { IChannel } from "./channels"
import { IContact } from "./contacts"

export interface IConversation {
    id: string
    ticket: ITicket
    updatedAt: string
    createdAt: string
    channel: IChannel
    followerIds: string[]
}

export interface IConversationModel extends IGenericModel<IConversation> {
    // sendMessage: Thunk<IConversationModel, ISendMessage, any, IStoreModel>
    // executeMacro: Thunk<IConversationModel, { macroId: string, ticketId: string }>
    // excludeMessage: Thunk<IConversationModel, { eventId: string, ticketId: string }, any, IStoreModel>
    model: IConversation
    onTicketPatched: ThunkOn<IConversationModel, any, IStoreModel>
    viewingTicket?: ITicket
    setViewingTicket: Action<IConversationModel, ITicket>
}

export const conversations: IConversationModel = {
    ...generic(endpoints.bank.CONVERSATIONS),
    viewingTicket: undefined,
    setViewingTicket: action((state, ticket) => {
        state.viewingTicket = ticket
    }),
    // sendMessage: thunk(async (actions, payload, { getState, getStoreActions }) => {
    //     return getStoreActions().bank.tickets.sendMessage(payload).then((response: ITicketEventMessage) => {
    //         actions.patchModel({ events: [...(getState().model.events || []), response] })
    //     })
    // }),
    // excludeMessage: thunk(async (actions, payload, { getState, getStoreActions, getStoreState }) => {
    //     return getStoreActions().bank.tickets.excludeMessage(payload).then(() => {
    //         actions.patchModel({ events: getState().model.events?.filter(event => event.id !== payload.eventId) })
    //     })
    // }),
    onTicketPatched: thunkOn(
        (actions, storeActions) => {
            return storeActions.bank.tickets.patchElementOnAll
        },
        (actions, target, { getStoreState }) => {
            const conversation = getStoreState().bank.conversations.all.find(conversation => conversation.ticket.id === target.payload.id)
            if (conversation) {
                actions.patchElementOnAll({
                    id: conversation.id,
                    ticket: { ...conversation.ticket, ...target.payload }
                })
            }
            if (getStoreState().bank.conversations.model?.ticket.id === target.payload.id) {
                actions.patchModel({
                    ticket: { ...getStoreState().bank.conversations.model.ticket, ...target.payload }
                })
            }
        }
    )
}