import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

interface ISLATarget {
    firstResponseTime: number[]
    everyResponseTime: number[]
    resolutionTime: number[]
    operationalHours: "calendar-hours" | "business-hours"
    escalation: boolean
}

export interface ISLA {
    id: string
    updatedAt: string
    createdAt: string
    name: string
    description: string
    conditions: {
        all: {
            field: string
            operator: string
            value: string | number
        }[]
    }
    targets: {
        urgent: ISLATarget
        high: ISLATarget
        normal: ISLATarget
        low: ISLATarget
    }
    escalation: {
        agents: string[]
        hoursAfter: number
    }
    reminder: {
        hoursBefore: number
        agents: string[]
    }
    position: number
}

export const slas: IGenericModel<ISLA> = {
    ...generic(endpoints.bank.SLAS),
}
