import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"

export default generateGenericMock(endpoints.bank.TAGS, generateTagMockData)

export function generateTagMockData(index?: string) {
    return {
        id: index || "0",
        name: faker.random.words(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        color: "#3498db",
        // color: faker.helpers.randomize([
        //     "#3480EA",
        //     "#EBB62D",
        //     "#94FFA9",
        //     "#F2870D",
        //     "#F25226",
        //     "#6EC8F2",
        //     "#FFF280",
        //     "#E34F85",
        //     "#D3E7F3",
        //     "#77F3E9",
        //     "#9CE53E",
        //     "#9263E9",
        // ]),
        // textColor: faker.internet.color(),
        textColor: "#fff",
    }
}