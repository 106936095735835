import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { InputType } from "lib/constants"

export interface ICustomField {
    id: string
    updatedAt: string
    createdAt: string
    name: string
    description: string
    placeholder: string
    type: InputType
    entity: "contact" | "organization" | "ticket"
    options?: {
        value: string
        label: string
    }[]
}

export const customFields: IGenericModel<ICustomField> = {
    ...generic(endpoints.bank.CUSTOM_FIELDS)
}