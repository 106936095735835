import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IImportacao } from "models/importacoes"

export default generateGenericMock(endpoints.IMPORTACOES, generateImportacaoMockData)

export function generateImportacaoMockData(index: string, model?: Partial<IImportacao>): IImportacao {
    return {
        id: index || "0",
        criado_em: faker.date.recent(5).toISOString(),
        processado_em: faker.date.recent(5).toISOString(),
        data: {
            location: faker.internet.url(),
            name: faker.random.word(),
        },
        tipo: faker.helpers.randomize(["Pessoas", "Matricula", "Legado", "Contracheques"]),
        arquivo: faker.random.word(),
        status: faker.helpers.randomize(["Aguardando aprovação", "Cancelado", "Aguardando processamento", "Processado com sucesso", "Processado com erro"]),
        // situacao: faker.helpers.randomize(["Solicitado", "Em processamento", "Processado com sucesso", "Processado com erro"]),
        ocorrencias: [
            {
                tipo: "OK",
                descricao: "OK",
                count: 821
            },
            {
                tipo: "Erro",
                descricao: "Matricula não cadastrada no sistema.",
                count: 20
            },
            {
                tipo: "Erro",
                descricao: "CPF não cadastrado no sistema",
                count: 87
            },
        ]
    }
}