import React from "react"
import cx from 'classnames'
import { getIconClassName } from "@uifabric/styling"

type Icon = {
    name: string
    fabric?: boolean
    marginRight?: number
    marginLeft?: number
    style?: object
    className?: string
}

function Icon({ name, marginRight, marginLeft, style, fabric, className }: Icon) {
    if (fabric) {
        return (
            <i
                className={cx(getIconClassName(name), className)}
                style={{ marginRight, marginLeft, ...style }}
            />
        )
    }
    return <i className={cx(name, className)} style={{ marginRight, marginLeft, ...style }} />
}

export default Icon
