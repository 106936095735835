import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { ITaxa, ITaxaParcela } from "models/taxas"

export default generateGenericMock(endpoints.TAXAS, generateTaxaMockData)

function generateParcelaMockData(index: string, model?: Partial<ITaxaParcela>): ITaxaParcela {
    return {
        id: index || "0",
        criado_em: faker.date.past().toISOString(),
        atualizado_em: faker.date.past().toISOString(),
        numero: faker.random.number(),
        cet: faker.random.number(),
        juros: faker.random.number(),
        iof: faker.random.number(),
    }
}

export const taxasParcela = generateGenericMock(`${endpoints.TAXAS}/:consignatariaId/parcela`, generateParcelaMockData)

export function generateTaxaMockData(index: string, model?: Partial<ITaxa>): ITaxa {
    return {
        id: index || "0",
        nome: faker.random.words(),
        criado_em: faker.date.past().toISOString(),
        atualizado_em: faker.date.past().toISOString(),
        ativo: faker.random.boolean(),
        vigencia_de: faker.date.past().toISOString(),
        vigencia_ate: faker.date.past().toISOString(),
        parcelas: [generateParcelaMockData("0"), generateParcelaMockData("1")],
        ...model
    }
}