import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { INotification } from "models/notifications"
import { generateUserMockData } from "./users.mock"

export default generateGenericMock(endpoints.NOTIFICATIONS, generateNotificationMockData)

export function generateNotificationMockData(index: string, model?: Partial<INotification>) {
    return {
        id: index || "0",
        criado_em: faker.date.recent(5).toISOString(),
        remetente: generateUserMockData("0"),
        destinatario_tipo: faker.helpers.randomize(["cpfs"]),
        consignataria_id: "0",
        titulo: faker.random.words(),
        tipo: faker.helpers.randomize(["mensagem"]),
        cpfs: ["0369077027", "0607230363"],
    }
}
