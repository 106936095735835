import axios, { AxiosResponse, AxiosError, AxiosRequestConfig, AxiosInstance, AxiosPromise } from "axios"
import createAuthRefreshInterceptor from "./axios-auth-refresh"
import { toast } from "./toast"
import { IMock, IMockMethod } from "../mock"
import { message } from "antd"
import _ from "lodash"
import patchMatcher from "path-match"
import { IUserSession } from "models/session"

export interface IEnv {
    url: string
    key: "production" | "staging" | "dev" | "mock" | "mock-error" | string
}

export type IRequestMethods = "post" | "get" | "put" | "patch" | "delete" | string

var createRoute = require("path-match")({
    // path-to-regexp options
    sensitive: false,
    strict: true,
    end: true,
})

// Get Domain from LocalStorage
let axiosInstance: AxiosInstance
let _mock: IMock
let _renewTokenEndpoint: any
let _defaultEnv: IEnv
let _isProduction: boolean
let _subdomain: string

export const getDomain = () => (JSON.parse(localStorage.getItem("nanui-env")!) || {}).url || _defaultEnv.url

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any, onRefreshedToken: any) =>
    axiosInstance
        .post(_renewTokenEndpoint, {
            token: localStorage.getItem("nanui-token"),
            renewalToken: localStorage.getItem("nanui-token-renewal"),
        })
        .then((tokenRefreshResponse: AxiosResponse) => {
            // console.log('tokenRefreshResponse', tokenRefreshResponse);
            // localStorage.setItem('nanui-token', token);
            // localStorage.setItem('nanui-token-renewal', renewalToken);
            const [token] = onRefreshedToken(tokenRefreshResponse.data)
            failedRequest.response.config.headers.Authentication = token
            return Promise.resolve()
        })

// Logic to get from Mock
export async function getFromMock(domain: string, method: IRequestMethods, url: string, data: any) {
    return new Promise((resolve, reject) => {
        const state = domain === "mock" ? "success" : "error"
        const mockRoute = _mock.routes.find((route) => {
            const match = createRoute(route.endpoint)
            // console.log('match', match(url), url, route.endpoint, _mock.routes)
            return match(url.split("?")[0])
        })

        if (mockRoute) {
            setTimeout(() => {
                const mockMethod: IMockMethod = (mockRoute.method as any)[method]
                const dataResponse = state === "success" ? mockMethod.success.body?.(data) : mockMethod.error?.error
                const status = state === "success" ? mockMethod.success.statusCode : mockMethod.error?.statusCode
                // const dataResponse = ((route as any)?.body(data)) ?? (route as any).error
                console.log("mockMethod", mockMethod)
                const response = {
                    data: dataResponse,
                    config: {
                        data: JSON.stringify(data),
                        status: status,
                        method: method,
                        url,
                        baseURL: "",
                    },
                    status: status,
                }
                printRequest(response as any)
                if (state === "error") {
                    const errorMessage = (dataResponse as any)?.errors[0]?.descriptions?.[0]
                    if (errorMessage) {
                        toast.error(errorMessage)
                    } else {
                        toast.error("Rota de api não existente.")
                    }
                    reject(response.data)
                } else resolve(response)
            }, (mockRoute && mockRoute.latency) || 0)
        } else {
            toast.error("Rota de mock não existente.")
            console.error("Rota mock não existente", `[${method}]`, url, data)
            throw new Error("Rota de mock não existente")
        }
    })
}

// Print requests on devtools
function printRequest(response: AxiosResponse) {
    console.group(
        `%c[${response?.config?.method?.toUpperCase()}] ` + response?.config?.url?.substring(response?.config?.baseURL!.length),
        "color: #f1c40f; background-color: black"
    )
    console.log("Response: ", response)
    try {
        console.log("Enviado: ", (response.config.data && JSON.parse(response.config.data)) || response.config.params)
    } catch (e) {
        console.log("Enviado: ")
    }
    console.log("Recebido: ", response ? response.data : "Erro de conexão. Sem contato com servidor")
    console.groupEnd()
}

function getAccessToken() {
    // const perfil: IUserSession = JSON.parse(localStorage.getItem('perfil')!)
    return JSON.parse(localStorage.getItem("user")!)?.token
}

interface IInitRequest {
    defaultEnv: IEnv
    mock: IMock
    renewTokenEndpoint: string
    onRefreshedToken: Function
    isProduction: boolean
    // subdomain: string
}

/*
 * OnRefreshed: Callback to update token on localStorage: eg: () => localStorage.setItem('token', tokenRefreshResponse.data.token);
 * getAccessToken: Callback to always get updated token from localStorage; eg: () => ;
 */
export function initRequest(props: IInitRequest) {
    _mock = props.mock
    _renewTokenEndpoint = props.renewTokenEndpoint
    _defaultEnv = props.defaultEnv
    _isProduction = props.isProduction
    // _subdomain = props.subdomain

    // Create Axios Instance
    axiosInstance = axios.create({
        baseURL: getDomain(),
        headers: { "Content-Type": "application/json" },
    })

    // Create renewal of token logic
    createAuthRefreshInterceptor(axiosInstance, (failedRequest: any) => refreshAuthLogic(failedRequest, props.onRefreshedToken), {
        statusCodes: [403],
    })

    axiosInstance.interceptors.request.use((request) => {
        const token = getAccessToken()
        // request.headers["subdomain"] = _subdomain

        if (token) {
            request.headers["Authorization"] = token
        }
        // console.log('request', request)
        return request
    })

    // Handle Response
    axiosInstance.interceptors.response.use(
        (response: AxiosResponse) => {
            if (!_isProduction) {
                printRequest(response)
            }
            return response
        },
        (error: AxiosError) => {
            if (error?.response?.status === 404) {
                return Promise.reject(error)
            }
            if (error.response) {
                printRequest(error.response)
                try {
                    if (error.response.status !== 403) {
                        toast.error(error.response.data.errors[0].descriptions[0])
                    }
                } catch (e) {
                    toast.error("Erro desconhecido no servidor")
                }
                throw error
            } else if (error.request) {
                console.error(error, error.request)
                toast.error("Sem resposta do servidor")
                // printRequest({config: { method: '', }});
            } else {
                toast.error("Erro desconhecido")
                console.error(error)
            }
            return Promise.reject(error)
        }
    )

    return axiosInstance
}

/*
 * Returns .get, .post, .patch, .put, .post, .save, .axiosInstance
 */
function send(method: IRequestMethods, url: string, data: any, config?: AxiosRequestConfig): AxiosPromise<any> {
    // console.log('to:', getDomain(), url, JSON.parse(localStorage.getItem("nanui-env")!))
    const domain = getDomain()?.toLowerCase()
    if (domain === "mock" || domain === "mock-error") {
        return getFromMock(domain, method, url, data) as AxiosPromise
    }

    console.log("send", url, method, data)

    return axiosInstance({
        baseURL: getDomain(),
        url,
        method,
        data,
        params: method === "get" ? data : undefined,
        ...config,
    })
}

export const request = {
    // Axios API to send request
    save: async (endpoint: string, data: any, config?: AxiosRequestConfig) =>
        send(data.id ? ((config as any)?.saveWithPut ? "put" : "patch") : "post", endpoint, data, config),
    get: (url: string, data?: any, config?: AxiosRequestConfig) => send("get", url, data, config),
    post: (endpoint: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<any> => send("post", endpoint, data, config),
    put: (endpoint: string, data: any, config?: AxiosRequestConfig) => send("put", endpoint, data, config),
    patch: (endpoint: string, data: any, config?: AxiosRequestConfig) => send("patch", endpoint, data, config),
    delete: (endpoint: string, data?: any, config?: AxiosRequestConfig) => send("delete", endpoint, data, config),
    axiosInstance: axiosInstance!,
}
