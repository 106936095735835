import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateGroupMockData } from "./groups.mock"
import { IReportTicketsGroups } from "products/bank/pages/Reports/ReportTicketsGroup"

export default generateGenericMock(endpoints.bank.REPORT_TICKETS_GROUP_PERFORMANCE, genMockReportConversation)

export function genMockReportConversation(): IReportTicketsGroups {
    return {
        tme: faker.random.number({ max: 200 }),
        tma: faker.random.number({ max: 200 }),
        tmr: faker.random.number({ max: 200 }),
        new: faker.random.number({ max: 200 }),
        pending: faker.random.number({ max: 200 }),
        open: faker.random.number({ max: 200 }),
        closed: faker.random.number({ max: 200 }),
        resolved: faker.random.number({ max: 200 }),
        group: generateGroupMockData("0"),
    }
}