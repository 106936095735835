import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateAgentMockData } from "./agents.mock"
import { IReportTicketsAgents } from "products/bank/pages/Reports/ReportTicketsAgent"

export default generateGenericMock(endpoints.bank.REPORT_TICKETS_AGENT_PERFORMANCE, genMockReportConversation)

export function genMockReportConversation(): IReportTicketsAgents {
    return {
        tme: faker.random.number({ max: 200 }),
        tma: faker.random.number({ max: 200 }),
        tmr: faker.random.number({ max: 200 }),
        assigned: faker.random.number({ max: 200 }),
        pending: faker.random.number({ max: 200 }),
        open: faker.random.number({ max: 200 }),
        closed: faker.random.number({ max: 200 }),
        resolved: faker.random.number({ max: 200 }),
        agent: generateAgentMockData("0"),
        messagesSent:faker.random.number({ max: 200 })
    }
}