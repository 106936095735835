import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IFile } from "./files"

export type IImportacaoLayout = "Pessoas" | "Matricula" | "Legado" | "Contracheques"
// | "Cartão de crédito" | "Descontos" | "Arquivo retorno"
// export type IImportacaoSituacao = "Solicitado" | "Em processamento" | "Processado com sucesso" | "Processado com erro"
export type IImportacaoStatus = "Aguardando aprovação" | "Cancelado" | "Aguardando processamento" | "Processado com sucesso" | "Processado com erro"
export type IImportacaoOcorrencia = {
    tipo: "OK" | "Erro"
    descricao: string | "OK"
    count: number
}

export interface IImportacao {
    id?: string
    data: Partial<IFile>
    tipo: IImportacaoLayout
    criado_em: string

    // Após importado
    // situacao?: IImportacaoSituacao
    status?: IImportacaoStatus
    arquivo?: string
    processado_em?: string
    ocorrencias?: IImportacaoOcorrencia[]
}

export const importacoes: IGenericModel<IImportacao> = {
    ...generic(endpoints.IMPORTACOES)
}