import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateRandomFakeList } from "lib/helper"
import { actionsMock } from "./macros.mock"
import { IApp } from "models/marketplace"

export default generateGenericMock(endpoints.APPS, generateMarketplaceMockData, { max: 1 })
export const install_app_mock = generateGenericMock(endpoints.APPS + '/:id/install', () => ({}))
export const uninstall_app_mock = generateGenericMock(endpoints.APPS + '/:id/uninstall', () => ({}))

export function generateMarketplaceMockData(i: string): IApp {
    return {
        id: i,
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        name: faker.random.words(),
        description: faker.random.words(20),
        icon: "https://card-fields.trello.services/images/custom-fields-circular.svg",
        private: faker.random.boolean(),
        installed: faker.random.boolean(),
        developer: {
            name: faker.name.firstName() + faker.name.lastName(),
            site: faker.internet.url()
        },
        // endpoint: "http://localhost:8080",
        endpoint: "https://andrody.github.io/dino_example_app",
        secret: faker.random.uuid(),
        devMode: faker.random.boolean(),
        manifest: {
            "name": "dino-first-app",
            "version": 1,
            "private": true,
            "author": {
                "name": "Andrew Feitosa",
                "email": "andrewcsz@gmail.com"
            },
            "product": {
                "bank": {
                    "location": {
                        "ticket_sidebar": {
                            "url": "assets/iframe_ticket_sidebar.html",
                            "icon": "AppIconDefault"
                        },
                        "nav_bar": {
                            "url": "assets/iframe_nav_bar.html",
                            "icon": "fa fa-flame"
                        },
                        "top_bar": {
                            "url": "assets/iframe_top_bar.html",
                            "icon": "AppIconDefault"
                        },
                        "contact_sidebar": {
                            "url": "assets/iframe_contact_sidebar.html",
                            "icon": "AppIconDefault"
                        },
                        "organization_sidebar": {
                            "url": "assets/iframe_organization_sidebar.html",
                            "icon": "AppIconDefault"
                        },
                        "new_ticket_sidebar": {
                            "url": "assets/iframe_new_ticket_sidebar.html",
                            "icon": "AppIconDefault"
                        },
                        "background": {
                            "url": "assets/iframe_background.html",
                            "icon": "AppIconDefault"
                        }
                    },
                    "preferences": {
                        "brandColor": "#0764cb"
                    }
                }
            }
        }

    }
}