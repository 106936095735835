import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IProduto {
    id: string,
    nome: string,
    tipo_margem: string,
    tipo_produto: string,
    codigo: string,
    parcelas_determinadas: boolean,
    consignante_id: string,
    atualizado_em: string,
    criado_em: string,
    ativo: boolean
}

export const produtos: IGenericModel<IProduto> = {
    ...generic(endpoints.PRODUTOS)
}
