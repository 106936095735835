import { useEffect } from "react"
import { useStore, useActions } from "hooks"
import { Actions, State } from "easy-peasy"
import { IStoreModel } from "models"
import { IGenericModel } from "models/generic"

type IUseGenericStore = <Result>(
    mapState: (state: State<IStoreModel>) => Result,
    equalityFn?: (prev: Result, next: Result) => boolean,
  ) => Result

export const useGenericStore: IUseGenericStore = (statePath) => {
    const state = useStore(statePath)
    const actions = useActions(statePath as any)

    useEffect(() => {
        (actions as any)?.getAll()
    }, [])

    return state
}