import React, { useMemo } from "react"

import {
    Form,
    Space,
    Input,
    Button,
    Select,
    Row,
    Col,
    InputNumber,
    Checkbox,
    DatePicker,
} from "antd"
import Icon from "components/common/Icon"
import { InputType } from "lib/constants"
import { FormInstance } from "antd/lib/form"
import { Ticket } from "lib/constants"
import { useGenericStore, useStore, useActions } from "hooks"
import { IGroup } from "models/bank/groups"
import { IAgent } from "models/bank/agents"
import { getFullName } from "lib/helper"
import { ITag } from "models/bank/tags"
import { IResponse } from "models/bank/responses"
import { ICustomField } from "models/bank/customFields"
import locale from "antd/es/date-picker/locale/pt_BR"

export interface IInputType {
    type: string
    options?: {
        value: string
        label: string
    }[]
}

export interface IActionType {
    type: string
    label: string
    values: IInputType
}

export interface IActionTypeGroup {
    groupLabel: string
    options: IActionType[]
}

export function getValueInput(values: IInputType | ICustomField) {
    switch (values?.type) {
        case InputType.Number:
            return <InputNumber style={{ width: "100%" }} />
        case InputType.Decimal:
            return <InputNumber style={{ width: "100%" }} step={0.1} />
        case InputType.Boolean:
            return (
                <Select>
                    <Select.Option value={1}>Sim</Select.Option>
                    <Select.Option value={0}>Não</Select.Option>
                </Select>
            )
        case InputType.Date:
            return (
                <DatePicker
                    placeholder=""
                    locale={locale}
                    format="DD-MM-YYYY"
                    style={{ width: "100%" }}
                />
            )
        case InputType.Textarea:
            return <Input.TextArea rows={2} />
        case InputType.Select:
            return (
                <Select>
                    {values?.options!.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            )
        case InputType.MultiSelect:
            return (
                <Select mode="multiple">
                    {values?.options!.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            )
        case InputType.Text:
        default:
            return <Input />
    }
}

interface IActionsInputProps {
    form: FormInstance
    currentUserContext: boolean
}

function ActionsInput(props: IActionsInputProps) {
    const { all: groups } = useGenericStore((state) => state.bank.groups)
    const { all: agents } = useGenericStore((state) => state.bank.agents)
    const { all: tags } = useGenericStore((state) => state.bank.tags)
    const { all: responses } = useGenericStore((state) => state.bank.responses)
    const { all: customFields } = useGenericStore((state) => state.bank.customFields)

    const currentUserOption = props.currentUserContext
        ? [
              {
                  value: "current_user",
                  label: "(Agente logado)",
              },
          ]
        : []

    const actionsTypesGroup: IActionTypeGroup[] = useMemo(
        () => [
            {
                groupLabel: "Ticket",
                options: [
                    {
                        type: "set_subject",
                        label: "Mudar Assunto",
                        values: {
                            type: InputType.Text,
                        },
                    },
                    {
                        type: "set_status",
                        label: "Mudar Status",
                        values: {
                            type: InputType.Select,
                            options: Object.keys(Ticket.Status).map((key) => Ticket.Status[key]),
                        },
                    },
                    {
                        type: "set_priority",
                        label: "Mudar Prioridade",
                        values: {
                            type: InputType.Select,
                            options: Object.keys(Ticket.Priority).map(
                                (key) => Ticket.Priority[key]
                            ),
                        },
                    },
                    {
                        type: "set_type",
                        label: "Mudar Tipo",
                        values: {
                            type: InputType.Select,
                            options: Object.keys(Ticket.Type).map((key) => Ticket.Type[key]),
                        },
                    },
                    {
                        type: "set_group",
                        label: "Mover para Grupo",
                        values: {
                            type: InputType.Select,
                            options: groups.map((group) => ({
                                value: group.id,
                                label: group.name,
                            })),
                        },
                    },
                    {
                        type: "set_agent",
                        label: "Atribuir Agente",
                        values: {
                            type: InputType.Select,
                            options: [
                                ...currentUserOption,
                                ...agents.map((agent) => ({
                                    value: agent.id,
                                    label: getFullName(agent.contact),
                                })),
                            ],
                        },
                    },
                    {
                        type: "set_tag",
                        label: "Adicionar Etiqueta",
                        values: {
                            type: InputType.Select,
                            options: tags.map((tag) => ({
                                value: tag.id,
                                label: tag.name,
                            })),
                        },
                    },
                    {
                        type: "unset_tag",
                        label: "Remover Etiqueta",
                        values: {
                            type: InputType.Select,
                            options: tags.map((tag) => ({
                                value: tag.id,
                                label: tag.name,
                            })),
                        },
                    },
                    {
                        type: "add_follower",
                        label: "Adicionar Seguidor",
                        values: {
                            type: InputType.Select,
                            options: [
                                ...currentUserOption,
                                ...agents.map((agent) => ({
                                    value: agent.id,
                                    label: getFullName(agent.contact),
                                })),
                            ],
                        },
                    },
                    {
                        type: "add_response",
                        label: "Enviar Resposta",
                        values: {
                            type: InputType.Select,
                            options: responses.map((response) => ({
                                value: response.id,
                                label: response.name,
                            })),
                        },
                    },
                    {
                        type: "add_comment",
                        label: "Enviar Comentário",
                        values: {
                            type: InputType.Text,
                        },
                    },
                ],
            },
            {
                groupLabel: "Campos Personalizados",
                options: customFields
                    .filter((customField) => customField.entity === "ticket")
                    .map((customField) => ({
                        type: `set_custom_${customField.placeholder}`,
                        label: `Mudar ${customField.name}`,
                        values: {
                            type: customField.type,
                            options: customField.options,
                        },
                    })),
            },
        ],
        [agents, currentUserOption, customFields, groups, responses, tags]
    )

    const actionsTypesGroupExisting = useMemo(
        () => actionsTypesGroup.filter((acGroup) => acGroup.options.length),
        [actionsTypesGroup]
    )

    return (
        <Form.Item label="Ações">
            <Form.List name="actions">
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            {fields.map((field, i) => (
                                <div
                                    key={field.key}
                                    style={{
                                        display: "flex",
                                        marginBottom: 8,
                                        // boxShadow: "0 1px 9px rgba(0,0,0,.08)",
                                        // border: "1px solid rgba(0,0,0,.08)",
                                        // padding: "10px 15px",
                                        alignItems: "center",
                                    }}
                                >
                                    <Row gutter={12} style={{ width: "100%" }} align="middle">
                                        <Col span={12}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, "type"]}
                                                fieldKey={[field.fieldKey, "type"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Faltando Campo",
                                                    },
                                                ]}
                                                noStyle
                                            >
                                                <Select
                                                    onChange={() => {
                                                        props.form.resetFields([
                                                            ["actions", field.name, "value"],
                                                        ])
                                                    }}
                                                >
                                                    {actionsTypesGroupExisting.map(
                                                        (actionGroups) => (
                                                            <Select.OptGroup
                                                                label={actionGroups.groupLabel}
                                                                key={actionGroups.groupLabel}
                                                            >
                                                                {actionGroups.options.map(
                                                                    (action) => (
                                                                        <Select.Option
                                                                            key={action.type}
                                                                            value={action.type}
                                                                        >
                                                                            {action.label}
                                                                        </Select.Option>
                                                                    )
                                                                )}
                                                            </Select.OptGroup>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, currentValues) => {
                                                    return (
                                                        prevValues.actions[field.name]?.type !==
                                                        currentValues.actions[field.name]?.type
                                                    )
                                                }}
                                            >
                                                {({ getFieldValue }) => {
                                                    let action: IActionType
                                                    actionsTypesGroupExisting.some(
                                                        (actionGroup) => {
                                                            const foundAction = actionGroup.options.find(
                                                                (action) => {
                                                                    return (
                                                                        action.type ===
                                                                        getFieldValue("actions")[
                                                                            field.name
                                                                        ]?.type
                                                                    )
                                                                }
                                                            )
                                                            if (foundAction) {
                                                                action = foundAction
                                                                return true
                                                            }
                                                        }
                                                    )
                                                    if (action!) {
                                                        return (
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, "value"]}
                                                                fieldKey={[field.fieldKey, "value"]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Faltando Campo",
                                                                    },
                                                                ]}
                                                                noStyle
                                                                valuePropName={
                                                                    action!.values.type ===
                                                                    InputType.Boolean
                                                                        ? "checked"
                                                                        : undefined
                                                                }
                                                            >
                                                                {getValueInput(action!.values)}
                                                            </Form.Item>
                                                        )
                                                    }
                                                    return null
                                                }}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Button
                                        icon={<i className="far fa-times" />}
                                        size="small"
                                        shape="circle"
                                        style={{ marginLeft: 10 }}
                                        type="text"
                                        onClick={() => {
                                            remove(field.name)
                                        }}
                                    />
                                </div>
                            ))}

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add()
                                    }}
                                    block
                                    style={{ color: "#367fee", textAlign: "left", height: 45 }}
                                >
                                    <Icon name="fas fa-plus-circle" style={{ marginRight: 10 }} />
                                    Ação
                                </Button>
                            </Form.Item>
                        </div>
                    )
                }}
            </Form.List>
        </Form.Item>
    )
}

export default ActionsInput
