import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IServidor, IServidorMatricula } from "models/servidores"

export default generateGenericMock(endpoints.SERVIDORES, generateServidorMockData)
export const servidorMatriculas = generateGenericMock(endpoints.SERVIDOR_MATRICULA, generateServidorMatriculaMockData)
export const primeiroDescontoOpcoes = generateGenericMock(endpoints.PRIMEIRO_DESCONTO, () => [
    {
        value: '01/05/2021',
        label: '01/05/2021'
    },
    {
        value: '01/06/2021',
        label: '01/06/2021'
    },
    {
        value: '01/07/2021',
        label: '01/07/2021'
    },
], { isObjectReturn: true })
export const reservarMargem = generateGenericMock(endpoints.RESERVAR_MARGEM, () => ({
    comprovante_url: 'https://i.imgur.com/CP5J8vw.png'
}), { isObjectReturn: true })

export function generateServidorMockData(index: string, model?: Partial<IServidor>): any {
    return {
        id: index || "0",
        nome: `${faker.name.firstName()} ${faker.name.lastName()}`,
        cpf: `${faker.random.number()}`,
        criado_em: faker.date.past().toISOString(),
        rg: `${faker.random.number()}`,
        tipo: "Proprio",
        sexo: "Masculino",
        nascido_em: "01/05/1992",
        estado_civil: "Solteiro",
        dependentes: 2,
        formacao: "",
        nome_mae: "Edinéia da Silva",
        nome_pai: "Marinaldo da Silva",
        email: "sexycat28@gmail.com",
        email_secundario: "",
        titulo_eleitor: "123213",
        secao: "2",
        zona: "B",
        cargo: "MEDICO DO TRABALHO",
        atividade: "Comissionado",
        telefones: [
            {
                tipo: "Comercial",
                numero: "(85) 3491 24 11"
            }
        ],
        emails: ["pussycat851@gmail.com", "amarildenio.silva@gmail.com"],
        matriculas: [{
            id: index || "0",
            numero: faker.random.number() + "",
            criado_em: faker.date.past().toISOString(),
            entidade: faker.company.companyName(),
            orgao: "0001",
            cargo: "Engenheiro",
            situacao: "Normal",
            fim_contrato: "Indeterminado",
            margem: {
                id: index || "0",
                emprestimo: faker.random.number(),
                mensalidade: faker.random.number(),
                cartao: faker.random.number(),
                totais: [{
                    data_competencia: faker.date.past().toISOString(),
                    valor: faker.random.number()
                }]
            },
        }],
        ...model
    }
}

export function generateServidorMatriculaMockData(index: string, model?: Partial<IServidorMatricula>): any {
    return {
        id: index || "0",
        servidor: {
            id: index || "0",
            cpf: `${faker.random.number()}`,
            nome: `${faker.name.firstName()} ${faker.name.lastName()}`,
            criado_em: faker.date.past().toISOString(),
            rg: `${faker.random.number()}`,
            matriculas: [{
                id: index || "0",
                numero: faker.random.number() + "",
                criado_em: faker.date.past().toISOString(),
                entidade: faker.company.companyName(),
                orgao: "0001",
                cargo: "Engenheiro",
                situacao: "Normal",
                fim_contrato: "Indeterminado",
                margem: {
                    id: index || "0",
                    emprestimo: faker.random.number(),
                    mensalidade: faker.random.number(),
                    cartao: faker.random.number(),
                    totais: [
                        {
                            data_competencia: faker.date.past().toISOString(),
                            valor: faker.random.number()
                        },
                        {
                            data_competencia: faker.date.past().toISOString(),
                            valor: faker.random.number()
                        },
                        {
                            data_competencia: faker.date.past().toISOString(),
                            valor: faker.random.number()
                        },
                        {
                            data_competencia: faker.date.past().toISOString(),
                            valor: faker.random.number()
                        },
                        {
                            data_competencia: faker.date.past().toISOString(),
                            valor: faker.random.number()
                        },
                    ]
                },
            }],
        },
        matricula: {
            id: index || "0",
            numero: faker.random.number() + "",
            entidade: faker.company.companyName(),
            criado_em: faker.date.past().toISOString(),
            orgao: "0001",
            cargo: "Engenheiro",
            situacao: "Normal",
            fim_contrato: "Indeterminado",
            margem: {
                id: index || "0",
                emprestimo: faker.random.number(),
                mensalidade: faker.random.number(),
                cartao: faker.random.number(),
                totais: [
                    {
                        data_competencia: faker.date.past().toISOString(),
                        valor: faker.random.number()
                    },
                    {
                        data_competencia: faker.date.past().toISOString(),
                        valor: faker.random.number()
                    },
                    {
                        data_competencia: faker.date.past().toISOString(),
                        valor: faker.random.number()
                    },
                    {
                        data_competencia: faker.date.past().toISOString(),
                        valor: faker.random.number()
                    },
                    {
                        data_competencia: faker.date.past().toISOString(),
                        valor: faker.random.number()
                    },
                ]
            },
        },
        ...model
    }
}