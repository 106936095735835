import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IConsignante {
    id: string
    atualizadoEm: string
    criadoEm: string
    nome: string
    subdomain: string
}

export const consignantes: IGenericModel<IConsignante> = {
    ...generic(endpoints.CONSIGNANTES),
}