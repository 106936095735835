import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { ITicket } from "./tickets"

export interface IPerson {
    id: string
    updatedAt: string
    createdAt: string
    avatar?: string
    name: string
    lastName?: string
    phone?: string
    email?: string
}

export interface ITimelineEvent {
    id: string
    createdAt: string
    title: string
    type: string
    icon?: string
    details: string
    ticket: ITicket
}

export interface IContact extends IPerson {
    organization?: string
    externalId?: string
    twitterId?: string
    facebookId?: string
    tagIds?: string[]
    notes?: string
    customFields: Object
    timeline?: ITimelineEvent[]
}

export const contacts: IGenericModel<IContact> = {
    ...generic(endpoints.bank.CONTACTS),
}