import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row } from "antd"
import Icon from "components/common/Icon"
import { ICalendarEvent } from "models/calendario_eventos"
import React, { useMemo } from "react"

import { ISlotInfo } from "components/common/BigCalendar"
import FormModal from "./common/FormModal"
import { useRecoilState } from "recoil"
import { askPasswordAtom } from "atoms/app"

interface IAskPasswordModalProps {
    onClose: () => any
}

function AskPasswordModal(props: IAskPasswordModalProps) {
    const [askPassword, setAskPassword] = useRecoilState(askPasswordAtom)
    const [form] = Form.useForm()

    return (
        <FormModal
            titleOverwrite="Digite sua senha de 4 dígitos"
            form={form}
            onClose={() => {
                props.onClose()
                setAskPassword({
                    show: false,
                })
            }}
            okText={"OK"}
            type="modal"
            onSubmit={(model: any) => {
                return new Promise((res) => {
                    askPassword.onOk?.(model.shortPassword)
                    setAskPassword({
                        show: false,
                    })
                    res?.(model.shortPassword)
                })
            }}
        >
            <Row justify="center">
                <Col>
                    <Form.Item
                        name="shortPassword"
                        rules={[
                            {
                                required: true,
                                message: "Obrigatório",
                            },
                        ]}
                    >
                        <Input type="password" autoFocus />
                    </Form.Item>
                </Col>
            </Row>
        </FormModal>
    )
}

export default AskPasswordModal
