import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useBreadcrumb, useStore, useActions, useHost, useGenericStore } from "hooks"
// import { UserOutlined, LockOutlined } from "@ant-design/icons"

import styles from "./style.module.css"
import { Row, Col, Form, Input, Button, Select, Alert, Radio, notification, Avatar } from "antd"
import AuthPage from "../AuthPage"
// import Icon from "components/common/Icon"
import { MaskedInput } from "antd-mask-input"
import masks from "lib/masks"
import { IUser } from "models/users"
import { CompanyType, IDocumentType, PersonRoleType } from "lib/constants"
import { registerAtom } from "atoms/registerAtom"
import Uploader from "components/form/Uploader"
import cx from "classnames"
import { useRecoilState } from "recoil"
import toast from "lib/toast"

function RegisterCNPJContinuation() {
    useBreadcrumb({ items: [{ title: "Solicitação de acesso" }] })
    const [loading, setLoading] = useState(false)
    const [registerModel, setRegisterModel] = useRecoilState(registerAtom)

    const { uploadAssets } = useActions((actions) => actions.app)

    const history = useHistory()

    async function onSubmit(model) {
        console.log("model", model)
        setLoading(true)
        try {
            for (let index = 0; index < model.persons?.length; index++) {
                const person = model.persons[index]
                if (person.document_front?.[0]?.preview) {
                    let result1 = await uploadAssets(person.document_front)
                    let result2
                    if (!result1.data?.[0].fileName) {
                        toast.error("Erro ao subir arquivo")
                        throw new Error("Error uploading file")
                    }
                    person.document_front = result1.data?.[0].fileName

                    if (person.document_back) {
                        result2 = await uploadAssets(person.document_back)
                        if (!result2.data?.[0].fileName) {
                            toast.error("Erro ao subir arquivo")
                            throw new Error("Error uploading file")
                        }
                        person.document_back = result2.data?.[0].fileName
                    }

                    if (person.document_front_cpf) {
                        let result3 = await uploadAssets(person.document_front_cpf)
                        if (!result3.data?.[0].fileName) {
                            toast.error("Erro ao subir arquivo")
                            throw new Error("Error uploading file")
                        }
                        person.document_front_cpf = result3.data?.[0].fileName
                    }

                    person.personDocuments = [
                        {
                            fileName: person.document_front,
                            documentType: person.document_type === "cnh" ? IDocumentType.CNH : IDocumentType.IdentityDocumentFront,
                        },
                        {
                            fileName: person.document_back,
                            documentType: person.document_type === "cnh" ? IDocumentType.CNH_Verse : IDocumentType.IdentityDocumentVerse,
                        },
                    ].filter((x) => x.fileName)

                    person.nationality = "BRA"
                    person.personRoleType = PersonRoleType.Partner

                    // request.post(endpoints.REGISTER, finalModel).then(() => {
                    //     toast.success("Obrigado! Seu cadastro será analisado e você receberá um e-mail.", 10)
                    //     history.push("/user/login")
                    // })
                } else {
                    toast.error("Necessário subir as fotos do documento de identificação")
                    throw new Error("Error uploading file")
                }
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            return
        }
        setLoading(false)

        setRegisterModel((previousModel) => ({
            ...previousModel,
            persons: model.persons || [],
        }))

        history.push("/user/register-continuation")

        // if (model.cnpj_card?.[0]?.preview && model.social_contract?.[0]?.preview) {
        //     setLoading(true)
        //     let { data } = await uploadAssets(model.cnpj_card)
        //     setLoading(false)
        //     if (!data?.[0].fileName) {
        //         toast.error("Erro ao subir arquivo")
        //         throw new Error("Error uploading file")
        //     }
        //     model.cnpj_card = data?.[0].fileName
        //     let result2 = await uploadAssets(model.social_contract)
        //     if (!result2.data?.[0].fileName) {
        //         toast.error("Erro ao subir arquivo")
        //         throw new Error("Error uploading file")
        //     }
        //     model.social_contract = result2.data?.[0].fileName
        //     setRegisterModel((p) => ({
        //         ...p,
        //         ...model,
        //     }))
        //     history.push("/user/register-continuation")
        // } else {
        //     toast.error("Necessário subir os arquivos de cartão cnpj e contrato social")
        // }
    }

    return (
        <AuthPage title="Cadastro CNPJ" maxWidth={990}>
            <Form name="normal_login" onFinish={onSubmit as any} layout="vertical">
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="A empresa possui outros sócios?"
                            name="parntersCount"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value={0}>Não</Select.Option>
                                <Select.Option value={1}>1 outro sócio</Select.Option>
                                <Select.Option value={2}>2 outros sócios</Select.Option>
                                <Select.Option value={3}>3 outros sócios</Select.Option>
                                <Select.Option value={4}>4 outros sócios</Select.Option>
                                <Select.Option value={5}>5 outros sócios</Select.Option>
                                <Select.Option value={6}>6 outros sócios</Select.Option>
                                <Select.Option value={7}>7+ outros sócios</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item shouldUpdate>
                    {({ getFieldsValue }) => {
                        const values = getFieldsValue()
                        if (!values.parntersCount) return null

                        const count = parseInt(values.parntersCount)
                        const partners = []
                        for (let i = 0; i < count; i++) {
                            partners.push(i)
                        }

                        return partners.map((partner, partnerIndex) => {
                            const partnerValues = getFieldsValue()
                            // const partnerDocumentType = getFieldsValue(["persons", partnerIndex, "documentType"])
                            const partnerDocumentType = partnerValues?.persons?.[partnerIndex]?.documentType
                            return (
                                <div
                                    style={{
                                        // border: "1px solid #d9d9d9",
                                        padding: 20,
                                        marginBottom: 30,
                                        borderRadius: 5,
                                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    }}
                                >
                                    <Row gutter={20}>
                                        <Col md={12} xs={24}>
                                            <Form.Item
                                                label={`Nome do sócio ${partner + 1}`}
                                                name={["persons", partnerIndex, "name"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Obrigatório.",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} xs={24}>
                                            <Form.Item
                                                label="CPF"
                                                name={["persons", partnerIndex, "taxNumber"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Por favor digite o cpf.",
                                                    },
                                                ]}
                                            >
                                                <MaskedInput mask="111.111.111-11" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} xs={24}>
                                            <Form.Item
                                                label="Celular"
                                                name={["persons", partnerIndex, "cellphone"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Obrigatório.",
                                                    },
                                                ]}
                                            >
                                                <MaskedInput mask="(11) 11111-1111" minLength={15} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} xs={24}>
                                            <Form.Item
                                                label="E-mail"
                                                name={["persons", partnerIndex, "email"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Obrigatório.",
                                                    },
                                                ]}
                                            >
                                                <Input type="email" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} xs={24}>
                                            <Form.Item
                                                label="Data de nascimento"
                                                name={["persons", partnerIndex, "birthDate"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Por favor digite uma data de nascimento.",
                                                    },
                                                ]}
                                            >
                                                <MaskedInput mask="11/11/1111" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} xs={24}>
                                            <Form.Item
                                                label="Nome da mãe"
                                                name={["persons", partnerIndex, "motherName"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Por favor digite um nome.",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} xs={24}>
                                            <Form.Item
                                                label="Doc. de identificação"
                                                name={["persons", partnerIndex, "documentType"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Obrigatório.",
                                                    },
                                                ]}
                                            >
                                                <Select>
                                                    <Select.Option value={"cnh"}>CNH</Select.Option>
                                                    <Select.Option value={"rg"}>RG</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {partnerDocumentType && (
                                        <Row gutter={20}>
                                            <Col md={12} xs={24}>
                                                <Form.Item shouldUpdate>
                                                    {({ getFieldValue }) => {
                                                        // const image = getFieldValue("document_front")
                                                        const image = getFieldValue(["persons", partnerIndex, "document_front"])
                                                        return (
                                                            <Form.Item
                                                                name={["persons", partnerIndex, "document_front"]}
                                                                label={
                                                                    getFieldValue(["persons", partnerIndex, "documentType"]) === "rg"
                                                                        ? "RG - Frente"
                                                                        : "CNH Aberta"
                                                                }
                                                            >
                                                                <Uploader
                                                                    className={cx(styles.avatar_uploader)}
                                                                    dropOptions={{
                                                                        accept: {
                                                                            "image/png": [".png", ".jpeg", ".jpg"],
                                                                            "image/jpeg": [".png", ".jpeg", ".jpg"],
                                                                            "application/pdf": [".pdf"],
                                                                        },
                                                                        multiple: false,
                                                                    }}
                                                                    showUploadButton
                                                                >
                                                                    <img
                                                                        src={image?.[0].preview}
                                                                        style={{
                                                                            width: 100,
                                                                            height: 100,
                                                                            objectFit: "cover",
                                                                            borderRadius: 12,
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                </Uploader>
                                                            </Form.Item>
                                                        )
                                                    }}
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <Form.Item shouldUpdate>
                                                    {({ getFieldValue }) => {
                                                        const image = getFieldValue(["persons", partnerIndex, "document_back"])
                                                        return (
                                                            <Form.Item
                                                                name={["persons", partnerIndex, "document_back"]}
                                                                label={
                                                                    getFieldValue(["persons", partnerIndex, "documentType"]) === "rg"
                                                                        ? "RG - Verso"
                                                                        : "CNH - Verso"
                                                                }
                                                            >
                                                                <Uploader
                                                                    className={cx(styles.avatar_uploader)}
                                                                    dropOptions={{
                                                                        accept: {
                                                                            "image/png": [".png", ".jpeg", ".jpg"],
                                                                            "image/jpeg": [".png", ".jpeg", ".jpg"],
                                                                            "application/pdf": [".pdf"],
                                                                        },
                                                                        multiple: false,
                                                                    }}
                                                                    showUploadButton
                                                                >
                                                                    <img
                                                                        src={image?.[0].preview}
                                                                        style={{
                                                                            width: 100,
                                                                            height: 100,
                                                                            objectFit: "cover",
                                                                            borderRadius: 12,
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                </Uploader>
                                                            </Form.Item>
                                                        )
                                                    }}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            )
                        })
                    }}
                </Form.Item>

                <Form.Item>
                    <Row gutter={20} align="middle">
                        <Col>
                            <Link to="/user/register-cnpj">
                                <Button>Voltar</Button>
                            </Link>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                // className="login-form-button"
                                // style={{ minWidth: 160, display: "block" }}
                                // size="large"
                            >
                                Continuar
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </AuthPage>
    )
}

export default RegisterCNPJContinuation
