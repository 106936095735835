import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { InputType } from "lib/constants"
import { Thunk, thunk } from "easy-peasy"
import { request } from "lib/request"
import { message } from "antd"
import { IStoreModel } from "models"
import { IPreferences } from "./session"
import toast from "lib/toast"

export interface IAppManifest {
    name: string,
    version: number,
    private: boolean,
    author: {
        name: string,
        email: string,
        url?: string
    },
    product: {
        bank: {
            location: {
                ticket_sidebar?: {
                    url: string,
                    icon: string
                },
                nav_bar?: {
                    url: string,
                    icon: string
                },
                top_bar?: {
                    url: string,
                    icon: string
                },
                background?: {
                    url: string,
                    icon: string
                },
                contact_sidebar?: {
                    url: string,
                    icon: string
                },
                organization_sidebar?: {
                    url: string,
                    icon: string
                },
                new_ticket_sidebar?: {
                    url: string,
                    icon: string
                },
            },
            preferences: Partial<IPreferences>
        }
    }
}

export interface IApp {
    id: string
    updatedAt: string
    createdAt: string
    name: string
    description: string
    icon: string
    private: boolean
    devMode: boolean
    installed: boolean
    developer: {
        name: string
        site: string
    }
    endpoint: string
    secret: string
    manifest?: IAppManifest
}

export interface IMarketPlace extends IGenericModel<IApp> {
    uninstall: Thunk<IMarketPlace, IApp>
    install: Thunk<IMarketPlace, IApp, any, IStoreModel>
}

export const marketplace: IMarketPlace = {
    ...generic(endpoints.APPS),
    install: thunk((actions, app, { getStoreActions }) => {
        actions.patchLoading({ custom: app?.id })
        return request
            .post(endpoints.APPS + "/" + app?.id + "/install")
            .then((response) => {
                toast.success("App instalado.")
                actions.patchElementOnAll({ ...app, installed: true })
                if (app.manifest?.product.bank.preferences) {
                    getStoreActions().session.patchPreferences(app.manifest?.product.bank.preferences)
                }
            })
            .finally(() => {
                actions.patchLoading({ custom: '' })
            })
    }),
    uninstall: thunk((actions, app) => {
        actions.patchLoading({ custom: app?.id })
        return request
            .post(
                endpoints.APPS + "/" + app?.id + "/uninstall"
            )
            .then((response) => {
                toast.info("App desinstalado.")
                actions.patchElementOnAll({ ...app, installed: false })
            })
            .finally(() => {
                actions.patchLoading({ custom: '' })
            })
    }),
}