import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useBreadcrumb, useStore, useActions, useHost, useGenericStore } from "hooks"
// import { UserOutlined, LockOutlined } from "@ant-design/icons"

import styles from "./style.module.css"
import { Row, Col, Form, Input, Button, Select, Divider, InputNumber, Checkbox } from "antd"
import AuthPage from "../AuthPage"
// import Icon from "components/common/Icon"
import { MaskedInput } from "antd-mask-input"
import { IDocumentType } from "lib/constants"
import Uploader from "components/form/Uploader"
import cx from "classnames"
import { useRecoilState } from "recoil"
import { paymentAtom } from "atoms/payment"
import toast from "lib/toast"
import masks, { formatterCurrency, parseDecimal } from "lib/masks"
import terms from "lib/terms"
import { request } from "lib/request"
import { endpoints } from "config/endpoints.config"
import estadosJson from "lib/estados.json"
import ConversationListHeader from "products/bank/pages/Chat/ConversationList/ConversationListHeader"
import { IRegisterModel, registerAtom } from "atoms/registerAtom"

const template = terms.getHtml()

function RegisterContinuation() {
    const [loading, setLoading] = useState(false)
    useBreadcrumb({ items: [{ title: "Solicitação de acesso" }] })
    const [registerModel, setRegisterModel] = useRecoilState(registerAtom)
    const { uploadAssets } = useActions((actions) => actions.app)

    const history = useHistory()

    async function onSubmit(model) {
        if (!model.agreed) {
            toast.error("Necessário concordar com os termos")
            return
        }

        setLoading(true)

        const cpfPass = (model.cpf_on_rg === "no" && model.document_front_cpf) || model.cpf_on_rg === "yes" || !model.cpf_on_rg

        try {
            if (model.document_front?.[0]?.preview && cpfPass) {
                let result1 = await uploadAssets(model.document_front)
                let result2
                if (!result1.data?.[0].fileName) {
                    toast.error("Erro ao subir arquivo")
                    throw new Error("Error uploading file")
                }
                model.document_front = result1.data?.[0].fileName

                if (model.document_back) {
                    result2 = await uploadAssets(model.document_back)
                    if (!result2.data?.[0].fileName) {
                        toast.error("Erro ao subir arquivo")
                        throw new Error("Error uploading file")
                    }
                    model.document_back = result2.data?.[0].fileName
                }

                if (model.document_front_cpf) {
                    let result3 = await uploadAssets(model.document_front_cpf)
                    if (!result3.data?.[0].fileName) {
                        toast.error("Erro ao subir arquivo")
                        throw new Error("Error uploading file")
                    }
                    model.document_front_cpf = result3.data?.[0].fileName
                }

                console.log(2222, model, {
                    fileName: model.cnpj_card,
                    documentType: IDocumentType.CNPJ,
                })

                const finalModel: IRegisterModel = {
                    ...registerModel,
                    ...model,
                    address: {
                        ...registerModel.address,
                        ...model.address,
                        cep: masks.number(model.address.cep),
                    },
                    publiclyExposedPerson: model.publiclyExposedPerson === "Sim",
                    monthlyIncome: parseFloat(masks.number(model.monthlyIncome + ""!) || "0"),
                    patrimony: parseFloat(masks.number(model.patrimony + ""!) || "0"),
                    cellphone: masks.number(model.cellphone),
                    documents: [
                        {
                            fileName: model.document_front,
                            documentType: model.document_type === "cnh" ? IDocumentType.CNH : IDocumentType.IdentityDocumentFront,
                        },
                        {
                            fileName: model.document_back,
                            documentType: model.document_type === "cnh" ? IDocumentType.CNH_Verse : IDocumentType.IdentityDocumentVerse,
                            // documentType: IDocumentType.IdentityDocumentVerse,
                        },
                        ...(model.document_front_cpf
                            ? [
                                  {
                                      fileName: model.document_front_cpf,
                                      documentType: IDocumentType.TaxNumber,
                                  },
                              ]
                            : []),
                        {
                            fileName: model.procuracao,
                            documentType: IDocumentType.LetterOfAttorney,
                        },
                        {
                            fileName: registerModel.cnpj_card,
                            documentType: IDocumentType.CNPJ,
                        },
                        {
                            fileName: registerModel.social_contract,
                            documentType: IDocumentType.Contract,
                        },
                        {
                            fileName: registerModel.proof_residence,
                            documentType: IDocumentType.ProofAddress,
                        },
                        {
                            fileName: registerModel.annual_billing,
                            documentType: IDocumentType.CompanyAnnualBilling,
                        },
                    ].filter((x) => x.fileName),
                }

                finalModel.persons = [
                    {
                        name: finalModel.name_pessoa_fisica || finalModel.name,
                        taxNumber: finalModel.cpf,
                        cellphone: finalModel.cellphone,
                        email: finalModel.email,
                        personRoleType: finalModel.personRoleType,
                        birthDate: finalModel.birthDate,
                        nationality: "BRA",
                        personDocuments: (finalModel as any)?.documents,
                        motherName: finalModel.motherName,
                    },
                    ...(registerModel.persons || []),
                ]

                setRegisterModel(finalModel)

                await request.post(endpoints.REGISTER, finalModel).then(() => {
                    toast.success("Obrigado! Seu cadastro será analisado e você receberá um e-mail.", 10)
                    history.push("/user/login")
                })
            } else {
                toast.error("Necessário subir as fotos do documento de identificação")
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    return (
        <AuthPage title="Cadastro continuação" maxWidth={990}>
            <Form
                name="normal_login"
                onFinish={onSubmit as any}
                layout="vertical"
                initialValues={{
                    document_type: "cnh",
                }}
            >
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Nome da mãe"
                            name="motherName"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Publicamente exposto?"
                            name="publiclyExposedPerson"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value={"Sim"}>Sim</Select.Option>
                                <Select.Option value={"Não"}>Não</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input type="email" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Celular"
                            name="cellphone"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <MaskedInput mask="(11) 11111-1111" minLength={15} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Senha de acesso 6 dígitos"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input type="password" minLength={6} maxLength={6} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Confirme a senha"
                            name="passwordConfirm"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input type="password" minLength={6} maxLength={6} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Senha de 4 dígitos"
                            name="shortPassword"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input type="password" minLength={4} maxLength={4} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Confirme a senha"
                            name="shortPasswordConfirm"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input type="password" minLength={4} maxLength={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    {registerModel?.account_type === "pessoa_juridica" ? (
                        <>
                            <Col md={24} xs={24}>
                                <Form.Item
                                    label="Qual é o nome da sua empresa?"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Obrigatório.",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={24} xs={24}>
                                <Form.Item
                                    label="Qual é o seu nome completo?"
                                    name="name_pessoa_fisica"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Obrigatório.",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </>
                    ) : (
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Qual é o seu nome completo?"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Obrigatório.",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    )}

                    <Col md={12} xs={24}></Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Renda bruta mensal"
                            name="monthlyIncome"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} parser={parseDecimal} formatter={formatterCurrency} step={0.01} min={0} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Qual é a sua profissão?"
                            name="job"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={24} xs={24}>
                        <Form.Item
                            label="Insira aqui o valor total estimado de carros, investimentos, imóveis e outros bens em seu nome."
                            name="patrimony"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} parser={parseDecimal} formatter={formatterCurrency} step={0.01} min={0} />
                        </Form.Item>
                    </Col>
                </Row>

                {/* ███████╗███╗   ██╗██████╗ ███████╗██████╗ ███████╗ ██████╗ 
                {/* ██╔════╝████╗  ██║██╔══██╗██╔════╝██╔══██╗██╔════╝██╔═══██╗
                {/* █████╗  ██╔██╗ ██║██║  ██║█████╗  ██████╔╝█████╗  ██║   ██║
                {/* ██╔══╝  ██║╚██╗██║██║  ██║██╔══╝  ██╔══██╗██╔══╝  ██║   ██║
                {/* ███████╗██║ ╚████║██████╔╝███████╗██║  ██║███████╗╚██████╔╝
                {/* ╚══════╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝  ╚═╝╚══════╝ ╚═════╝ 
                {/*                                                             */}
                <Form.Item shouldUpdate>
                    {({ getFieldValue }) =>
                        getFieldValue("pessoa_fisica") ? (
                            <Divider orientation="left">Endereço</Divider>
                        ) : (
                            <Divider orientation="left">Endereço da empresa</Divider>
                        )
                    }
                </Form.Item>

                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Qual o CEP?"
                            name={["address", "cep"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Rua"
                            name={["address", "street"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Número"
                            name={["address", "number"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Complemento" name={["address", "complement"]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={24} xs={24}>
                        <Form.Item
                            label="Referência"
                            name={["address", "reference"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Bairro"
                            name={["address", "neighborhood"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Estado"
                            name={["address", "state"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Select>
                                {estadosJson.UF.map((uf) => (
                                    <Select.Option key={uf.nome} value={uf.sigla}>
                                        {uf.nome}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Cidade"
                            name={["address", "city"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Doc. de identificação"
                            name="document_type"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value={"cnh"}>CNH</Select.Option>
                                <Select.Option value={"rg"}>RG</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                            if (getFieldValue("document_type") === "rg") {
                                return (
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            label="Seu RG contém o número do seu CPF?"
                                            name="cpf_on_rg"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Obrigatório.",
                                                },
                                            ]}
                                        >
                                            <Select>
                                                <Select.Option value={"yes"}>Sim</Select.Option>
                                                <Select.Option value={"no"}>Não</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )
                            }
                            return null
                        }}
                    </Form.Item>
                </Row>

                {/* ███████╗ ██████╗ ████████╗ ██████╗ ███████╗
                {/* ██╔════╝██╔═══██╗╚══██╔══╝██╔═══██╗██╔════╝
                {/* █████╗  ██║   ██║   ██║   ██║   ██║███████╗
                {/* ██╔══╝  ██║   ██║   ██║   ██║   ██║╚════██║
                {/* ██║     ╚██████╔╝   ██║   ╚██████╔╝███████║
                {/* ╚═╝      ╚═════╝    ╚═╝    ╚═════╝ ╚══════╝
                {/*                                             */}
                <Divider orientation="left">Fotos</Divider>
                <br />

                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item shouldUpdate>
                            {({ getFieldValue }) => {
                                const image = getFieldValue("document_front")
                                return (
                                    <Form.Item
                                        name={"document_front"}
                                        label={getFieldValue("document_type") === "rg" ? "RG - Frente" : "CNH Aberta"}
                                        // valuePropName="url"
                                        // getValueFromEvent={(e) => {
                                        //     return e?.file?.response?.url
                                        // }}
                                    >
                                        <Uploader
                                            className={cx(styles.avatar_uploader)}
                                            dropOptions={{
                                                accept: {
                                                    "image/png": [".png", ".jpeg", ".jpg"],
                                                    "image/jpeg": [".png", ".jpeg", ".jpg"],
                                                    "application/pdf": [".pdf"],
                                                },
                                                multiple: false,
                                            }}
                                            showUploadButton
                                        >
                                            <img
                                                src={image?.[0].preview}
                                                style={{
                                                    width: 100,
                                                    height: 100,
                                                    objectFit: "cover",
                                                    borderRadius: 12,
                                                }}
                                                alt=""
                                            />
                                        </Uploader>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item shouldUpdate>
                            {({ getFieldValue }) => {
                                const image = getFieldValue("document_back")
                                // if (getFieldValue("document_type") === "cnh") return null
                                return (
                                    <Form.Item
                                        name={"document_back"}
                                        label={getFieldValue("document_type") === "rg" ? "RG - Verso" : "CNH - Verso"}

                                        // valuePropName="url"
                                        // getValueFromEvent={(e) => {
                                        //     return e?.file?.response?.url
                                        // }}
                                    >
                                        <Uploader
                                            className={cx(styles.avatar_uploader)}
                                            dropOptions={{
                                                accept: {
                                                    "image/png": [".png", ".jpeg", ".jpg"],
                                                    "image/jpeg": [".png", ".jpeg", ".jpg"],
                                                    "application/pdf": [".pdf"],
                                                },
                                                multiple: false,
                                            }}
                                            showUploadButton
                                        >
                                            <img
                                                src={image?.[0].preview}
                                                style={{
                                                    width: 100,
                                                    height: 100,
                                                    objectFit: "cover",
                                                    borderRadius: 12,
                                                }}
                                                alt=""
                                            />
                                        </Uploader>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item shouldUpdate>
                            {({ getFieldValue }) => {
                                const image = getFieldValue("document_front_cpf")
                                if (getFieldValue("document_type") === "cnh" || getFieldValue("cpf_on_rg") === "yes") return null
                                return (
                                    <Form.Item
                                        name={"document_front_cpf"}
                                        label="CPF - Frente"
                                        // valuePropName="url"
                                        // getValueFromEvent={(e) => {
                                        //     return e?.file?.response?.url
                                        // }}
                                    >
                                        <Uploader
                                            className={cx(styles.avatar_uploader)}
                                            dropOptions={{
                                                accept: {
                                                    "image/png": [".png", ".jpeg", ".jpg"],
                                                    "image/jpeg": [".png", ".jpeg", ".jpg"],
                                                    "application/pdf": [".pdf"],
                                                },
                                                multiple: false,
                                            }}
                                            showUploadButton
                                        >
                                            <img
                                                src={image?.[0].preview}
                                                style={{
                                                    width: 100,
                                                    height: 100,
                                                    objectFit: "cover",
                                                    borderRadius: 12,
                                                }}
                                                alt=""
                                            />
                                        </Uploader>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}></Col>
                </Row>
                <Form.Item name="agreed" valuePropName="checked">
                    <Checkbox style={{ fontSize: 16 }}>
                        Li e concordo com os{" "}
                        <a
                            onClick={() => {
                                const wnd = window.open("about:blank", "", "_blank")
                                wnd?.document.write(template)
                            }}
                            style={{ color: "var(--primary)" }}
                        >
                            Termos de Uso e Política de Privacidade
                        </a>
                    </Checkbox>
                </Form.Item>

                <br />
                <Form.Item>
                    <Row gutter={20} align="middle">
                        <Col>
                            <Link to="/user/login">
                                <Button>Voltar ao início</Button>
                            </Link>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                // className="login-form-button"
                                // style={{ minWidth: 160, display: "block" }}
                                // size="large"
                            >
                                Cadastrar
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </AuthPage>
    )
}

export default RegisterContinuation
