export enum StatusEnum {
    NEW = "new",
    OPEN = "open",
    PENDING = "pending",
    SOLVED = "solved",
    CLOSED = "closed",
}

export const StatusLabel = {
    new: "Novo",
    open: "Aberto",
    pending: "Pendente",
    solved: "Resolvido",
    closed: "Fechado",
}

export enum TypeEnum {
    QUESTION = "question",
    INCIDENT = "incident",
    PROBLEM = "problem",
    TASK = "task",
}

export enum PriorityEnum {
    LOW = "low",
    NORMAL = "normal",
    HIGH = "high",
    URGENT = "urgent",
}

export const PriorityLabel = {
    low: "Baixa",
    normal: "Normal",
    high: "Alta",
    urgent: "Urgente",
}

export const TypeLabel = {
    question: "Dúvida",
    incident: "Incidente",
    problem: "Problema",
    task: "Tarefa",
}

type EnumType = {
    [key: string]: {
        value: string
        label: string
    }
}

export const Status: EnumType = {
    NEW: {
        value: "new",
        label: "Novo"
    },
    OPEN: {
        value: "open",
        label: "Aberto"
    },
    PENDING: {
        value: "pending",
        label: "Pendente"
    },
    SOLVED: {
        value: "solved",
        label: "Resolvido"
    },
    CLOSED: {
        value: "closed",
        label: "Fechado"
    },
}

export const Type: EnumType = {
    QUESTION: {
        value: "question",
        label: "Dúvida"
    },
    INCIDENT: {
        value: "incident",
        label: "Incidente"
    },
    PROBLEM: {
        value: "problem",
        label: "Problema"
    },
    TASK: {
        value: "task",
        label: "Tarefa"
    },
}

export const Priority: EnumType = {
    LOW: {
        value: "low",
        label: "Baixo"
    },
    NORMAL: {
        value: "normal",
        label: "Normal"
    },
    HIGH: {
        value: "high",
        label: "Alto"
    },
    URGENT: {
        value: "urgent",
        label: "Urgente"
    },
}

// export const TicketConditionsFields = [
//     {
//         label: "Status",
//         field: "ticket:status",
//         operators: [Operators.Is, Operators.IsNot, Operators.LessThan, Operators.GreaterThan],
//         values: {
//             type: InputTypes.Select,
//             options: Object.keys(TicketStatus).map((key) => TicketStatus[key]),
//         },
//     },
//     {
//         label: "Tipo",
//         field: "ticket:type",
//         operators: [Operators.Is, Operators.IsNot],
//         values: {
//             type: InputTypes.Select,
//             options: Object.keys(TicketType).map((key) => TicketType[key]),
//         },
//     },
//     {
//         label: "Prioridade",
//         field: "ticket:priority",
//         operators: [Operators.Is, Operators.IsNot, Operators.LessThan, Operators.GreaterThan],
//         values: {
//             type: InputTypes.Select,
//             options: Object.keys(TicketPriority).map((key) => TicketPriority[key]),
//         },
//     },
//     {
//         label: "Horas desde criação",
//         field: "ticket:hours_since_created",
//         operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
//         values: {
//             type: InputTypes.Number,
//         },
//     },
//     {
//         label: "Grupos",
//         field: "ticket:hours_since_created",
//         operators: [Operators.LessThan, Operators.GreaterThan, Operators.Is],
//         values: {
//             type: InputTypes.Number,
//         },
//     },
// ]

export default {
    Status,
    Type,
    Priority,
    StatusEnum,
    StatusLabel,
    TypeEnum,
    TypeLabel,
    PriorityEnum,
    PriorityLabel,
}