import auth_mock from "./auth_mock"
import bank_mock from "./bank_mock"
import system_mock from "./system_mock"
import admin_mock from "./admin_mock"
import other_mock from "./other_mock"
import perfis_mock from "./perfis.mock"
import users_mock from "./users.mock"
import consignatarias_mock, { consignatariasDocsMock, documentosConsignataria, verbaConsignataria} from "./consignatarias.mock"
import calendar_events_mock from "./calendar_events.mock"
import notifications_mock from "./notifications.mock"
import avisos_mock from "./avisos.mock"
import { liberacao_mock } from "./users.mock"
import logs_mock from "./logs.mock"
import downloads_mock from "./downloads.mock"
import importacoes_mock from "./importacoes.mock"
import servidores_mock, { servidorMatriculas, primeiroDescontoOpcoes, reservarMargem } from "./servidores.mock"
import taxas_mock, { taxasParcela } from "./taxas.mock"
import contratos_mock, { aprovarContrato, contratoNotes, refinanciarContrato, reprovarContrato } from "./contratos.mock"
import parametrosConvenio_mock from "./parametrosConvenio.mock"
import produtos_mock from "./produtos.mock"

export interface IMockMethod {
    success: {
        body: any
        statusCode: number
    }
    error?: {
        statusCode: number
        error: {
            errors: {
                key: string | null
                type: string
                descriptions: string[]
            }[]
        }
    }
}

interface IMockRoute {
    endpoint: string
    latency: number
    method: {
        get?: IMockMethod
        post?: IMockMethod
        put?: IMockMethod
        patch?: IMockMethod
        delete?: IMockMethod
    }
}

export interface IMock {
    path: string
    routes: IMockRoute[]
}

const mocks: IMock = {
    path: 'api',
    routes: [
        ...auth_mock,
        ...bank_mock,
        ...admin_mock, ...other_mock,
        ...system_mock,
        ...perfis_mock,
        ...users_mock,
        ...verbaConsignataria,
        ...taxasParcela,
        ...taxas_mock,
        ...consignatariasDocsMock,
        ...consignatarias_mock,
        ...documentosConsignataria,
        ...calendar_events_mock,
        ...notifications_mock,
        ...avisos_mock,
        ...liberacao_mock,
        ...logs_mock,
        ...downloads_mock,
        ...servidores_mock,
        ...servidorMatriculas,
        ...primeiroDescontoOpcoes,
        ...reservarMargem,
        ...contratos_mock,
        ...refinanciarContrato,
        ...aprovarContrato,
        ...reprovarContrato,
        ...reprovarContrato,
        ...contratoNotes,
        ...importacoes_mock,
        ...parametrosConvenio_mock,
        ...produtos_mock
    ],

}

export default mocks
