import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IOrganization {
    id: string
    updatedAt: string
    createdAt: string
    avatar?: string
    name: string
    description: string
    notes: string
    domains: string[]
    phone: string
    tagIds?: string[]
    email: string
    customFields?: any
}

export const organizations: IGenericModel<IOrganization> = {
    ...generic(endpoints.bank.ORGANIZATIONS),
}