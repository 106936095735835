import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IReportConversation } from "products/bank/pages/Reports/ReportConversations"

export default generateGenericMock(endpoints.bank.REPORT_CONVERSATIONS, genMockReportConversation)

export function genMockReportConversation(): IReportConversation {
    return {
        initialDate: faker.date.past().toISOString(),
        finalDate: faker.date.past().toISOString(),
        tme: faker.random.number({ max: 200 }),
        tma: faker.random.number({ max: 200 }),
        tmr: faker.random.number({ max: 200 }),
        new: faker.random.number({ max: 200 }),
        pending: faker.random.number({ max: 200 }),
        resolved: faker.random.number({ max: 200 }),
    }
}