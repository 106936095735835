import React, { useMemo } from "react"
import cx from "classnames"
import { SimpleImg } from "react-simple-img"

import defaultAvatarSrc from "assets/images/default-avatar-80-b.png"
import styles from "./style.module.css"
import Icon from "components/common/Icon"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"

// const colors = [
//     "#f3a683",
//     "#f7d794",
//     "#cf6a87",
//     "#f8a5c2",
//     "#63cdda",
//     "#b8e994",
//     "#f5cd79",
//     "#e15f41",
//     "#badc58",
//     "#18dcff",
//     "#f8c291",
//     "#e056fd",
// ]
const colors = [
    "#da9575",
    "#cf6a87",
]

export type IAvatarProps = {
    imgSrc?: string
    name?: string
    size?: number
    id?: string
    avatarPlaceholderIcon?: string
    fontSize?: number
    lazy?: boolean
    round?: boolean
    loading?: boolean
    style?: React.CSSProperties
    iconStyle?: React.CSSProperties
    placeholderStyle?: React.CSSProperties
    imgStyle?: React.CSSProperties
    className?: string
}

function Avatar({
    imgSrc,
    name = "",
    size = 32,
    fontSize,
    id,
    lazy = false,
    round,
    loading,
    avatarPlaceholderIcon,
    iconStyle,
    placeholderStyle,
    imgStyle,
    ...props
}: IAvatarProps) {
    // const finalId = useMemo(() => id || String(Math.round(Math.random() * colors.length)), [id])
    // const parsedId = useMemo(() => parseInt(finalId), [finalId])
    const colorIndex = useMemo(() => String(id || "0")?.[0]?.charCodeAt(0) || 0, [id])
    // const colorIndex = useMemo(
    //     () => (isNaN(parsedId) ? Math.round(Math.random() * colors.length) : parsedId),
    //     [parsedId]
    // )
    const style = useMemo(
        () =>
            size
                ? { width: size, height: size, fontSize: fontSize || size / 2 }
                : {},
        [fontSize, size]
    )

    const placeholder = useMemo(
        () => (
            <div
                className={cx(styles.placeholder, "placeholder")}
                style={{
                    backgroundColor: colors[colorIndex % colors.length],
                    ...style,
                    ...placeholderStyle,
                }}
            >
                {name ? (
                    <>
                        {name.toLocaleUpperCase()[0]}
                        {name.toLocaleUpperCase()[1]}
                    </>
                ) : (
                    <Icon
                        name={avatarPlaceholderIcon || "fa fa-user"}
                        style={{ fontSize: Math.max(size / 2, 12, fontSize || 0), ...iconStyle }}
                    />
                )}
            </div>
        ),
        [colorIndex, name, size, style]
    )

    const imgProps = {
        height: size,
        width: size,
        src: imgSrc!,
        style: imgStyle
    }

    return (
        <figure className={cx(styles.avatar, props.className, { round })} style={props.style}>
            {imgSrc ? (
                lazy ? (
                    <SimpleImg placeholder={defaultAvatarSrc} {...imgProps} />
                ) : (
                    <img {...imgProps} alt="avatar" />
                )
            ) : (
                placeholder
            )}
            {loading && (
                <div className={styles.loading}>
                    <div>
                        <LoadingOutlined />
                    </div>
                </div>
            )}
        </figure>
    )
}

export default Avatar
