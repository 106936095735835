import React from "react"
import styles from "./style.module.css"
import BaseSkeleton from "components/layout/BaseSkeleton"
import { Spin } from "antd"

function AppSpinner() {
    return (
        <div className={styles.loading}>
            <Spin spinning />
            {/* <div className={styles.logo} />
            <div className={styles.spinner}>
                <div className={styles.spinner_dots}>
                    <div className={styles.spinner_dot} style={{ animationDelay: "0s" }} />
                    <div className={styles.spinner_dot} style={{ animationDelay: "-0.2s" }} />
                    <div className={styles.spinner_dot} style={{ animationDelay: "-0.1s" }} />
                </div>
            </div> */}
        </div>
    )
}

export default AppSpinner
