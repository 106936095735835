import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IRegion } from "models/system/regions"

export default generateGenericMock(endpoints.system.REGIONS, generateRegionMockData)

export function generateRegionMockData(index: string): IRegion {
    return {
        id: index || "0",
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        name: faker.random.words(),
        subdomain: faker.random.word().toLocaleLowerCase(),
        avatar: "https://www.fortaleza.ce.gov.br/templates/pmf/images/logo-portal-prefeitura-fortaleza-retina.png",
        secretaryIds: faker.helpers.randomize([["0"], ["0", "1"], []])
    }
}