import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateTicketMockData } from "./tickets.mock"
import { generateRandomFakeList } from "lib/helper"
import { generateMockContact } from "./contacts.mock"
import { generateMockOrganization } from "./organizations.mock"
import { generateUserMockData } from "../users.mock"
import { generateConversationMockData } from "./conversations.mock"
import { ISearch } from "models/bank/search"

export default generateGenericMock(endpoints.bank.SEARCH, generateSearchMockData, { getBody: generateSearchMockData })

export function generateSearchMockData(): ISearch {
    const tickets = generateRandomFakeList(0, 20, (index) => generateTicketMockData(index))
    const conversations = generateRandomFakeList(0, 20, (index) => generateConversationMockData(index))
    const contacts = generateRandomFakeList(0, 20, (index) => generateMockContact())
    const organizations = generateRandomFakeList(0, 20, (index) => generateMockOrganization())
    const users = generateRandomFakeList(0, 20, (index) => generateUserMockData(index))
    return {
        tickets: {
            data: tickets,
            total: tickets.length * 2
        },
        conversations: {
            data: conversations,
            total: conversations.length
        },
        contacts: {
            data: contacts,
            total: contacts.length
        },
        organizations: {
            data: organizations,
            total: organizations.length
        },
        users: {
            data: users,
            total: users.length
        },
    }
}