import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useBreadcrumb, useStore, useActions, useHost, useGenericStore } from "hooks"
import { Row, Col, Form, Input, Button, Select, Alert, Radio, notification } from "antd"
import AuthPage from "../AuthPage"
import { MaskedInput } from "antd-mask-input"
import { Gender, MaritalStatus } from "lib/constants"
import { IRegisterModel } from "atoms/registerAtom"
import { useRecoilState } from "recoil"
import { registerAtom } from "atoms/registerAtom"

function Register() {
    // const { subdomain } = useHost()
    const [registerModel, setRegisterModel] = useRecoilState(registerAtom)

    useBreadcrumb({ items: [{ title: "Solicitação de acesso" }] })
    const history = useHistory()

    const { loading } = useStore((state) => state.session)
    // const { register } = useActions((actions) => actions.session)
    // const { all: consignatarias } = useGenericStore((actions) => actions.consignatarias)

    useEffect(() => {
        setRegisterModel(undefined)
    }, [])

    function onSubmit(model: Partial<IRegisterModel>) {
        setRegisterModel(model as any)
        if (model.account_type === "pessoa_juridica") {
            history.push("/user/register-cnpj")
        } else {
            history.push("/user/register-continuation")
        }
        // register({ ...model, celular: masks.number(model.celular) }).then(() => {
        //     history.push("/user/login")
        //     notification.success({
        //         message: "Solicitação de acesso efetuada!",
        //         duration: 10,
        //         description: "Aguarde sua liberação por um gestor. Você receberá uma confirmação por e-mail.",
        //         icon: <i className="fas fa-check-circle" style={{ color: "#08a742" }} />,
        //     })
        // })
    }

    return (
        <AuthPage title="Cadastro" maxWidth={990}>
            <Form name="normal_login" onFinish={onSubmit as any} layout="vertical">
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="CPF"
                            name="cpf"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor digite seu cpf.",
                                },
                            ]}
                        >
                            <MaskedInput mask="111.111.111-11" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Apelido"
                            name="nickname"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor digite um nome.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="gender"
                            label="Sexo"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value={Gender.Male}>Masculino</Select.Option>
                                <Select.Option value={Gender.Female}>Feminino</Select.Option>
                                <Select.Option value={Gender.Other}>Outro</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="maritalStatus"
                            label="Estado civil"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value={MaritalStatus.Single}>Solteiro</Select.Option>
                                <Select.Option value={MaritalStatus.Married}>Casado</Select.Option>
                                <Select.Option value={MaritalStatus.NotMarried}>União Estável</Select.Option>
                                <Select.Option value={MaritalStatus.Divorced}>Divorciado</Select.Option>
                                <Select.Option value={MaritalStatus.Separate}>Separado</Select.Option>
                                <Select.Option value={MaritalStatus.Widower}>Viúvo</Select.Option>
                                <Select.Option value={MaritalStatus.Other}>Outro</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Data de nascimento"
                            name="birthDate"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor digite uma data de nascimento.",
                                },
                            ]}
                        >
                            <MaskedInput mask="11/11/1111" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Tipo de conta"
                            name="account_type"
                            rules={[
                                {
                                    required: true,
                                    message: "Obrigatório.",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value={"pessoa_fisica"}>Abrir uma conta pessoal</Select.Option>
                                <Select.Option value={"pessoa_juridica"}>Abrir uma conta para a minha empresa</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {/*  ██████╗███╗   ██╗██████╗     ██╗
                {/* ██╔════╝████╗  ██║██╔══██╗    ██║
                {/* ██║     ██╔██╗ ██║██████╔╝    ██║
                {/* ██║     ██║╚██╗██║██╔═══╝██   ██║
                {/* ╚██████╗██║ ╚████║██║    ╚█████╔╝
                {/*  ╚═════╝╚═╝  ╚═══╝╚═╝     ╚════╝ 
                {/*                                   */}
                {/* <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                        if (getFieldValue("account_type") !== "pessoa_juridica") return null

                        return (
                            <>
                                <Row gutter={20}>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            label="CNPJ"
                                            name="cnpj"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Obrigatório.",
                                                },
                                            ]}
                                        >
                                            <MaskedInput mask="11.111.111/1111-11" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            label="Tipo de empresa"
                                            name="companyType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Obrigatório.",
                                                },
                                            ]}
                                        >
                                            <Select>
                                                <Select.Option value={CompanyType.MEI}>MEI</Select.Option>
                                                <Select.Option value={CompanyType.LTDA}>LTDA</Select.Option>
                                                <Select.Option value={CompanyType.SA}>SA</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            label="Atividade da sua empresa"
                                            name="companyActivity"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Obrigatório.",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            label="Data de constituição da empresa"
                                            name="constitutionDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Obrigatório.",
                                                },
                                            ]}
                                        >
                                            <MaskedInput mask="11/11/1111" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            label="Qual seu papel na empresa?"
                                            name="personRoleType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Obrigatório.",
                                                },
                                            ]}
                                        >
                                            <Select>
                                                <Select.Option value={PersonRoleType.AccountHolder + ""}>Titular da conta</Select.Option>
                                                <Select.Option value={PersonRoleType.Assignee + ""}>Procurador</Select.Option>
                                                <Select.Option value={PersonRoleType.Holder + ""}>Portador</Select.Option>
                                                <Select.Option value={PersonRoleType.LegalRepresentative + ""}>Representante legal</Select.Option>
                                                <Select.Option value={PersonRoleType.Partner + ""}>Sócio</Select.Option>
                                                <Select.Option value={PersonRoleType.User + ""}>Usuário</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}></Col>
                                </Row>
                            </>
                        )
                    }}
                </Form.Item> */}

                {/* <Form.Item>
                    <Alert
                        message="Este é apenas um pré-cadastro, posteriomente seu cadastro deverá ser liberado por um Gestor para que você tenha acesso ao sistema."
                        type="warning"
                    />
                </Form.Item> */}

                <Form.Item>
                    <Row gutter={20} align="middle">
                        <Col>
                            <Link to="/user/login">
                                <Button>Voltar ao início</Button>
                            </Link>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                htmlType="submit"
                                // className="login-form-button"
                                loading={loading}
                                // style={{ minWidth: 160, display: "block" }}
                                // size="large"
                            >
                                Continuar
                            </Button>
                            {/* <Button
                                type="primary"
                                // className="login-form-button"
                                onClick={() => {
                                    history.push("/user/register-continuation")
                                }}
                                loading={loading}
                                // style={{ minWidth: 160, display: "block" }}
                                // size="large"
                            >
                                teste
                            </Button> */}
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </AuthPage>
    )
}

export default Register
