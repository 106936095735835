import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { IContact, ITimelineEvent } from "models/bank/contacts"
import { InputType } from "lib/constants"
import { generateTicketMockData } from "./tickets.mock"

export default generateGenericMock(endpoints.bank.CONTACTS, generateMockContact)
export const contacts_timeline_mock = generateGenericMock(endpoints.bank.CONTACTS + '/:id/' + 'timeline', (): ITimelineEvent => ({
    id: faker.random.uuid(),
    createdAt: faker.date.past().toISOString(),
    title: faker.random.words(),
    type: 'ticket',
    details: faker.random.words(),
    ticket: generateTicketMockData("0")
}))
export const export_contacts = generateGenericMock(endpoints.bank.CONTACTS_EXPORT, () => ({}))

export function generateMockContact(): IContact {
    const customType = faker.helpers.randomize([InputType.Boolean, InputType.Date, InputType.Decimal, InputType.MultiSelect, InputType.Number, InputType.Select, InputType.Text, InputType.Textarea])

    return {
        id: faker.random.uuid(),
        avatar: faker.helpers.randomize([undefined, 'https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/216150/5933160d2b734175fd7e7adbeb894fc1b4a02f08.gif']),
        name: faker.name.firstName() + " " + faker.name.lastName(),
        lastName: faker.name.lastName(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        email: faker.internet.email(),
        phone: faker.phone.phoneNumber(),
        twitterId: faker.name.lastName().toLowerCase(),
        facebookId: faker.name.lastName().toLowerCase(),
        externalId: faker.random.uuid(),
        organization: faker.random.uuid(),
        tagIds: [faker.random.uuid()],
        notes: faker.company.catchPhraseDescriptor(),
        customFields: {
            [faker.random.word()]: {
                type: customType,
                value: faker.random.words()
            }
        }
    }
}