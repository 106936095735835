import Ticket from "./Ticket"
import Defaults from "./defaults"
import Channel from "./Channel"

export enum InputType {
    Text = "text",
    Textarea = "textarea",
    Number = "number",
    Decimal = "decimal",
    Select = "select",
    MultiSelect = "multi-select",
    Boolean = "boolean",
    Date = "date",
}

export enum Operators {
    Is = "is",
    IsNot = "is_not",
    LessThan = "less_than",
    GreaterThan = "greater_than",
    Contains = "contains",
    NotContains = "not_contains",
    ContainsAnyWords = "contains_any_words",
    ContainsNoneWords = "contains_none_of_words",
    ContainsString = "contains_string",
    NotContainsString = "not_contains_string",
}

export const OperatorsLabel = {
    [Operators.Is]: "é igual a",
    [Operators.IsNot]: "não é igual a",
    [Operators.LessThan]: "é menor que",
    [Operators.GreaterThan]: "é maior que",
    [Operators.Contains]: "contém",
    [Operators.NotContains]: "não contém",
    [Operators.ContainsAnyWords]: "contém pelo menos uma das seguintes palavras",
    [Operators.ContainsNoneWords]: "não contém nenhuma das seguintes palavras",
    [Operators.ContainsString]: "contém a seguinte string",
    [Operators.NotContainsString]: "não contém a seguinte string",
}

export { Ticket, Defaults, Channel }

export enum Gender {
    Male = "0",
    Female = "1",
    Other = "2",
}

export const getGenderLabel = (gender: Gender) => {
    switch (gender) {
        case Gender.Male:
            return "Masculino"
        case Gender.Female:
            return "Feminino"
        case Gender.Other:
            return "Outro"
    }
}

export enum MaritalStatus {
    NotMarried = "0", // União Estável
    Married = "1", // Casado
    Divorced = "2", // Divorciado
    Separate = "3", // Separado
    Widower = "4", // Viúvo
    Single = "5", // Solteiro
    Other = "6", // Outros
}

export const getMaritalStatusLabel = (maritalStatus: MaritalStatus) => {
    switch (maritalStatus) {
        case MaritalStatus.NotMarried:
            return "União Estável"
        case MaritalStatus.Married:
            return "Casado"
        case MaritalStatus.Divorced:
            return "Divorciado"
        case MaritalStatus.Separate:
            return "Separado"
        case MaritalStatus.Widower:
            return "Viúvo"
        case MaritalStatus.Single:
            return "Solteiro"
        case MaritalStatus.Other:
            return "Outros"
    }
}

export enum DocumentType {
    IdentityDocumentFront = 0, // Documento de Identidade Frente
    TaxNumber = 1, // CPF
    ProofAddress = 2, // Comprovante de Endereço
    CNH = 3,
    CNPJ = 4, // Cartão CNPJ
    Contract = 5, // Contrato Social
    LetterOfAttorney = 6, // Procuração
    IdentityDocumentVerse = 7, // Documento de Identidade Verso
}

export enum AccountConditionType {
    Limited = 1, // [Description("Limitado")]
    Verified = 2, // [Description("Verificado")]
    Blocked = 3, // [Description("Bloqueado")]
    Terminated = 4, // [Description("Finalizado")] – Significa encerramento de conta
}

/**
 * Se refere ao fato de a conta estar liberada ou não para acesso via APP ou Portal:
 */
export enum AccountStatus {
    Disabled = 0,
    Enabled = 1,
}

export enum CompanyType {
    SA = 0,
    LTDA = 1,
    MEI = 2,
}

export enum PersonRoleType {
    AccountHolder = 0, // Titular da conta
    Assignee = 1, // Procurador
    Holder = 2, // Portador
    Partner = 3, // Sócio
    User = 4, // Usuário
    LegalRepresentative = 5, // Representante Legal
}

export enum IDocumentType {
    IdentityDocumentFront = 0, // Documento de Identidade Frente
    TaxNumber = 1, // CPF
    ProofAddress = 2, // Comprovante de Endereço
    CNH = 3,
    CNH_Verse = 16,
    CNPJ = 4,
    Contract = 5, // Contrato Social
    LetterOfAttorney = 6, // Procuração
    IdentityDocumentVerse = 7, // Documento de Identidade Verso
    CompanyAnnualBilling = 17,
}
