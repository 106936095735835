import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { INotification } from "models/notifications"
import { generateUserMockData } from "./users.mock"
import { generate } from "shortid"
import { ILog } from "models/logs"
import { IDownload } from "models/downloads"

export default generateGenericMock(endpoints.DOWNLOADS, generateDownloadMockData)

export function generateDownloadMockData(index: string, model?: Partial<IDownload>): IDownload {
    return {
        id: index || "0",
        criado_em: faker.date.recent(5).toISOString(),
        user: generateUserMockData("0"),
        name: faker.random.words(),
        url: "https://youtu.be/dQw4w9WgXcQ?list=PL92269ABDE05B71E7",
    }
}