import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { ITicket } from "./tickets"
import { IContact } from "./contacts"
import { IAgent } from "./agents"
import { IOrganization } from "./organizations"
import { Action, Thunk, action, thunk } from "easy-peasy"
import { request } from "lib/request"
import { IConversation } from "./conversations"
import { IUser } from "models/users"

export type ISearchTypes = keyof ISearch

export interface ISearch {
    tickets: {
        data: ITicket[]
        total: number
    },
    conversations: {
        data: IConversation[]
        total: number
    },
    contacts: {
        data: IContact[],
        total: number
    },
    users: {
        data: IUser[],
        total: number
    },
    organizations: {
        data: IOrganization[],
        total: number
    },
}

export interface ISearchModel {
    result?: ISearch
    setResult: Action<ISearchModel, ISearch>
    fetch: Thunk<ISearchModel, {
        limit?: number
        offset?: number
        type?: ISearchTypes
        searchString: string
        isNextPage?: boolean
    }>
    loading: boolean
    setLoading: Action<ISearchModel, boolean>
    reset: Action<ISearchModel>
}

export const search: ISearchModel = {
    result: undefined,
    setResult: action((state, payload) => {
        state.result = { ...state.result, ...payload }
    }),
    fetch: thunk(async (actions, { isNextPage, ...params }, { getState }) => {
        actions.setLoading(true)
        return request.get(endpoints.bank.SEARCH, params).then((response) => {
            if (isNextPage) {
                const oldType = (getState().result as any)?.[params.type as keyof ISearch]
                const type = response.data?.[params.type as keyof ISearch]
                const newResult: ISearch = {
                    ...getState().result!,
                    [params.type!]: {
                        data: [...oldType.data, ...type?.data],
                        total: type?.total
                    }
                }
                actions.setResult(newResult)
            } else {
                actions.setResult(response.data)
            }
            return response.data
        }).finally(() => {
            actions.setLoading(false)
        })
    }),
    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload
    }),
    reset: action((state, payload) => {
        state.result = undefined
    }),
    // ...generic(endpoints.bank.SEARCH)
}