import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateRandomFakeList } from "lib/helper"
import { IView } from "models/bank/views"
import { defaultColumns } from "products/bank/pages/Settings/Views/EditView"
import { InputType } from "lib/constants"

export default generateGenericMock(endpoints.bank.VIEWS, generateMockData)
export const views_count_mock = generateGenericMock(endpoints.bank.VIEWS + '-count', (index: string) => ({
    viewId: index,
    count: faker.random.number(999)
}))

function generateMockData(index: string): IView {
    const customType = faker.helpers.randomize([InputType.Boolean, InputType.Date, InputType.Decimal, InputType.MultiSelect, InputType.Number, InputType.Select, InputType.Text, InputType.Textarea])

    return {
        id: index || faker.random.uuid(),
        active: faker.random.boolean(),
        name: faker.random.words(),
        description: faker.random.words(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        restriction: faker.helpers.randomize([
            {
                type: faker.helpers.randomize(["user", "group"]),
                ids: faker.helpers.randomize([["1"], ["1", "2"]]),
            },
            {
                type: "public"
            }
        ]),
        conditions: {
            all: generateRandomFakeList(0, 5, () =>
                faker.helpers.randomize([
                    {
                        field: "ticket:status",
                        operator: faker.helpers.randomize(["is", "is_not"]),
                        value: faker.helpers.randomize(["new", "pending", "open", "solved", "closed"]),
                    },
                    {
                        field: "ticket:type",
                        operator: faker.helpers.randomize(["is", "is_not"]),
                        value: faker.helpers.randomize(["question", "incident", "problem", "task"]),
                    },
                ]))
        },
        execution: {
            // groupBy: faker.helpers.randomize(["status", "type", "subject", "priority", "contact", "organization", "createdAt", "updatedAt", "nextSlaBreach", "groupId", "assigneeId"]),
            // groupBy: faker.helpers.randomize(["status"]),
            groupOrder: faker.helpers.randomize(["ascend", "descend"]),
            sortBy: faker.helpers.randomize(["status", "type"]),
            sortOrder: faker.helpers.randomize(["ascend", "descend"]),
            columns: faker.helpers.randomize([
                defaultColumns,
                [
                    { title: "Prioridade", key: "priority" },
                    { title: "Eg Texto", key: "ticket:example_text" },
                    { title: "Eg Texto Área", key: "ticket:example_textarea" },
                    { title: "Eg Número", key: "ticket:example_number" },
                    { title: "Eg Decimal", key: "ticket:example_decimal" },
                    { title: "Eg Select", key: "ticket:example_select" },
                    { title: "Eg MultiSelect", key: "ticket:example_multi-select" },
                    { title: "Eg Booleano", key: "ticket:example_boolean" },
                    { title: "Eg Data", key: "ticket:example_date" },
                ],
                [
                    { title: "Prioridade", key: "priority" },
                    { title: "Eg Texto", key: "ticket:example_text" },
                ]
            ]),
        }
    }
}