import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateTicketMockData, generateEventMockData } from "./tickets.mock"
import { IConversation } from "models/bank/conversations"
import { generateChannelsMockData } from "./channels.mock"

export default generateGenericMock(endpoints.bank.CONVERSATIONS, generateConversationMockData)
export const conversation_events_mock = generateGenericMock(endpoints.bank.CONVERSATIONS + '/:id/events', generateEventMockData)

export function generateConversationMockData(index: string): IConversation {
    return {
        id: index || "0",
        ticket: generateTicketMockData(index || "0"),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        channel: generateChannelsMockData("0"),
        followerIds: [faker.random.uuid()],
    }
}