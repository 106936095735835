import React from "react"
import { Link, RouteComponentProps } from "react-router-dom"
import { useBreadcrumb, useStore, useActions } from "hooks"
import { UserOutlined } from "@ant-design/icons"
import { Row, Form, Input, Button } from "antd"
import AuthPage from "./AuthPage"
import { MaskedInput } from "antd-mask-input"

// interface IForgotProps extends RouteComponentProps {

// }

function Forgot(props: RouteComponentProps) {
    useBreadcrumb({ items: [{ title: "Esqueci a senha" }] })

    const { loading } = useStore((state) => state.session)
    const { sendForgotPassword } = useActions((actions) => actions.session)

    function onSubmit(model: { password: string; email: string; remember: boolean }) {
        sendForgotPassword(model).then(() => {
            props.history.push("/user/forgot/sent")
        })
    }

    return (
        <AuthPage title="Esqueci a senha">
            <Form onFinish={onSubmit as any} hideRequiredMark layout="vertical">
                <Form.Item name="taxNumber" label="Seu cpf" rules={[{ required: true, message: "Por favor, insira seu cpf." }]}>
                    <MaskedInput mask="111.111.111-11" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} style={{ width: "100%" }} size="large">
                        Enviar e-mail
                    </Button>
                </Form.Item>
                <Row justify="center">
                    <Link to="/user/login">
                        <Button type="text">Voltar ao início</Button>
                    </Link>
                </Row>
            </Form>
        </AuthPage>
    )
}

export default Forgot
