import { atom } from "recoil"

export const showBalanceAtom = atom<boolean>({
    key: "showBalanceAtom",
    default: false,
})

export const modalState = atom<React.ReactNode>({
    key: "ModalState",
    default: undefined,
})

export const askPasswordAtom = atom<{
    show: boolean
    onOk?: (shortPassword?: string) => void
}>({
    key: "askPasswordAtom",
    default: {
        show: false,
    },
})
