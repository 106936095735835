import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic, genericFetchSet, genericFetchSetPaginated } from "models/generic"
import { Thunk, Action, thunk, action } from "easy-peasy"
import { request } from "lib/request"
import { InputType } from "lib/constants"

export interface IConditions {
    all: {
        field: string
        operator: string
        value: string | number
    }[],
    any?: {
        field: string
        operator: string
        value: string | number
    }[]
}

export interface IView {
    id: string
    name: string
    updatedAt: string
    createdAt: string
    description: string
    active: boolean
    restriction: {
        type: "user" | "group" | "public"
        ids?: string[]
    }
    conditions: IConditions
    execution: {
        groupBy?: string
        groupOrder: "ascend" | "descend"
        sortBy?: string
        sortOrder: "ascend" | "descend"
        columns: {
            title: string
            key: string
            type?: InputType
        }[]
    }
}

export interface IViewModel extends IViewCount, IGenericModel<IView> { }

interface IViewCount {
    fetchCounts: Thunk<IViewModel>
    setCounts: Action<IViewModel, { id: string, count: number }[]>
    counts: { viewId: string, count: number }[]
}

export const views: IViewModel = {
    ...generic(endpoints.bank.VIEWS),
    ...genericFetchSetPaginated<IViewCount>('counts', endpoints.bank.VIEWS + '-count')
    // counts: [],
    // setCounts: action((state, payload) => {
    //     state.counts = payload
    // }),
    // fetchCounts: thunk(async (actions) => {
    //     return request.get(endpoints.bank.VIEWS + '-count').then((response) => {
    //         actions.setCounts(response.data)
    //         return response.data
    //     }).finally(() => {
    //         actions.setLoadingOne(false)
    //     })
    // }),
}