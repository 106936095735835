import faker from "faker"
import { endpoints } from "config/endpoints.config"
import { generateGenericMock } from "mock/genericMock"
import { generateRandomFakeList } from "lib/helper"

export default generateGenericMock(endpoints.bank.SLAS, generateMockData)

function getTargetMock() {
    return {
        firstResponseTime: [faker.random.number(), faker.random.number(), faker.random.number()],
        everyResponseTime: [faker.random.number(), faker.random.number(), faker.random.number()],
        resolutionTime: [faker.random.number(), faker.random.number(), faker.random.number()],
        operationalHours: faker.helpers.randomize(["calendar-hours", "business-hours"]),
    }
}

export function generateMockData() {
    return {
        id: faker.random.uuid(),
        updatedAt: faker.date.past().toISOString(),
        createdAt: faker.date.past().toISOString(),
        name: faker.random.words(),
        description: faker.random.words(20),
        conditions: {
            all: generateRandomFakeList(0, 5, () =>
                faker.helpers.randomize([
                    {
                        field: "ticket:status",
                        operator: faker.helpers.randomize(["is", "is_not"]),
                        value: faker.helpers.randomize(["new", "pending", "open", "solved", "closed"]),
                    },
                    {
                        field: "ticket:type",
                        operator: faker.helpers.randomize(["is", "is_not"]),
                        value: faker.helpers.randomize(["question", "incident", "problem", "task"]),
                    },
                ]))
        },
        targets: {
            urgent: getTargetMock(),
            high: getTargetMock(),
            normal: getTargetMock(),
            low: getTargetMock(),
        },
        escalation: {
            agents: [faker.random.uuid()],
            hoursAfter: faker.random.number(),
        },
        reminder: {
            agents: [faker.random.uuid()],
            hoursBefore: faker.random.number()
        },
        position: faker.random.number()
    }
}