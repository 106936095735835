import { Action, action, Computed, computed, thunk, Thunk } from "easy-peasy"
import { request, IEnv } from "lib/request"
import { endpoints } from "config/endpoints.config"
import config from "config"
import { AxiosPromise } from "axios"
import { IFile } from "./files"

export interface IBreadcrumbItem {
    title?: string
    path?: string
    icon?: React.ReactNode
}

export interface IBreadcrumb {
    items?: IBreadcrumbItem[]
    renderButtons?: Object
    backTo?: string
}

export interface ILocalPreferences {
    isViewsCollapsed?: boolean
    pluginKey?: string
    contactContextActiveKeys?: string | string[]
}

export interface IWorkspaceInfo {
    organizationLogo: string
    name: string
}

export interface IModal {
    content: React.ReactNode
    title: string
    onSave: Function
}

export interface IAppModel {
    // state
    breadcrumb: IBreadcrumb
    modal?: IModal | null
    localPreferences: ILocalPreferences
    textEditorMention: string
    scrollerCount: number
    workspaceInfo?: IWorkspaceInfo
    domainEnv: IEnv
    imagesViewer?: { src: string; alt: string; downloadUrl: string }[]

    // actions
    setBreadcrumb: Action<IAppModel, IBreadcrumb>
    addScrollerCount: Action<IAppModel>
    setModal: Action<IAppModel, IModal | null>
    setDomainEnv: Action<IAppModel, IEnv>
    setImagesViewer: Action<IAppModel, { src: string; alt: string; downloadUrl: string }[]>
    setLocalPreferences: Action<IAppModel, ILocalPreferences>
    patchLocalPreferences: Action<IAppModel, ILocalPreferences>
    setTextEditorMention: Action<IAppModel, string>
    retrieveLocalPreferences: Thunk<IAppModel>
    fetchWorkspaceInfo: Thunk<IAppModel, { subdomain: string }>
    setWorkspaceInfo: Action<IAppModel, IWorkspaceInfo>

    // thunks
    uploadAssets: Thunk<IAppModel, any[], any, any, AxiosPromise<IFile[]>>
}

const app: IAppModel = {
    domainEnv:
        process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
            ? config.envs.find((env) => env.key === "production")!
            : config.envs[0],
    breadcrumb: {},
    modal: undefined,
    scrollerCount: 0,
    localPreferences: {},
    workspaceInfo: undefined,
    imagesViewer: [],
    textEditorMention: "",
    setTextEditorMention: action((state, mentionType) => {
        state.textEditorMention = mentionType
    }),
    addScrollerCount: action((state) => {
        state.scrollerCount += 1
    }),
    setWorkspaceInfo: action((state, workspaceInfo) => {
        state.workspaceInfo = workspaceInfo
    }),
    setModal: action((state, modal) => {
        state.modal = modal
    }),
    setBreadcrumb: action((state, breadcrumb) => {
        state.breadcrumb = breadcrumb
    }),
    setImagesViewer: action((state, images) => {
        state.imagesViewer = images
    }),
    setLocalPreferences: action((state, localPreferences) => {
        state.localPreferences = localPreferences
        localStorage.setItem("ticket:local_preferences", JSON.stringify(localPreferences))
    }),
    patchLocalPreferences: action((state, localPreferences) => {
        state.localPreferences = { ...state.localPreferences, ...localPreferences }
        localStorage.setItem(
            "ticket:local_preferences",
            JSON.stringify({ ...state.localPreferences, ...localPreferences })
        )
    }),
    retrieveLocalPreferences: thunk((actions) => {
        const localPreferencesString = localStorage.getItem("ticket:local_preferences")
        if (localPreferencesString) {
            try {
                actions.setLocalPreferences(JSON.parse(localPreferencesString))
            } catch (e) {
                console.log("Error retrieving local preferences")
            }
        }
    }),
    fetchWorkspaceInfo: thunk((actions, payload) => {
        request.get(endpoints.WORKSPACE_INFO + "/" + payload.subdomain).then((response) => {
            actions.setWorkspaceInfo(response.data)
        })
    }),
    uploadAssets: thunk((actions, assets) => {
        const formData = new FormData()
        assets.forEach((asset: any, i: number) => formData.append("file" + i, asset))

        return request.post(endpoints.UPLOAD, formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
    }),
    setDomainEnv: action((state, localPreferences) => {
        state.localPreferences = { ...state.localPreferences, ...localPreferences }
        localStorage.setItem(
            "ticket:local_preferences",
            JSON.stringify({ ...state.localPreferences, ...localPreferences })
        )
    }),
}

export default app
