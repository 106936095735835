import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IRegion } from "./regions"

export interface ISecretary {
    id: string
    updatedAt: string
    createdAt: string
    name: string
    regionId: string
    adminEmail: string
}

export const secretaries: IGenericModel<ISecretary> = {
    ...generic(endpoints.system.SECRETARIES),
}