import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic, genericFetchSetPaginated } from "models/generic"
import { IContact, IPerson } from "./contacts"
import { PriorityEnum, StatusEnum, TypeEnum } from "lib/constants/Ticket"
import { IOrganization } from "./organizations"
import { IChannel } from "./channels"
import { IAgent } from "./agents"
import { IGroup } from "./groups"
import { ITag } from "./tags"
import { ICustomField } from "./customFields"
import { IInputType } from "components/form/ActionsInput"
import { InputType } from "lib/constants"
import { Thunk, thunk } from "easy-peasy"
import { request } from "lib/request"
import { UploadFile } from "antd/lib/upload/interface"
import { IFileUpload } from "./responses"
import { message } from "antd"
import { Type as ChannelType } from "lib/constants/Channel"
import { IFile } from "models/files"
import toast from "lib/toast"

export interface IReplyTo extends Partial<ITicketEventMessage> {
    id: string;
    body: string
}

export interface ITicketEventMessage extends ITicketEvent {
    attachments: IFile[]
    body: string
    channel: IChannel
    // to?: IPerson
    toEntity: 'contact' | 'channel'
    cc?: string[]
    bcc?: string[]
    notify?: string[]
    replyTo?: IReplyTo
}

export interface ITicketEventUpdate extends ITicketEvent {
    set?: {
        [custom_placeholder: string]: { type: InputType, value: string } | any
        status?: StatusEnum
        priority?: PriorityEnum
        type?: TypeEnum
        assignee?: IAgent
        group?: IGroup
        // customFields?: Record<string, { type: IInputType, value: string }>
    },
    add?: {
        tag?: ITag
        follower?: IAgent
    },
    remove?: {
        tag?: ITag
        follower?: IAgent
    }
}

export interface ITicketEvent {
    id: string
    createdAt: string
    type: 'reply' | 'update' | 'note' | 'forward'
    fromEntity: 'agent' | 'contact' | 'system'
    from: IPerson | undefined
}

export interface ITicket {
    id: string
    number: number
    updatedAt: string
    createdAt: string
    subject: string
    contact: IContact
    status: StatusEnum
    type: TypeEnum
    priority: PriorityEnum
    description: string
    lastMessageEvent: {
        authorId: string
        authorType: 'agent' | 'contact'
        body: string
        createdAt: string
        id: string
    }
    tagIds: string[]
    assigneeId?: string
    organization: IOrganization
    groupId: string
    followerIds: string[]
    dueDate: string
    channelId: string
    channelType: ChannelType
    mainEvent: ITicketEvent
    customFields?: Object
    unreadMessagesCount: number
    invisible: boolean
}

export interface ISendMessage {
    ticketId: string
    message: Partial<ITicketEventMessage>
}

export interface ITicketModel extends IGenericModel<ITicket> {
    sendMessage: Thunk<ITicketModel, ISendMessage>
    excludeMessage: Thunk<ITicketModel, { eventId: string, ticketId: string }>
    executeMacro: Thunk<ITicketModel, { macroId: string, ticketId: string }>
    model: ITicket
}

export const tickets: ITicketModel = {
    ...generic(endpoints.bank.TICKETS),
    sendMessage: thunk(async (actions, payload, { getState }) => {
        actions.patchLoading({ custom: 'message' })
        const saveEndpoint = endpoints.bank.TICKETS + '/' + payload.ticketId + '/message'
        return request.save(saveEndpoint, payload.message).then((response) => {
            return response.data
        }).catch((e) => {
            console.log(e)
        })
            .finally(() => {
                actions.patchLoading({ custom: '' })
            })
    }),
    excludeMessage: thunk(async (actions, payload, { getState }) => {
        actions.patchLoading({ custom: 'exclude-message' })
        const endpoint = endpoints.bank.TICKETS + '/' + payload.ticketId + '/message/' + payload.eventId
        return request.delete(endpoint).then((response) => {
            return response.data
        }).catch((e) => {
            console.log(e)
        })
            .finally(() => {
                actions.patchLoading({ custom: ''})
            })
    }),
    executeMacro: thunk(async (actions, payload, { getState }) => {
        actions.patchLoading({ custom: 'macro'})
        const hide = message.loading('Executando macro', 0)
        const saveEndpoint = endpoints.bank.TICKETS + '/' + payload.ticketId + '/execute-macro/' + payload.macroId
        return request.post(saveEndpoint).then((response) => {
            actions.setModel(response.data)
            return response.data
        }).catch((e) => {
            console.log(e)
        })
            .finally(() => {
                actions.patchLoading({ custom: ''})
                hide()
                toast.success('Macro executado!')
            })
    }),
}