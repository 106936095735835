import React from "react"

import styles from "./style.module.css"
import { RouteComponentProps } from "react-router-dom"
import { Form, Input, Row, Col, Select, Radio, Transfer } from "antd"
import ConditionsInput from "components/form/ConditionsInput"
import { useStore, useGenericStore } from "hooks"
import { IView } from "models/bank/views"
import SettingsPageEdit from "components/common/SettingsPage/SettingsPageEdit"
import TextArea from "antd/lib/input/TextArea"
import RestrictionInput from "components/form/RestrictionInput"
import { InputType } from "lib/constants"

export const defaultColumns = [
    {
        title: "Assunto",
        key: "subject",
    },
    {
        title: "Contato",
        key: "contact",
    },
    {
        title: "Criado em",
        key: "createdAt",
    },
    {
        title: "Atualizado em",
        key: "updatedAt",
    },
    {
        title: "ID",
        key: "number",
    },
    {
        title: "Prioridade",
        key: "priority",
    },
    {
        title: "Grupo",
        key: "groupId",
    },
    {
        title: "Atribuído",
        key: "assigneeId",
    },
    {
        title: "Etiquetas",
        key: "tagIds",
    },
    {
        title: "Tipo",
        key: "type",
    },
    {
        title: "Organização",
        key: "organization",
    },
    {
        title: "Violação de SLA",
        key: "nextSlaBreach",
    },
    {
        title: "Vencimento",
        key: "dueDate",
    },
]

interface MatchParams {
    id: string
}

interface EditViewProps extends RouteComponentProps<MatchParams> {
    children: React.ReactNode
}

function EditView(props: EditViewProps) {
    const [form] = Form.useForm()
    const { all: customFields } = useGenericStore((state) => state.bank.customFields)
    // const user = useStore((state) => state.session.user)

    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px",
    }

    return (
        <SettingsPageEdit<IView>
            {...props}
            pageComponent="drawer"
            routeName="views"
            title="Visualização"
            storePath={(store) => store.bank.views}
            contentClassname={styles.edit_view}
            defaultValues={{
                restriction: { type: "public" },
                execution: {
                    groupOrder: "ascend",
                    sortOrder: "ascend",
                    columns: [],
                },
            }}
            form={form}
            onSubmitBefore={async (model, onSubmit, config) => {
                onSubmit({ ...model, active: true })
            }}
        >
            <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: "Por favor digite um nome." }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Descrição" name="description">
                <TextArea rows={2} />
            </Form.Item>
            <RestrictionInput form={form} />
            <ConditionsInput form={form} />
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item name={["execution", "groupBy"]} label="Agrupar por">
                        <Select allowClear>
                            <Select.Option value="ticket:subject">Assunto</Select.Option>
                            <Select.Option value="ticket:status">Status</Select.Option>
                            <Select.Option value="ticket:type">Tipo</Select.Option>
                            <Select.Option value="ticket:priority">Prioridade</Select.Option>
                            <Select.Option value="assigneeId">Atribuído</Select.Option>
                            <Select.Option value="groupId">Grupo</Select.Option>
                            <Select.Option value="contact">Contato</Select.Option>
                            <Select.Option value="organization">Organização</Select.Option>
                            <Select.Option value="createdAt">Criado em</Select.Option>
                            <Select.Option value="updatedAt">Atualizado em</Select.Option>
                            <Select.Option value="nextSlaBreach">
                                Próxima violação de SLA
                            </Select.Option>
                            <Select.Option value="channelId">Canal</Select.Option>
                            {customFields.map((customField) => (
                                <Select.Option
                                    value={customField.placeholder}
                                    key={customField.id}
                                >
                                    {customField.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={["execution", "groupOrder"]} style={{ marginTop: -10 }}>
                        <Radio.Group>
                            <Radio value="ascend" style={radioStyle}>
                                Ascendente
                            </Radio>
                            <Radio value="descend" style={radioStyle}>
                                Descendente
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={["execution", "sortBy"]} label="Ordernar por">
                        <Select allowClear>
                            <Select.Option value="ticket:subject">Assunto</Select.Option>
                            <Select.Option value="ticket:status">Status</Select.Option>
                            <Select.Option value="ticket:type">Tipo</Select.Option>
                            <Select.Option value="ticket:priority">Prioridade</Select.Option>
                            <Select.Option value="assigneeId">Atribuído</Select.Option>
                            <Select.Option value="groupId">Grupo</Select.Option>
                            <Select.Option value="contact">Contato</Select.Option>
                            <Select.Option value="organization">Organização</Select.Option>
                            <Select.Option value="createdAt">Criado em</Select.Option>
                            <Select.Option value="updatedAt">Atualizado em</Select.Option>
                            <Select.Option value="nextSlaBreach">
                                Próxima violação de SLA
                            </Select.Option>
                            <Select.Option value="channelId">Canal</Select.Option>
                            {customFields.map((customField) => (
                                <Select.Option
                                    value={customField.placeholder}
                                    key={customField.id}
                                >
                                    {customField.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={["execution", "sortOrder"]} style={{ marginTop: -10 }}>
                        <Radio.Group>
                            <Radio value="ascend" style={radioStyle}>
                                Ascendente
                            </Radio>
                            <Radio value="descend" style={radioStyle}>
                                Descendente
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.execution?.columns?.length !==
                    currentValues.execution?.columns?.length
                }
            >
                {({ getFieldValue }) => {
                    return (
                        <Form.Item name={["execution", "columns"]} label="Colunas">
                            <Transfer
                                dataSource={defaultColumns}
                                titles={["Disponíveis", "Selecionadas"]}
                                targetKeys={getFieldValue(["execution", "columns"])}
                                // operations={['Add Coluna', '<']}
                                oneWay
                                // selectedKeys={selectedKeys}
                                // onChange={this.handleChange}
                                // onSelectChange={this.handleSelectChange}
                                // onScroll={this.handleScroll}
                                showSelectAll={false}
                                render={(item) => item.title!}
                                listStyle={{
                                    width: 305,
                                    height: 300,
                                }}
                                locale={{
                                    notFoundContent: "Adicione de 1 até 10 colunas",
                                }}
                            />
                        </Form.Item>
                    )
                }}
            </Form.Item>
        </SettingsPageEdit>
    )
}

export default EditView
