import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export enum TypeStatusProcess {
    SUCCESS = "success",
    ERROR = "error",
}
export enum TypeStatusTransfer {
    SUCCESS = "success",
    ERROR = "erro",
}

export interface ITreasuryImport {
    id: string
    _id: string
    owner: string
    qtLines: number
    type: string
    location: string
    tags: string[]
    qtFailuresProcess: number
    qtSuccessProcess: number
    qtFailuresTransfer: number
    qtSuccessTransfer: number
    statusProcess: TypeStatusProcess
    statusTransfer: TypeStatusTransfer
    datePayment: string
    createdAt: string
    updatedAt: string
    __v: number
    linesBacthTransfer?: ITreasuryImportDetails[]
}

export interface ITreasuryImportDetails {
    _id: string
    batchTransfer: string
    favored: {
        name: string
        value: string
        codeHistory: string
        identificationOperation: string
        bankAccount: string
        bankAccountDigit: string
        taxNumber: string
        _id: string
        updatedAt: string
        createdAt: string
    }
    codTransaction: string
    statusProcess: string
    statusTransfer: string
    failuresProcess?: string[]
    failuresTransfer?: string[]
    dateTransfer: string
    createdAt: string
    updatedAt: string
    __v: number
}

export const treasury_imports: IGenericModel<ITreasuryImport> = {
    ...generic(endpoints.TREASURY_IMPORTACOES),
}
